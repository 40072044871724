import {GroupPreview, Preview} from 'app/Components'
import {Source} from 'lib/select'
import {Student} from 'lib/students'
import * as React from 'react'
import {connect} from 'react-redux'

type StudentGroupsPreviewProps = {
    id: string,
}

class StudentGroupsPreviewComponent extends React.Component<StudentGroupsPreviewProps> {
    public render = () =>
        <Preview
            value={this.props.id}
            source={Source.STUDENTS}
            template={(student: Student) => <GroupPreview ids={student.groups}/>}
        />
}

export const StudentGroupsPreview = connect()(StudentGroupsPreviewComponent)