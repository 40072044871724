// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    HistoryEntryRead,
    HistoryEntryWrite,
    InlineResponse2006,
} from '../models';

export interface DeleteHistoryEntryItemRequest {
    id: string;
}

export interface GetHistoryEntryCollectionRequest {
    id?: Array<string>;
    student?: Array<string>;
    event?: Array<string>;
    occurredAtBefore?: string;
    occurredAtStrictlyBefore?: string;
    occurredAtAfter?: string;
    occurredAtStrictlyAfter?: string;
    orderOccurredAt?: GetHistoryEntryCollectionOrderOccurredAtEnum;
    orderDescription?: GetHistoryEntryCollectionOrderDescriptionEnum;
    orderEventName?: GetHistoryEntryCollectionOrderEventNameEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetHistoryEntryItemRequest {
    id: string;
}

export interface PostHistoryEntryCollectionRequest {
    historyEntryWrite?: HistoryEntryWrite;
}

export interface PutHistoryEntryItemRequest {
    id: string;
    historyEntryWrite?: HistoryEntryWrite;
}

/**
 * no description
 */
export class HistoryEntryApi extends BaseAPI {

    /**
     * Removes the HistoryEntry resource.
     */
    deleteHistoryEntryItem({ id }: DeleteHistoryEntryItemRequest): Observable<void>
    deleteHistoryEntryItem({ id }: DeleteHistoryEntryItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteHistoryEntryItem({ id }: DeleteHistoryEntryItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteHistoryEntryItem');

        return this.request<void>({
            url: '/api/history-entries/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of HistoryEntry resources.
     */
    getHistoryEntryCollection({ id, student, event, occurredAtBefore, occurredAtStrictlyBefore, occurredAtAfter, occurredAtStrictlyAfter, orderOccurredAt, orderDescription, orderEventName, page, itemsPerPage }: GetHistoryEntryCollectionRequest): Observable<InlineResponse2006>
    getHistoryEntryCollection({ id, student, event, occurredAtBefore, occurredAtStrictlyBefore, occurredAtAfter, occurredAtStrictlyAfter, orderOccurredAt, orderDescription, orderEventName, page, itemsPerPage }: GetHistoryEntryCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2006>>
    getHistoryEntryCollection({ id, student, event, occurredAtBefore, occurredAtStrictlyBefore, occurredAtAfter, occurredAtStrictlyAfter, orderOccurredAt, orderDescription, orderEventName, page, itemsPerPage }: GetHistoryEntryCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2006 | RawAjaxResponse<InlineResponse2006>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (student != null) { query['student[]'] = student; }
        if (event != null) { query['event[]'] = event; }
        if (occurredAtBefore != null) { query['occurredAt[before]'] = occurredAtBefore; }
        if (occurredAtStrictlyBefore != null) { query['occurredAt[strictly_before]'] = occurredAtStrictlyBefore; }
        if (occurredAtAfter != null) { query['occurredAt[after]'] = occurredAtAfter; }
        if (occurredAtStrictlyAfter != null) { query['occurredAt[strictly_after]'] = occurredAtStrictlyAfter; }
        if (orderOccurredAt != null) { query['order[occurredAt]'] = orderOccurredAt; }
        if (orderDescription != null) { query['order[description]'] = orderDescription; }
        if (orderEventName != null) { query['order[event.name]'] = orderEventName; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2006>({
            url: '/api/history-entries',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a HistoryEntry resource.
     */
    getHistoryEntryItem({ id }: GetHistoryEntryItemRequest): Observable<HistoryEntryRead>
    getHistoryEntryItem({ id }: GetHistoryEntryItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<HistoryEntryRead>>
    getHistoryEntryItem({ id }: GetHistoryEntryItemRequest, opts?: OperationOpts): Observable<HistoryEntryRead | RawAjaxResponse<HistoryEntryRead>> {
        throwIfNullOrUndefined(id, 'id', 'getHistoryEntryItem');

        return this.request<HistoryEntryRead>({
            url: '/api/history-entries/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a HistoryEntry resource.
     */
    postHistoryEntryCollection({ historyEntryWrite }: PostHistoryEntryCollectionRequest): Observable<HistoryEntryRead>
    postHistoryEntryCollection({ historyEntryWrite }: PostHistoryEntryCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<HistoryEntryRead>>
    postHistoryEntryCollection({ historyEntryWrite }: PostHistoryEntryCollectionRequest, opts?: OperationOpts): Observable<HistoryEntryRead | RawAjaxResponse<HistoryEntryRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<HistoryEntryRead>({
            url: '/api/history-entries',
            method: 'POST',
            headers,
            body: historyEntryWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the HistoryEntry resource.
     */
    putHistoryEntryItem({ id, historyEntryWrite }: PutHistoryEntryItemRequest): Observable<HistoryEntryRead>
    putHistoryEntryItem({ id, historyEntryWrite }: PutHistoryEntryItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<HistoryEntryRead>>
    putHistoryEntryItem({ id, historyEntryWrite }: PutHistoryEntryItemRequest, opts?: OperationOpts): Observable<HistoryEntryRead | RawAjaxResponse<HistoryEntryRead>> {
        throwIfNullOrUndefined(id, 'id', 'putHistoryEntryItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<HistoryEntryRead>({
            url: '/api/history-entries/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: historyEntryWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetHistoryEntryCollectionOrderOccurredAtEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetHistoryEntryCollectionOrderDescriptionEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetHistoryEntryCollectionOrderEventNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
