import * as React from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";

type PreviewProps = {
    data?: Data
    preview: boolean
}

type TestState = {
    data?: Data
}

interface Data {
    groups: string[]
    required: boolean
    description: string
}

export class Generic1FieldForm extends React.Component<PreviewProps> {

    state: TestState = {
        data: this.props.data
    }

    private handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.data!.description = event.target.value

        this.setState(prevState => {
            return {...prevState, data: this.state.data}
        })
    }

    public render = () => {
        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{width: '100%'}}>Opis</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                <TableRow>
                    <TableCell align={"center"}>
                        <TextField name={"description"} disabled={this.props.preview || false}
                                   value={this.state.data?.description} onChange={this.handleChangeDescription} multiline
                                   variant="outlined" style={{width: '100%'}}/>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }
}