import {Preview} from "app/Components";
import {Dictionary} from "lib/dictionaries";
import {Source} from "lib/select";
import * as React from 'react'
import {connect} from 'react-redux'

type QualificationsPreviewProps = {
    ids: string[]
}

class QualificationsPreviewComponent extends React.Component<QualificationsPreviewProps> {
    public render = () => <Preview
        value={this.props.ids}
        source={Source.QUALIFICATIONS}
        template={(dictionary: Dictionary) => <>{dictionary.name}</>}
    />
}

export const QualificationsPreview = connect()(QualificationsPreviewComponent)