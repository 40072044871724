import {CrudAddHeader} from 'app/Components'
import {CrudAction, Type} from 'lib/crud'
import {name, newSubject, Subject} from 'lib/subjects'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {Form} from './Form'

type DispatchToProps = {
    readonly handleSubmit: (subject: Subject) => void,
}

type AddProps = DispatchToProps

class AddComponent extends React.Component<AddProps> {
    public render = () => (<>
        <CrudAddHeader actionName={name}/>
        <Form initialValues={newSubject()} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (subject: Subject) => {
        delete subject.id
        dispatch(CrudAction.execute(name, Type.CREATE, subject))
    },
})

export const Add = connect(null, mapDispatchToProps)(AddComponent)