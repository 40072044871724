import {Button, Card, CardContent as MuiCardContent} from '@material-ui/core'
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from '@material-ui/icons/EditOutlined'
import ShowIcon from '@material-ui/icons/VisibilityOutlined'
import {spacing} from "@material-ui/system";
import {DeleteMenuItem, EditMenuItem, Menu, ShowMenuItem, SimpleDialog, Table} from 'app/Components'
import {AddButton} from 'app/Layout/Content'
import {generateDialogName} from 'external-lib/utils/helpers'
import {isGranted, me, Role} from 'lib/auth'
import {CrudAction, RouteType, Type} from 'lib/crud'
import {DialogAction} from 'lib/dialog'
import {ListOptions} from 'lib/dto'
import {NavigationAction} from 'lib/navigation'
import {generateCrudPath, getCrudPathLabels} from 'lib/router'
import {State} from 'lib/store'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom'
import styled from 'styled-components'
import {Field, Filter} from './Filter'

type Params = {
    parentId?: string
}

type StateToProps = {
    readonly user: User
}

type DispatchToProps = {
    load: (actionName: string, id: string) => void
    reset: (actionName: string) => void
    openDeleteDialog: (actionName: string) => void
    delete: (id: string, actionName: string) => void
    setHeader: (actionName: string, type: RouteType) => void
    setAction: (action: JSX.Element) => void
}

type ListProps = {
    actionName: string
    parentActionName?: string
    selector: (state: State) => any,
    cols: { [key: string]: any }[],
    deleteDialogText: string,
    filter?: {
        initialValues: any,
        fields: Field[]
    },
    tabs?: JSX.Element
    rules?: { [key: string]: Role[] },
}

type ListState = {
    id: string
}

const CardContent = styled(MuiCardContent)(spacing)

class ListComponent extends React.Component<ListProps & StateToProps & DispatchToProps & RouteComponentProps<Params>, ListState> {
    state: {
        id: ''
    }

    public componentDidMount(): void {
        this.props.reset(this.props.actionName)
        this.props.setHeader(this.props.actionName, RouteType.LIST)
        this.props.setAction(this.canAdd() ? <AddButton url={generateCrudPath(this.props.actionName, RouteType.ADD, {...this.getParams()})}/> : <></>)
        if (this.props.parentActionName && this.props.match.params.parentId) {
            this.props.load(this.props.parentActionName, this.props.match.params.parentId)
        }
    }

    private canAdd = (): boolean => {
        if (this.props.rules == undefined) {
            return true
        }
        if (this.props.rules.add == undefined) {
            return true
        }

        return isGranted(this.props.rules.add, this.props.user)
    }

    public render() {
        const handleDeleteDialogOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
            this.setState(prevState => {
                return {...prevState, id}
            })
            this.props.openDeleteDialog(this.props.actionName)
        }

        const handleDelete = () => {
            this.props.delete(this.state.id, this.props.actionName)
        }

        const editButton = (row: any) => <Button size="small"
                                                 component={NavLink}
                                                 color={'primary'}
                                                 to={generateCrudPath(this.props.actionName, RouteType.EDIT, {...this.getParams(), id: row.id})}
                                                 startIcon={<EditIcon/>}>Edytuj</Button>

        const showButton = (row: any) => <Button size="small"
                                                 component={NavLink}
                                                 color={'primary'}
                                                 to={generateCrudPath(this.props.actionName, RouteType.SHOW, {...this.getParams(), id: row.id})}
                                                 startIcon={<ShowIcon/>}>Podgląd</Button>

        const deleteButton = (row: any) => <Button size="small"
                                                   color={'primary'}
                                                   variant="contained"
                                                   onClick={(event) => handleDeleteDialogOpen(event, row.id)}
                                                   startIcon={<DeleteIcon/>}>Usuń</Button>

        const getActionButton = (row: any) => {
            if (canShow()) {
                return showButton(row)
            }

            if (canEdit(row)) {
                return editButton(row)
            }

            if (canDelete(row)) {
                return deleteButton(row)
            }

            return null
        }

        const actions = (row: any) => {
            if (onlyDeleteButton()) {
                return canDelete(row) ? deleteButton(row) : null
            }

            let menuActions = []
            if (canEdit(row) && canShow()) {
                menuActions.push(<EditMenuItem to={generateCrudPath(this.props.actionName, RouteType.EDIT, {...this.getParams(), id: row.id})}/>)
            }

            if ((canEdit(row) || canShow()) && canDelete(row)) {
                menuActions.push(<DeleteMenuItem onClickHandler={(event) => handleDeleteDialogOpen(event, row.id)}/>)
            }

            return <Menu
                id={row.id}
                button={getActionButton(row)}
                actions={menuActions}
                showSubmenu={menuActions.length > 0}
            />
        }

        const getReloadAction = (options: ListOptions, filters: { [paramName: string]: string | number | boolean | undefined } ) => {
            return CrudAction.execute(this.props.actionName, Type.LIST, {
                orderName: options.sortDirection,
                page: options.page + 1,
                itemsPerPage: options.pageSize,
                ...filters
            })
        }

        const canShow = (): boolean => {
            if (this.props.rules == undefined) {
                return true
            }
            if (this.props.rules.show == undefined) {
                return true
            }

            return isGranted(this.props.rules.show, this.props.user)
        }

        const canEdit = (row: any): boolean => {
            return row.metadata !== undefined
                ? row.metadata.canEdit
                : false
        }

        const canDelete = (row: any): boolean => {
            return row.metadata !== undefined
                ? row.metadata.canDelete
                : false
        }

        const onlyDeleteButton = (): boolean => {
            if (this.props.rules == undefined) {
                return false
            }
            return this.props.rules.deleteOnly != undefined
        }

        return (
            <>
                {this.props.tabs}
                <Card variant="outlined">
                    <CardContent>
                        {this.props.filter
                            ? <Filter
                                actionName={this.props.actionName}
                                selector={this.props.selector}
                                initialValues={this.props.filter!.initialValues}
                                fields={this.props.filter!.fields} />
                            : null
                        }
                        <Table
                            selector={this.props.selector}
                            getReloadAction={getReloadAction}
                            cols={this.props.cols}
                            actions={actions}
                        />
                    </CardContent>
                </Card>

                <SimpleDialog
                    id={generateDialogName(this.props.actionName, Type.DELETE)}
                    title={'Potwierdź usunięcie'}
                    text={this.props.deleteDialogText}
                    confirmLabel={'Usuń'}
                    handleConfirm={handleDelete}
                />
            </>
        )
    }

    private getParams = () => {
        return {parentId: this.props.match.params.parentId}
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    user: me(state)!,
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    setHeader: (actionName, type) => {
        dispatch(NavigationAction.setHeader(getCrudPathLabels(actionName, type)))
    },
    setAction: (action: JSX.Element) => {
        dispatch(NavigationAction.setAction(action))
    },
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    reset: (actionName: string) => {
        dispatch(CrudAction.execute(actionName, Type.RESET, {}))
    },
    openDeleteDialog: (actionName: string) => {
        dispatch(DialogAction.open(generateDialogName(actionName, Type.DELETE)))
    },
    delete: (id: string, actionName: string) => {
        dispatch(CrudAction.execute(actionName, Type.DELETE, {id}))
    }
})

export const CrudList = withRouter(connect(mapStateToProps, mapDispatchToProps)(ListComponent))
