import {CrudAddHeader} from 'app/Components'
import {CrudAction, Type} from 'lib/crud'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {Form} from './Form'
import {Group, name, newGroup} from "lib/groups";

type DispatchToProps = {
    readonly handleSubmit: (group: Group) => void,
}

type AddProps = DispatchToProps

class AddComponent extends React.Component<AddProps> {
    public render = () => (<>
        <CrudAddHeader actionName={name}/>
        <Form initialValues={newGroup()} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (group: Group) => {
        delete group.id
        dispatch(CrudAction.execute(name, Type.CREATE, group))
    },
})

export const Add = connect(null, mapDispatchToProps)(AddComponent)