import {
    CrudAction,
    dispatchAfterFailureEpic,
    dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {NotificationsAction} from 'lib/notifications'
import {generateCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {dao, name} from 'lib/users'
import {combineEpics} from 'redux-observable'
import {NavigationAction} from "../navigation";

export const usersEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.CREATE)(dao.create),
    executeCrudEpic(name, Type.UPDATE)(dao.update),
    executeCrudEpic(name, Type.DELETE)(dao.delete),

    redirectAfterSuccessEpic(name, Type.CREATE, (action, state: State) => generateCrudPath(name, RouteType.LIST)),
    redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => generateCrudPath(name, RouteType.LIST)),

    dispatchAfterSuccessEpic(name, Type.GET, (action, state: State) => NavigationAction.setSubheader(state.users.crud.row.lastName + ' ' + state.users.crud.row.firstName)),

    dispatchAfterSuccessEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nowego użytkownika',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas dodawania nowego użytkownika',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Dane użytkownika zostały zaktualizowane',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas aktualizacji użytkownika',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Użytkownik został skasowany',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas usuwania użytkownika',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.users.crud.listOptions.sortDirection,
        page: state.users.crud.listOptions.page + 1,
        itemsPerPage: state.users.crud.listOptions.pageSize,
        ...state.users.crud.filters
    })),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.users.crud.listOptions.sortDirection,
        page: state.users.crud.listOptions.page + 1,
        itemsPerPage: state.users.crud.listOptions.pageSize,
        ...state.users.crud.filters
    }))
)