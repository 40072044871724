import {ActionsUnion, createAction} from "external-lib/redux-utils";

export const PreviewActionType = {
    Add: (source: string) => `[PREVIEW] add value to ${source}`,
    Remove: (source: string) => `[PREVIEW] remove value from ${source}`,

    Load: (source: string) => `[PREVIEW] load (source: ${source})`,
    LoadFailure: (source: string) => `[PREVIEW] load failure (source: ${source})`,
    LoadSuccess: (source: string) => `[PREVIEW] load success (source: ${source})`,
}

export const PreviewAction = {
    add: (source: string, ids: string[]) => createAction(PreviewActionType.Add(source), {source, ids}),
    remove: (source: string, ids: string[]) => createAction(PreviewActionType.Remove(source), {source, ids}),

    load: (source: string, ids: string[]) => createAction(PreviewActionType.Load(source), {source, ids}),
    loadFailure: (source: string, error: any) => createAction(PreviewActionType.LoadFailure(source), error),
    loadSuccess: (source: string, response: any) => createAction(PreviewActionType.LoadSuccess(source), response),
}

export type PreviewAction = ActionsUnion<typeof PreviewAction>
