import {Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    index: number
    section: KsTestSection
    preview?: boolean
    prev: null|number
    next: null|number
}

type SectionState = {
    section: KsTestSection
}

export type KsTestSection = {
    section: string
    subsections: Subsection[]
}

interface TestItem {
    answer: boolean;
    groups: string[];
    question: string;
    required: boolean;
}

interface Subsection {
    norm: number;
    items: TestItem[];
    subsection: string;
}

export class KsSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let keys = event.target.name.split('|')
        this.state.section.subsections[parseInt(keys[0])].items[parseInt(keys[1])].answer = event.target.checked;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => <Box style={this.props.preview ? {margin: '0px 0px 50px 0px'} : {}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2} style={{whiteSpace: "nowrap"}}><Typography variant={"h4"}>{this.props.section.section}</Typography></TableCell>
                    <TableCell align={'center'} style={{width: '50px'}}>Tak/Nie</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {this.state.section.subsections.map(
                    (subsection, subsectionIndex) =>
                    <>
                        {subsection.subsection !== "" ?
                            <TableRow key={subsectionIndex} >
                                <TableCell colSpan={5}><Typography variant={"h6"}>{subsection.subsection}</Typography></TableCell>
                            </TableRow>
                        : "" }
                        {subsection.items.map(
                            (item, itemIndex) =>
                                <TableRow key={itemIndex}>
                                    <TableCell align={'right'}
                                               style={{width: '50px'}}>{itemIndex + 1}.</TableCell>
                                    <TableCell>
                                        {this.props.preview && item.required && itemIndex == 0 ?
                                            <Box style={{color: 'red'}}>{item.question}</Box> : item.question}
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <Checkbox
                                            name={subsectionIndex + '|' + itemIndex + '|0'}
                                            checked={item.answer == true}
                                            onChange={this.handleChange}
                                            disabled={this.props.preview || false}
                                            color="primary"
                                        />
                                    </TableCell>
                                </TableRow>
                        )}
                    </>,
                    this
                )}
            </TableBody>
        </Table>
    </Box>
}