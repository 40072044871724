import React from 'react'
import styled from 'styled-components'
import {Route, Switch} from 'react-router'
import {Routes} from 'lib/router'
import {SignIn} from './SignIn'
import {Footer} from './Footer'
import {BeforeRedirect} from "app/Components/Route/BeforeRedirect";
import {Notification} from 'app/Components/Notification'
import {ResetPassword} from "app/Views/Auth/ResetPassword";
import {ResetPasswordChange} from "app/Views/Auth/ResetPasswordChange";
import {TermsOfService} from "app/Views/TermsOfService";

const ContainerStyled = styled.div`
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  min-height: 100%;
`

const Logo = styled.img`
  max-width: 60%;
  margin: -70px;
  
  ${props => props.theme.breakpoints.up("sm")} {
    max-width: 40%;
  }
  
  ${props => props.theme.breakpoints.up("md")} {
    max-width: 250px;
  }
`

export class Layout extends React.Component {
    public render() {
        return (
            <ContainerStyled>
                <Logo src={"/logo-192x192.png"}/>
                <Switch>
                    <Route path={Routes.TermsOfService}>
                        <TermsOfService/>
                    </Route>
                    <Route path={Routes.AuthSignIn}>
                        <SignIn/>
                    </Route>
                    <Route path={Routes.AuthResetPasswordToken}>
                        <ResetPasswordChange/>
                    </Route>
                    <Route path={Routes.AuthResetPassword}>
                        <ResetPassword/>
                    </Route>
                    <Route path={'/'}>
                        <BeforeRedirect />
                    </Route>
                </Switch>
                <Notification/>
                <Footer/>
            </ContainerStyled>
        )
    }
}