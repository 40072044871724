import {Box, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Grid} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import * as React from 'react'
import styled from 'styled-components'

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

const Divider = styled(MuiDivider)(spacing)

type NavigationProps = {
    breadcrumbs: JSX.Element[]
    action?: JSX.Element
}

export class Navigation extends React.Component<NavigationProps> {
    public render = () => (
        <>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Breadcrumbs mt={2}>
                        {this.props.breadcrumbs.map((element, index) => <Box key={index}>{element}</Box>, this)}
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{textAlign: 'right'}}>{this.props.action}</Grid>
            </Grid>

            <Divider my={6}/>
        </>
    )
}