import {configuration} from 'lib/client'
import {StudentApi} from "client/apis";
import {map} from "rxjs/operators";

const StudentApiClient = (): StudentApi => {
    return new StudentApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        const offset = (payload.page - 1) * payload.itemsPerPage
        return StudentApiClient().getStudentCollection(payload).pipe(map( response => {
            return {
                ...response,
                items: response.items?.map((student, index) => {
                    return {
                        ...student,
                        no: offset + index + 1,
                    }
                })
            }
        }))
    },
    summary: (payload: any) => {
        return StudentApiClient().summariesStudentItem(payload)
    },
    get: (payload: any) => {
        return StudentApiClient().getStudentItem({id: payload.id})
    },
    create: (payload: any) => {
        return StudentApiClient().postStudentCollection({studentWrite: payload})
    },
    update: (payload: any) => {
        return StudentApiClient().putStudentItem({id: payload.id, studentWrite: payload.data})
    },
    delete: (payload: any) => {
        return StudentApiClient().deleteStudentItem({id: payload.id})
    },
    evaluations: (payload: any) => {
        return StudentApiClient().evaluationsStudentItem(payload)
    }
}