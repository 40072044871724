import {
    CrudAction,
    dispatchAfterFailureEpic,
    dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {NotificationsAction} from 'lib/notifications'
import {generateCrudPath, generateRouterCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {dao, name, StudentAction, StudentActionType} from 'lib/students'
import {dao as daoTest} from 'lib/test'
import {combineEpics, Epic, ofType} from 'redux-observable'
import {of} from 'rxjs'
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators'
import {NavigationAction} from '../navigation'

const analyseStudent: Epic<StudentAction> = action$ => action$.pipe(
    ofType(StudentActionType.analyseStep1),
    switchMap(({payload}) => {
        return dao.evaluations({...payload, from: new Date(payload.from), to: new Date(payload.to)}).pipe(
            map((response) => StudentAction.evaluateSuccess(response)),
            catchError(error => of(StudentAction.evaluateFailure(error))),
        )
    })
)

const analyseStudentStep1: Epic<StudentAction> = action$ => action$.pipe(
    ofType(StudentActionType.analyseStep1),
    switchMap(({payload}) => {
        return dao.get(payload).pipe(
            map((response) => StudentAction.analyseStep1Success(response)),
            catchError(error => of(StudentAction.analyseStep1Failure(error))),
        )
    })
)

const analyseStudentStep2: Epic<StudentAction> = (action$, state$) => action$.pipe(
    ofType(StudentActionType.analyseStep1Success),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const payload = {
            ...state.students.analyseFilter,
            student: [state.students.crud.row.id],
            group: state.students.crud.row.groups
        }

        delete payload['id']

        return daoTest.compare({...payload, from: new Date(payload.from), to: new Date(payload.to)}).pipe(
            map((response) => StudentAction.analyseStep2Success(response)),
            catchError(error => of(StudentAction.analyseStep2Failure(error))),
        )
    })
)

export const studentsEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.CREATE)(dao.create),
    executeCrudEpic(name, Type.UPDATE)(dao.update),
    executeCrudEpic(name, Type.DELETE)(dao.delete),

    redirectAfterSuccessEpic(name, Type.CREATE, (action, state: State) => generateRouterCrudPath(name, RouteType.LIST)),
    redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => {
        if (state.students.crud.redirect !== '') {
            return generateCrudPath(state.students.crud.redirect, RouteType.LIST, {parentId: state.students.crud.row.id})
        }

        return generateRouterCrudPath(name, RouteType.LIST)
    }),

    dispatchAfterSuccessEpic(name, Type.GET, (action, state: State) => NavigationAction.setSubheader(state.students.crud.row.lastName + ' ' + state.students.crud.row.firstName)),

    dispatchAfterSuccessEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nowe dziecko',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas dodawania nowego dziecka',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Dane dziecka zostało zaktualizowane',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas aktualizacji danych dziecka',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Dane dziecka zostało usunięte',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas usuwania danych dziecka',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.students.crud.listOptions.sortDirection,
        page: state.students.crud.listOptions.page + 1,
        itemsPerPage: state.students.crud.listOptions.pageSize,
        ...state.students.crud.filters,
    })),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.students.crud.listOptions.sortDirection,
        page: state.students.crud.listOptions.page + 1,
        itemsPerPage: state.students.crud.listOptions.pageSize,
        ...state.students.crud.filters,
    })),

    analyseStudent,
    analyseStudentStep1,
    analyseStudentStep2,
)