import {TestTypeApi} from 'client/apis'
import {configuration} from 'lib/client'

const TestTypeApiClient = (): TestTypeApi => {
    return new TestTypeApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return TestTypeApiClient().getTestTypeCollection({page: 1, itemsPerPage: 50})
    },
    get: (payload: any) => {
        return TestTypeApiClient().getTestTypeItem({id: payload.id})
    },
}