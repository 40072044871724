// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20021,
    TestStatusRead,
} from '../models';

export interface GetTestStatusCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetTestStatusItemRequest {
    id: string;
}

/**
 * no description
 */
export class TestStatusApi extends BaseAPI {

    /**
     * Retrieves the collection of TestStatus resources.
     */
    getTestStatusCollection({ page, itemsPerPage }: GetTestStatusCollectionRequest): Observable<InlineResponse20021>
    getTestStatusCollection({ page, itemsPerPage }: GetTestStatusCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20021>>
    getTestStatusCollection({ page, itemsPerPage }: GetTestStatusCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20021 | RawAjaxResponse<InlineResponse20021>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20021>({
            url: '/api/test-statuses',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a TestStatus resource.
     */
    getTestStatusItem({ id }: GetTestStatusItemRequest): Observable<TestStatusRead>
    getTestStatusItem({ id }: GetTestStatusItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestStatusRead>>
    getTestStatusItem({ id }: GetTestStatusItemRequest, opts?: OperationOpts): Observable<TestStatusRead | RawAjaxResponse<TestStatusRead>> {
        throwIfNullOrUndefined(id, 'id', 'getTestStatusItem');

        return this.request<TestStatusRead>({
            url: '/api/test-statuses/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
