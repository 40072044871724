import {Button, Grid, Typography} from '@material-ui/core'
import {TestConfigPreview} from 'app/Components'
import {getRow, RouteType} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {generateCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {Student} from 'lib/students'
import {Test, testName} from 'lib/test'
import {selectConfig} from 'lib/testConfig'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {NavLink, RouteComponentProps} from 'react-router-dom'

type StateToProps = {
    readonly student: Student
    readonly test: Test
    readonly isLoading: boolean
}

type DispatchToProps = {}

type Params = {
    parentId: string
    id: string
}

type TestMenuState = {
    section: number
}

class TestHeaderComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>, TestMenuState> {
    state: TestMenuState = {
        section: 0
    }

    componentDidUpdate(prevProps: Readonly<StateToProps & DispatchToProps & RouteComponentProps<Params>>, prevState: Readonly<TestMenuState>, snapshot?: any) {
        const query = new URLSearchParams(this.props.location.search)
        const section = parseInt(query.get("section") || '1') - 1

        if (this.state.section !== section) {
            this.setState(prevState => {
                return {...prevState, section: section}
            })
        }
    }

    public render = () => {
        const testConfig = selectConfig(this.props.test.config)

        if (testConfig === undefined || testConfig.menu === null) {
            return <Grid item xs={11} sm={11} md={11} lg={12} style={{paddingTop: '10px'}}>
                <Typography
                    variant="h4"
                    style={{color: 'rgba(0,0,0,0.87)'}}
                >
                    <TestConfigPreview id={this.props.test.config} />
                </Typography>
            </Grid>
        }

        return <>
            <Grid item xs={11} sm={11} md={11} lg={12} style={{paddingTop: '10px'}}>
                <Typography
                    variant="h4"
                    style={{color: 'rgba(0,0,0,0.87)'}}
                >
                    <TestConfigPreview id={this.props.test.config} />
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    component={NavLink}
                    disabled={this.state.section <= 0}
                    to={generateCrudPath(testName, RouteType.EDIT, {parentId: this.props.match.params.parentId, id: this.props.match.params.id}) + '?section=' + (this.state.section)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left"
                         width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                         stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <line x1="5" y1="12" x2="11" y2="18"></line>
                        <line x1="5" y1="12" x2="11" y2="6"></line>
                    </svg>
                </Button>
            </Grid>
            {!this.props.isLoading && this.props.test.content
                ? testConfig.menu.data(this.props.test).map(
                    (section: any, index: number) => {
                        if (this.state.section == index) {
                            return <Grid item key={index} xs>
                                <Typography variant={"h5"} style={{color: 'rgba(0,0,0,0.87)', display: 'inline'}}>{(index + 1) + '. ' + testConfig.menu.label(section)}</Typography>
                            </Grid>
                        }
                        if (this.state.section+1 == index) {
                            return
                        }

                        return null
                    },
                    this
                ) : ''}
            <Grid item>
                <Button
                    style={{margin: '5px;'}}
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    component={NavLink}
                    disabled={this.state.section + 2 > testConfig.menu.data(this.props.test).length}
                    to={generateCrudPath(testName, RouteType.EDIT, {parentId: this.props.match.params.parentId, id: this.props.match.params.id}) + '?section=' + (this.state.section + 2)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right"
                         width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                         stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <line x1="13" y1="18" x2="19" y2="12"></line>
                        <line x1="13" y1="6" x2="19" y2="12"></line>
                    </svg>
                </Button>
            </Grid>
        </>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    test: getRow(state => state.tests)(state),
    isLoading: isLoading(state)
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({})

export const TestHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestHeaderComponent))