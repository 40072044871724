import {Button, Card, CardContent as MuiCardContent, FormControl, Grid, TextField} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import {CircularProgressBar} from 'app/Components'
import {onKeyDown} from 'external-lib/utils/helpers'
import {Formik} from 'formik'
import {isLoading, isProcessing} from 'lib/progress'
import {State} from 'lib/store'
import {Profile} from 'lib/users'
import React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

const CardContent = styled(MuiCardContent)(spacing)

type StateToProps = {
    readonly isLoading: boolean
    readonly isProcessing: boolean
}

type FormControlProps = {
    initialValues: Profile
    handleSubmit: (user: Profile) => void
}

const Schema = Yup.object().shape({
    id: Yup.string(),
    login: Yup.string()
        .required('Pole jest wymagane'),
    firstName: Yup.string()
        .required('Pole jest wymagane'),
    lastName: Yup.string()
        .required('Pole jest wymagane'),
    email: Yup.string()
        .email('Podaj poprawny adres e-mail')
        .required('Pole jest wymagane'),
})

class ProfileFormComponent extends React.Component<FormControlProps & StateToProps> {
    public render = () =>
        <>
            {
                this.props.isLoading
                    ? <CircularProgressBar/>
                    : <Formik
                        initialValues={this.props.initialValues}
                        validationSchema={Schema}
                        onSubmit={values => this.props.handleSubmit(values)}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit
                          }) => (
                            <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>

                                <Card variant="outlined">
                                    <CardContent>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.email && touched.email)}
                                                        label="E-mail *"
                                                        id="email"
                                                        name="email"
                                                        variant="outlined"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.email}
                                                        inputProps={{
                                                            maxLength: 100,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.login && touched.login)}
                                                        label="Login *"
                                                        id="login"
                                                        name="login"
                                                        variant="outlined"
                                                        value={values.login}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.login}
                                                        inputProps={{
                                                            maxLength: 30,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.firstName && touched.firstName)}
                                                        label="Imię *"
                                                        id="firstName"
                                                        name="firstName"
                                                        variant="outlined"
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.firstName}
                                                        inputProps={{
                                                            maxLength: 100,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.lastName && touched.lastName)}
                                                        label="Nazwisko *"
                                                        id="lastName"
                                                        name="lastName"
                                                        variant="outlined"
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.lastName}
                                                        inputProps={{
                                                            maxLength: 100,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.phone && touched.phone)}
                                                        label="Telefon"
                                                        id="phone"
                                                        name="phone"
                                                        variant="outlined"
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.phone}
                                                        inputProps={{
                                                            maxLength: 100,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSubmit()}
                                                    disabled={this.props.isProcessing}
                                                >
                                                    Zapisz
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
            }
        </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    isProcessing: isProcessing(state),
})

export const ProfileForm = connect(mapStateToProps)(ProfileFormComponent)