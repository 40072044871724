import {CrudEditHeader} from 'app/Components'
import {CrudAction, getRow, Type} from 'lib/crud'
import {name, GroupClass} from 'lib/groupClass'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {Tabs} from "../../Tabs";
import {Form} from './Form'

type StateToProps = {
    readonly groupClass: GroupClass
}

type DispatchToProps = {
    readonly handleSubmit: (groupClass: GroupClass) => void,
}

type Params = {
    parentId: string
}

type EditProps = StateToProps & DispatchToProps

class EditComponent extends React.Component<EditProps & RouteComponentProps<Params>> {
    public render = () => (<>
        <CrudEditHeader actionName={name}/>
        <Tabs id={this.props.match.params.parentId} />
        <Form initialValues={this.props.groupClass} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    groupClass: getRow(state => state.groupClass)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (groupClass: GroupClass) => {
        const groupClassId = groupClass.id
        delete groupClass.id
        dispatch(CrudAction.execute(name, Type.UPDATE, {
            id: groupClassId,
            data: groupClass
        }))
    }
})

export const Edit = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditComponent))