import * as React from 'react'
import {Box, Button, FormControl, TextField, Typography} from '@material-ui/core'
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "lib/store";
import {AuthAction, isSubmitting} from "lib/auth";
import * as Yup from 'yup'
import {Formik} from 'formik';
import {LinearProgressBar} from "app/Components/ProgressBar";
import {Wrapper} from "app/Views/Auth/Wrapper";

type StateToProps = {
    isSubmitting: boolean
}

type DispatchToProps = {
    readonly handleResetPassword: (email: string) => void,
}

type ResetPasswordProps = StateToProps & DispatchToProps

const Schema = Yup.object().shape({
    email: Yup.string()
        .email('Niepoprawny adres e-mail')
        .required('E-mail jest wymagany do przypomnienia hasła'),
})

class ResetPasswordComponent extends React.Component<ResetPasswordProps>
{
    public render() {
        return (
            <Box mb={2}>
                <LinearProgressBar isVisible={this.props.isSubmitting}/>
                <Wrapper variant="outlined">
                    <Typography component="h1" variant="h4" align="center" gutterBottom>
                        Przypomnij hasło
                    </Typography>
                    <Typography component="h2" variant="body1" align="center">
                        Wpisz e-mail, na który zostanie wysłana wiadomość
                    </Typography>
                    <Formik
                        initialValues={{email: ''}}
                        validationSchema={Schema}
                        validateOnChange={false}
                        onSubmit={(values) => {
                            this.props.handleResetPassword(values.email)
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <FormControl margin="normal" required fullWidth>
                                    <TextField
                                        error={Boolean(errors.email && touched.email)}
                                        label="E-mail *"
                                        id="email"
                                        name="email"
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                        autoComplete="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        variant="outlined"
                                        helperText={errors.email}
                                    />
                                </FormControl>
                                <Box mb={2} marginTop={3}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.isSubmitting}
                                    >
                                       Przypomnij hasło
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Wrapper>
            </Box>
        )
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isSubmitting: isSubmitting(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleResetPassword: (email: string) => {
        dispatch(AuthAction.passwordResetInitialization({
            passwordResetInitializationRequest: {
                email: email
            }
        }))
    }
})

export const ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent)