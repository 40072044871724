import {
    CrudAction, dispatchAfterFailureEpic, dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {NotificationsAction} from 'lib/notifications'
import {generateRouterCrudPath} from 'lib/router'
import {dao, name} from 'lib/schools'
import {State} from 'lib/store'
import {combineEpics} from 'redux-observable'
import {NavigationAction} from "../navigation";

export const schoolsEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.CREATE)(dao.create),
    executeCrudEpic(name, Type.UPDATE)(dao.update),
    executeCrudEpic(name, Type.DELETE)(dao.delete),

    redirectAfterSuccessEpic(name, Type.CREATE, (action, state: State) => generateRouterCrudPath(name, RouteType.LIST)),
    redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => generateRouterCrudPath(name, RouteType.LIST)),

    dispatchAfterSuccessEpic(name, Type.GET, (action, state: State) => NavigationAction.setSubheader(state.schools.crud.row.name)),

    dispatchAfterSuccessEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nową placówkę',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas dodawania nowej placówki',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Dane placówki zostały zaktualizowane',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas aktualizacji placówki',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Placówka została skasowana',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas usuwania placówki',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.schools.crud.listOptions.sortDirection,
        page: state.schools.crud.listOptions.page + 1,
        itemsPerPage: state.schools.crud.listOptions.pageSize,
        ...state.schools.crud.filters
    })),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.schools.crud.listOptions.sortDirection,
        page: state.schools.crud.listOptions.page + 1,
        itemsPerPage: state.schools.crud.listOptions.pageSize,
        ...state.schools.crud.filters
    }))
)