import {Card, CardContent} from '@material-ui/core'
import {CircularProgressBar, CrudEditHeader} from 'app/Components'
import {onKeyDown} from 'external-lib/utils/helpers'
import {Formik} from 'formik'
import {CrudAction, getRow, Type} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import {Test, TestAction, testName} from 'lib/test'
import {selectConfig} from 'lib/testConfig'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'

type StateToProps = {
    readonly student: Student
    readonly test: Test
    readonly isLoading: boolean
}

type DispatchToProps = {
    load: (actionName: string, id: string) => void
    reset: (actionName: string) => void
    clear: (actionName: string) => void
    handleSubmit: (test: Test) => void
    determineIfCorrect: (isCorrect: boolean) => void
    setInvalidSection: (sectionNumber: number) => void
}

type Params = {
    parentId: string
    id: string
}

type EditState = {
    section: number
}

class EditComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>, EditState> {
    ref: React.RefObject<HTMLFormElement> = React.createRef();
    state: EditState = {
        section: 0
    }

    public componentDidMount(): void {
        // this.props.reset(testName)

        const query = new URLSearchParams(this.props.location.search)
        const section = parseInt(query.get("section") || '1')

        this.setState(prevState => {
            return {...prevState, section: section - 1}
        })

        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
        if (this.props.match.params.id) {
            this.props.load(testName, this.props.match.params.id)
        }

        this.props.determineIfCorrect(true)
        this.props.setInvalidSection(0)
    }

    public componentDidUpdate(prevProps: Readonly<StateToProps & DispatchToProps & RouteComponentProps<Params>>, prevState: Readonly<EditState>, snapshot?: any) {
        if (this.props.location.search != prevProps.location.search) {
            const query = new URLSearchParams(this.props.location.search)
            const section = parseInt(query.get("section") || '1')

            this.setState(prevState => {
                return {...prevState, section: section - 1}
            })

            this.ref.current!.scrollIntoView()
        }
    }

    public componentWillUnmount() {
        this.props.clear(testName)
    }

    public render = () => {
        const testConfig = selectConfig(this.props.test.config)

        if (testConfig === undefined) {
            return <></>
        }

        return (<>
            <CrudEditHeader actionName={testName}/>
            {this.props.isLoading
                ? <CircularProgressBar/>
                : <>
                    {this.props.test && this.props.test.content ? <Formik
                        initialValues={this.props.test}
                        onSubmit={values => this.props.handleSubmit(values)}
                    >
                        {({
                              values,
                              handleSubmit,
                          }) => {
                            return (<form id={"test-form"} onSubmit={handleSubmit} onKeyDown={onKeyDown} ref={this.ref} style={{paddingTop: '10px'}}>
                                <Card variant={'outlined'} style={{marginBottom: '20px'}}>
                                    <CardContent>
                                        {values.content && testConfig.form !== null ? React.createElement(testConfig.form, {
                                            data: values.content.data,
                                            preview: false,
                                            sectionIndex: this.state.section
                                        }) : ''}
                                    </CardContent>
                                </Card>
                            </form>)
                        }}
                    </Formik> : <CircularProgressBar/>}
            </>}
        </>)
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    test: getRow(state => state.tests)(state),
    isLoading: isLoading(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    reset: (actionName: string) => {
        dispatch(CrudAction.execute(actionName, Type.RESET, {}))
    },
    clear: (actionName: string) => {
        dispatch(CrudAction.executeResponseSuccess(actionName, Type.GET, {}))
    },
    handleSubmit: (test: Test) => {
        let t = {...test}
        const testId = t.id
        delete t.id
        dispatch(CrudAction.execute(testName, Type.UPDATE, {
            id: testId,
            data: t
        }))
    },
    determineIfCorrect: (isCorrect: boolean) => {
        dispatch(TestAction.determineIfCorrect(isCorrect))
    },
    setInvalidSection: (sectionNumber: number) => {
        dispatch(TestAction.setInvalidSection(sectionNumber))
    }
})

export const Edit = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditComponent))