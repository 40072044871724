import {Box} from '@material-ui/core'
import Link from '@material-ui/core/Link'
import {CrudList, Enum, FilterType, GroupPreview, SchoolPreview, Status} from 'app/Components'
import {rules} from 'lib/auth'
import {RouteType} from 'lib/crud'
import {name as educationStatusName} from 'lib/educationStatus'
import {generateCrudPath} from 'lib/router'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {name, Student} from 'lib/students'
import * as React from 'react'
import {NavLink} from 'react-router-dom'

const cols = [
    {
        id: "lp",
        numeric: false,
        label: "Lp.",
        sort: false,
        width: '20px',
        template: (student: Student) => student.no + '.'
    },
    {
        id: "name",
        numeric: false,
        label: "Dziecko",
        sort: false,
        template: (student: Student) => (<>
            <Link component={NavLink} to={generateCrudPath(name, RouteType.SHOW, {id: student.id})}>{student.lastName} {student.firstName}</Link>
            <Box>({student.identifier})</Box>
        </>)
    },
    {
        id: "school",
        numeric: false,
        label: "Placówka",
        sort: false,
        width: '20%',
        template: (student: Student) => <SchoolPreview id={student.school} />
    },
    {
        id: "group",
        numeric: false,
        label: "Grupa",
        sort: false,
        width: '15%',
        template: (student: Student) => <GroupPreview ids={student.groups} />
    },
    {
        id: "category",
        numeric: false,
        label: "Kategoria",
        sort: false,
        width: '100px',
        template: (student: Student) => student.category
    },
    {
        id: "status",
        numeric: false,
        label: "Status",
        sort: false,
        width: '100px',
        template: (student: Student) => <Enum name={educationStatusName} id={student.status}/>,
        rule: rules.student.status
    },
]

export class List extends React.Component {
    public render = () => (
        <CrudList
            actionName={name}
            cols={cols}
            selector={(state: State) => state.students}
            deleteDialogText="Usunąć dziecko?"
            rules={rules.student}
            filter={{
                initialValues: {
                    search: '',
                    schools: [],
                    category: [],
                    status: 'ONGOING',
                    groups: [],
                },
                fields: [
                    {name: 'search', label: 'Imię / nazwisko / id', type: FilterType.TEXT},
                    {name: 'school', label: 'Placówka', type: FilterType.MULTIPLE_SELECT, source: Source.SCHOOLS},
                    {name: 'groups', label: 'Grupy', type: FilterType.MULTIPLE_SELECT, source: Source.GROUPS},
                    {
                        name: 'status',
                        label: 'Status',
                        type: FilterType.SELECT,
                        source: Source.EDUCATION_STATUS,
                        rule: rules.student.status
                    },
                    {name: 'category', label: 'Kategoria', type: FilterType.MULTIPLE_SELECT, source: Source.STUDENTS_CATEGORY}
                ]
            }}
        />
    )
}