import {Box, Button, Grid, Toolbar, Typography} from "@material-ui/core";
import * as React from 'react'
import {connect, MapStateToProps} from "react-redux";
import {withRouter} from 'react-router'
import {NavLink, RouteComponentProps} from 'react-router-dom'
import styled from "styled-components";
import {getRow, RouteType} from "lib/crud";
import {isLoading} from "lib/progress";
import {generateCrudPath} from "lib/router";
import {State} from "lib/store";
import {Student} from "lib/students";
import {Test, testName} from "lib/test";

const Footer = styled.div`
  width: 100%;
  background: #FFFFFF;
  padding: 15px;
`

type StateToProps = {
    readonly student: Student
    readonly test: Test
    readonly isLoading: boolean
}

type Params = {
    parentId: string
    id: string
}

type TestFooterState = {
    section: number
}

class TestFooterComponent extends React.Component<StateToProps & RouteComponentProps<Params>, TestFooterState> {
    state: TestFooterState = {
        section: 0
    }

    public render = () => <Footer style={{borderTop: 'solid 1px rgba(224, 224, 224, 1)'}}>
        <Grid container spacing={4}>
            <Grid item sm>
                <Typography
                    variant="h4"
                    display="inline"
                    style={{color: 'rgba(0,0,0,0.87)'}}
                >
                    {this.props.student.lastName} {this.props.student.firstName}
                </Typography>
                <Box>
                    [{this.props.student.identifier}]
                </Box>
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    color="primary"
                    component={NavLink}
                    to={generateCrudPath(testName, RouteType.LIST, {parentId: this.props.student.id || ' '})}
                >
                    Powrót
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    form="test-form"
                    type="submit"
                >
                    Podgląd
                </Button>
            </Grid>
        </Grid>
    </Footer>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    test: getRow(state => state.tests)(state),
    isLoading: isLoading(state),
})

export const TestFooter = withRouter(connect(mapStateToProps)(TestFooterComponent))