import {Card, CardContent} from "@material-ui/core";
import {CircularProgressBar, CrudShowHeader} from 'app/Components'
import {CrudAction, getRow, Type} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import {getTestSummary, Test, TestAction, testName} from 'lib/test'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import {Tabs} from "./Tabs";
import {selectConfig} from 'lib/testConfig'

type StateToProps = {
    readonly student: Student
    readonly test: Test
    readonly isLoading: boolean
    readonly summary: any
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
    readonly loadSummary: (id: string) => void,
}

type Params = {
    parentId: string
    id: string
}

class SummaryComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
        if (this.props.match.params.id) {
            this.props.load(testName, this.props.match.params.id)
            this.props.loadSummary(this.props.match.params.id)
        }
    }

    public render = () => {
        const testConfig = selectConfig(this.props.test.config)

        if (testConfig === undefined) {
            return <></>
        }

        return (<>
            <CrudShowHeader actionName={testName}/>

            {this.props.isLoading
                ? <CircularProgressBar/>
                : <>
                    <Tabs
                        studentId={this.props.match.params.parentId}
                        test={this.props.test}
                    />
                    <Card variant="outlined">
                        <CardContent>
                            {this.props.summary.data !== undefined && testConfig.tabs.summary !== null
                                ? React.createElement(testConfig.tabs.summary, {data: Object.entries(this.props.summary.data)})
                                : ''}
                        </CardContent>
                    </Card>
                </>}
        </>)
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    test: getRow(state => state.tests)(state),
    isLoading: isLoading(state),
    summary: getTestSummary(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    loadSummary: (id: string) => {
        dispatch(TestAction.execute(testName, 'summary', {id}))
    }
})

export const Summary = withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryComponent))