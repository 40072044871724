import {DisabilityTypeApi, EventApi} from 'client/apis'
import {configuration} from 'lib/client'

const EventApiClient = (): EventApi => {
    return new EventApi(configuration)
}

export const dao = {
    list: (payload: any) => EventApiClient().getEventCollection(payload),
    get: (payload: any) => EventApiClient().getEventItem({id: payload.id}),
    create: (payload: any) => EventApiClient().postEventCollection({eventWrite: payload}),
    update: (payload: any) => EventApiClient().putEventItem({id: payload.data.id, eventWrite: payload.data}),
    delete: (payload: any) => EventApiClient().deleteEventItem({id: payload.id})
}