import {Action} from 'redux'
import {VariantName} from './variants/variants'

export type ThemeState = {
    name: VariantName
}

export const initialState: ThemeState = {
    name: VariantName.Dark
}

export function themeReducer(state: ThemeState = initialState, action: Action): ThemeState {
    return state
}