import * as React from 'react'
import styled from 'styled-components'
import {Typography} from "@material-ui/core";

const FooterStyled = styled.div`
`

const footerText = () => {
    return "Słoneczna Kraina " + (new Date().getFullYear()).toString()
}

export const Footer: React.FunctionComponent = () => (
    <FooterStyled>
        <Typography style={{textAlign: "center", paddingBottom: "20px"}}>
            {footerText()} | <a href={"/regulamin"} style={{color: "#1976d2"}}>Regulamin</a>
        </Typography>
        <Typography style={{textAlign: "center", color: "gray"}}>
            Jakiekolwiek korzystanie z danych zawartych na platformie wymaga uzyskania uprzedniej zgody w formie pisemnej. <br/> Korzystanie z nich niezgodnie z <a href={"/regulamin"} style={{color: "#1976d2"}}>Regulaminem</a> lub bez uzyskania wymaganej zgody jest zabronione <br/> i może stanowić naruszenie prawa oraz może skutkować odpowiedzialnością cywilną lub karną.
        </Typography>
    </FooterStyled>
)