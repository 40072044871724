import {AnyAction} from 'redux'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'

export const name = 'EDUCATION_STATUS'

export type EducationStatusState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function studentCategoryReducer(state: EducationStatusState = initialState, action: AnyAction): EducationStatusState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
