// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20010,
    SchoolTypeRead,
} from '../models';

export interface GetSchoolTypeCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetSchoolTypeItemRequest {
    id: string;
}

/**
 * no description
 */
export class SchoolTypeApi extends BaseAPI {

    /**
     * Retrieves the collection of SchoolType resources.
     */
    getSchoolTypeCollection({ page, itemsPerPage }: GetSchoolTypeCollectionRequest): Observable<InlineResponse20010>
    getSchoolTypeCollection({ page, itemsPerPage }: GetSchoolTypeCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20010>>
    getSchoolTypeCollection({ page, itemsPerPage }: GetSchoolTypeCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20010 | RawAjaxResponse<InlineResponse20010>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20010>({
            url: '/api/school-types',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a SchoolType resource.
     */
    getSchoolTypeItem({ id }: GetSchoolTypeItemRequest): Observable<SchoolTypeRead>
    getSchoolTypeItem({ id }: GetSchoolTypeItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SchoolTypeRead>>
    getSchoolTypeItem({ id }: GetSchoolTypeItemRequest, opts?: OperationOpts): Observable<SchoolTypeRead | RawAjaxResponse<SchoolTypeRead>> {
        throwIfNullOrUndefined(id, 'id', 'getSchoolTypeItem');

        return this.request<SchoolTypeRead>({
            url: '/api/school-types/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
