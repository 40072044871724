import {Button, Card as MuiCard, CardContent as MuiCardContent} from '@material-ui/core'
import EditIcon from '@material-ui/icons/EditOutlined'
import {spacing} from '@material-ui/system'
import {DeleteMenuItem, Menu, SimpleDialog, Status, Table} from 'app/Components'
import {AddButton} from 'app/Layout/Content'
import {Location} from 'history'
import {CrudAction, RouteType, Type} from 'lib/crud'
import {DialogAction} from 'lib/dialog'
import {Dictionary, DictionaryAction, getType, name} from 'lib/dictionaries'
import {ListOptions} from 'lib/dto'
import {generateCrudPath, getCrudPathLabels, getLocation} from 'lib/router'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom'
import styled from 'styled-components'
import {NavigationAction} from 'lib/navigation'

type StateToProps = {
    location: Location
    type: string
}

type DispatchToProps = {
    readonly setType: (type: string) => void,
    readonly openDeleteDialog: () => void,
    readonly delete: (id: string, type: string) => void,
    readonly setHeader: (actionName: string, type: RouteType) => void,
    readonly setAction: (action: JSX.Element) => void,
}

type Params = {
    type: string
}

type ListProps = StateToProps & DispatchToProps & RouteComponentProps<Params>

type ListState = {
    id: string
}

const Card = styled(MuiCard)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

class ListComponent extends React.Component<ListProps, ListState> {
    state: {
        id: ''
    }

    public componentDidMount() {
        this.props.setHeader(this.props.match.params.type, RouteType.LIST)
        this.props.setAction(<AddButton url={generateCrudPath(this.props.match.params.type, RouteType.ADD)}/>)
        this.props.setType(this.props.match.params.type)
    }

    public componentDidUpdate(prevProps: Readonly<ListProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.type !== this.props.match.params.type) {
            this.props.setType(this.props.match.params.type)
        }
    }

    public render() {
        const cols = [
            {
                id: "name",
                numeric: false,
                label: "Nazwa",
                sort: false,
                template: (row: Dictionary) => (<>{row.name}</>)
            },
            {
                id: "active",
                numeric: false,
                label: "Status",
                sort: false,
                width: '100px',
                template: (row: Dictionary) => (<Status status={row.active} activeLabel={'Aktywny'} inactiveLabel={'Nieaktywny'} />)
            },
        ]

        const handleDeleteDialogOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
            this.setState(prevState => {
                return {...prevState, id}
            })
            this.props.openDeleteDialog()
        }

        const handleDelete = () => {
            this.props.delete(this.state.id, this.props.match.params.type)
        }

        const actions = (row: Dictionary) => <Menu
            id={row.id}
            button={<Button size="small"
                            component={NavLink}
                            color={'primary'}
                            to={`/slowniki/${this.props.type}/edytuj/` + row.id}
                            startIcon={<EditIcon/>}>Edytuj</Button>}
            actions={[
                <DeleteMenuItem onClickHandler={(event) => handleDeleteDialogOpen(event, row.id)}/>
            ]}
            showSubmenu={true}
        />

        const getReloadAction = (type: string) => (options: ListOptions) => {
            return CrudAction.execute(name, Type.LIST, {
                type: type,
                data: {
                    type: [type],
                    orderPosition: options.sortDirection,
                    page: options.page + 1,
                    itemsPerPage: options.pageSize
                }
            })
        }

        return (
            <>
                <Card mt={3} variant="outlined">
                    <CardContent>
                        <Table
                            selector={(state: State) => state.dictionaries}
                            getReloadAction={getReloadAction(this.props.match.params.type)}
                            cols={cols}
                            actions={actions}
                        />
                    </CardContent>
                </Card>

                <SimpleDialog
                    id={'dictionary-delete'}
                    title={'Potwierdź usunięcie'}
                    text={'Usunąć wpis w słowniku?'}
                    confirmLabel={'Usuń'}
                    handleConfirm={handleDelete}
                />
            </>
        )
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    location: getLocation(state),
    type: getType(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    setHeader: (actionName, type) => {
        dispatch(NavigationAction.setHeader(getCrudPathLabels(actionName, type)))
    },
    setAction: (action: JSX.Element) => {
        dispatch(NavigationAction.setAction(action))
    },
    setType: (type: string) => {
        dispatch(DictionaryAction.setType(type))
    },
    openDeleteDialog: () => {
        dispatch(DialogAction.open('dictionary-delete'))
    },
    delete: (id: string, type: string) => {
        dispatch(CrudAction.execute(name, Type.DELETE, {
            type: type,
            data: {id}
        }))
    }
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(ListComponent))
