import {Table as MuiTable, TableBody, TableCell, TablePagination, TableRow} from '@material-ui/core'
import {LabelDisplayedRowsArgs} from '@material-ui/core/TablePagination/TablePagination'

import {Location} from 'history'
import {isGranted, me} from 'lib/auth'
import {countRows, getFilters, getListOptions, listRows} from 'lib/crud'
import {ListOptions} from 'lib/dto'
import {isLoading} from 'lib/progress'
import {getLocation} from 'lib/router'
import {State} from 'lib/store'
import {User} from 'lib/users'
import React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {AnyAction} from 'redux'
import {TableHead} from './TableHead'

type Params = {}

type StateToProps = {
    rows: { [key: string]: any }[]
    count: number
    listOptions: ListOptions
    filters: { [paramName: string]: string | number | boolean | undefined }
    isLoading: boolean
    location: Location
    user: User
}

type DispatchToProps = {
    readonly reload: (options: ListOptions, filters: { [paramName: string]: string | number | boolean | undefined }) => void,
}

type TableProps = {
    selector: (state: State) => any,
    getReloadAction: (listOptions: ListOptions, filters: { [paramName: string]: string | number | boolean | undefined }) => AnyAction
    cols: { [key: string]: any }[],
    actions?: any
} & RouteComponentProps<Params>

class TableComponent extends React.Component<TableProps & StateToProps & DispatchToProps> {
    public componentDidMount() {
        const query = new URLSearchParams(this.props.location.search)
        const page = parseInt(query.get("page") || '1')
        this.props.listOptions.page = page - 1
        this.props.reload(this.props.listOptions, this.props.filters)
    }

    public componentDidUpdate(prevProps: Readonly<TableProps & StateToProps & DispatchToProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.location !== prevProps.location) {
            this.props.reload(this.props.listOptions, this.props.filters)
        }
    }

    private handleChangePage = (event: any, page: number) => {
        this.props.listOptions.page = page
        this.props.reload(this.props.listOptions, this.props.filters)
        this.props.history.push('?page=' + (page + 1))
    }

    private handleChangePerPage = (event: any) => {
        this.props.listOptions.pageSize = event.target.value
        this.props.listOptions.page = 0
        this.props.reload(this.props.listOptions, this.props.filters)
        this.props.history.push('?page=1')
    }

    public render() {
        const {cols, rows, actions} = this.props

        return (
            <>
                <MuiTable>
                    <TableHead
                        cols={cols}
                        selector={this.props.selector}
                        getReloadAction={this.props.getReloadAction}
                        actions={actions}
                    />
                    <TableBody className={'gray-striped'}>
                        {rows.map(
                            (row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {cols.map(
                                        (col, colIndex) => (
                                            col.rule == undefined || isGranted(col.rule, this.props.user)
                                                ? <TableCell
                                                    align={col.numeric ? "right" : "left"}
                                                    padding={col.disablePadding ? "none" : "default"}
                                                    size={"small"}
                                                    key={colIndex}
                                                >
                                                    {col.template(row)}
                                                </TableCell> : null
                                        ),
                                        this
                                    )}
                                    {actions ? <TableCell align="right">{actions(row)}</TableCell> : null}
                                </TableRow>
                            ),
                            this
                        )}
                        {rows.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={this.visibleColsCount(cols) + (actions ? 2 : 1)} align={"center"}>
                                    {this.props.isLoading
                                        ? "Ładownanie danych..."
                                        : "Brak danych"
                                    }
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </MuiTable>
                {this.props.count > 10 && (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.props.count}
                        rowsPerPage={this.props.listOptions.pageSize}
                        page={this.props.listOptions.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangePerPage}
                        labelRowsPerPage={<>Wierszy na stronie</>}
                        labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (<>{paginationInfo.from}-{paginationInfo.to} z {paginationInfo.count}</>)}
                    />
                )}
            </>
        )
    }

    private visibleColsCount = (cols: { [key: string]: any }[]): number => {
        let count = 0;
        cols.forEach((col) => {
            if (col.rule == undefined || isGranted(col.rule, this.props.user)) {
                count ++
            }
        })

        return count;
    }
}

const mapStateToProps: MapStateToProps<StateToProps, TableProps, State> = (state, props) => ({
    rows: listRows(props.selector)(state),
    count: countRows(props.selector)(state),
    listOptions: getListOptions(props.selector)(state),
    filters: getFilters(props.selector)(state),
    isLoading: isLoading(state),
    location: getLocation(state),
    user: me(state)!,
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, TableProps> = (dispatch, props) => ({
    reload: (options: ListOptions, filters: { [paramName: string]: string | number | boolean | undefined }) => {
        dispatch(props.getReloadAction(options, filters))
    },
})

export const Table = withRouter(connect(mapStateToProps, mapDispatchToProps)(TableComponent))