import {Metadata} from 'lib/dto'

export type School = {
    id: string
    identifier?: string
    name: string,
    street: string,
    houseNumber: string,
    flatNumber: string,
    city: string,
    zipCode: string,
    phone: string,
    licensed: boolean,
    active: boolean,
    principals: string[],
    type: string,
    staff: string[]
    metadata?: Metadata
}

export const newSchool = (): School => ({
    id: '',
    name: '',
    street: '',
    houseNumber: '',
    flatNumber: '',
    city: '',
    zipCode: '',
    phone: '',
    licensed: true,
    active: false,
    principals: [] as string[],
    type: '',
    staff: [] as string[]
})