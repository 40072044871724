import {CrudList, EventPreview, FilterType, FormattedDate} from 'app/Components'
import {Tabs} from 'app/Views/Students/Tabs/Tabs'
import {rules} from 'lib/auth'
import {CrudAction, getRow, Type} from 'lib/crud'
import {HistoryEntry, name} from 'lib/historyEntry'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'

type StateToProps = {
    readonly student: Student
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
}

type Params = {
    parentId: string
}

const cols = [
    {
        id: "occurredAt",
        numeric: false,
        label: "Data",
        sort: false,
        width: '20%',
        template: (historyEntry: HistoryEntry) => <FormattedDate date={historyEntry.occurredAt} />
    },
    {
        id: "event",
        numeric: false,
        label: "Zdarzenie",
        sort: false,
        width: '30%',
        template: (historyEntry: HistoryEntry) => <EventPreview id={historyEntry.event} />
    },
    {
        id: "description",
        numeric: false,
        label: "Opis",
        sort: false,
        template: (historyEntry: HistoryEntry) => <>{historyEntry.description}</>
    },
]

class HistoryComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
    }

    public render = () => <>
        {this.props.student.id !== undefined ? <CrudList
            actionName={name}
            cols={cols}
            deleteDialogText={"Usunąć zdarzenie?"}
            selector={(state: State) => state.historyEntry}
            rules={rules.historyEntries}
            filter={{
                initialValues: {
                    event: [],
                    student: this.props.match.params.parentId,
                    occurredAtBefore: '',
                    occurredAtAfter: '',
                },
                fields: [
                    {name: 'occurredAtAfter', label: 'Od (dd-mm-rrrr)', type: FilterType.DATE},
                    {name: 'occurredAtBefore', label: 'Do (dd-mm-rrrr)', type: FilterType.DATE},
                    {name: 'event', label: 'Zdarzenia', type: FilterType.MULTIPLE_SELECT, source: Source.EVENTS},
                ]
            }}
            tabs={<Tabs id={this.props.student.id || ' '} />}
        /> : null}
    </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryComponent))