// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20014,
    StudentFileTypeRead,
} from '../models';

export interface GetStudentFileTypeCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetStudentFileTypeItemRequest {
    id: string;
}

/**
 * no description
 */
export class StudentFileTypeApi extends BaseAPI {

    /**
     * Retrieves the collection of StudentFileType resources.
     */
    getStudentFileTypeCollection({ page, itemsPerPage }: GetStudentFileTypeCollectionRequest): Observable<InlineResponse20014>
    getStudentFileTypeCollection({ page, itemsPerPage }: GetStudentFileTypeCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20014>>
    getStudentFileTypeCollection({ page, itemsPerPage }: GetStudentFileTypeCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20014 | RawAjaxResponse<InlineResponse20014>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20014>({
            url: '/api/student-file-types',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a StudentFileType resource.
     */
    getStudentFileTypeItem({ id }: GetStudentFileTypeItemRequest): Observable<StudentFileTypeRead>
    getStudentFileTypeItem({ id }: GetStudentFileTypeItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StudentFileTypeRead>>
    getStudentFileTypeItem({ id }: GetStudentFileTypeItemRequest, opts?: OperationOpts): Observable<StudentFileTypeRead | RawAjaxResponse<StudentFileTypeRead>> {
        throwIfNullOrUndefined(id, 'id', 'getStudentFileTypeItem');

        return this.request<StudentFileTypeRead>({
            url: '/api/student-file-types/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
