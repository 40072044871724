import {CrudAction, getRow, Type} from 'lib/crud'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Form} from './Form'
import {CrudEditHeader} from 'app/Components'
import {Student, name} from "lib/students";

type StateToProps = {
    readonly student: Student
}

type DispatchToProps = {
    readonly handleSubmit: (student: Student) => void,
}

type EditProps = StateToProps & DispatchToProps

class EditComponent extends React.Component<EditProps> {
    public render = () => (<>
        <CrudEditHeader actionName={name}/>
        <Form initialValues={this.props.student} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (student: Student) => {
        const studentId = student.id
        delete student.id
        dispatch(CrudAction.execute(name, Type.UPDATE, {
            id: studentId,
            data: student
        }))
    }
})

export const Edit = connect(mapStateToProps, mapDispatchToProps)(EditComponent)