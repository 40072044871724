import {AnyAction} from 'redux'
import {DialogActionType} from 'lib/dialog'

export type DialogState = {
    id: string
}

const initialState = {
    id: ''
}

export function dialogReducer(state: DialogState = initialState, action: AnyAction): DialogState {
    switch (action.type) {
        case DialogActionType.open():
            return {
                ...state,
                id: action.payload
            }
        case DialogActionType.close():
            return {
                ...state,
                id: ''
            }
        default:
            return state
    }
}
