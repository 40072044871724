import React from 'react'
import styled from 'styled-components'
import {CircularProgress} from '@material-ui/core'

const ContainerStyled = styled.div`
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  min-height: 100%;
`

type CircularProgressBarProps = {}

export class CircularProgressBar extends React.Component<CircularProgressBarProps> {
    public render() {
        return (
            <ContainerStyled><CircularProgress/></ContainerStyled>
        )
    }
}