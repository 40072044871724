import {CrudActionType, crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'
import {TestActionType} from './actions'
import {comparisonTransformer} from "./transformer";

export const testName = 'TEST'
export const testResultName = 'TEST_RESULT'
export const testSummaryName = 'TEST_SUMMARY'
export const testAnalysisName = 'TEST_ANALYSIS'
export const testDataName = 'TEST_DATA'

export type TestState = {
    crud: CrudState,
    evaluation: any
    summary: any,
    isCorrect: boolean,
    firstInvalidSection: number,
    comparison: any,
    comparisonFilter: any,
}

const initialState = {
    crud: initialCrudState,
    evaluation: {},
    summary: {},
    isCorrect: true,
    firstInvalidSection: 0,
    comparison: {},
    comparisonFilter: {}
}

export function testReducer(state: TestState = initialState, action: AnyAction): TestState {
    switch (action.type) {
        case TestActionType.execute(testName, 'evaluate'):
            return {
                ...state,
                evaluation: {},
            }
        case TestActionType.executeResponseSuccess(testName, 'evaluate'):
            return {
                ...state,
                evaluation: action.payload,
            }
        case TestActionType.execute(testName, 'summary'):
            return {
                ...state,
                summary: {},
            }
        case TestActionType.executeResponseSuccess(testName, 'summary'):
            return {
                ...state,
                summary: action.payload,
            }
        case TestActionType.determineIfCorrect():
            return {
                ...state,
                isCorrect: action.payload,
            }
        case TestActionType.setInvalidSection():
            return {
                ...state,
                firstInvalidSection: action.payload,
            }
        case TestActionType.compareSuccess:
            return {
                ...state,
                comparison: comparisonTransformer(action.payload),
            }
        case CrudActionType.filter('Analysis'):
            return {
                ...state,
                comparisonFilter: action.payload,
            }
        case TestActionType.analyseReset:
            return {
                ...state,
                comparison: {},
                comparisonFilter: {}
            }
        default:
            return {
                ...state,
                crud: crudReducer(testName, state.crud, action)
            }
    }
}
