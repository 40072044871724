import {AnyAction} from 'redux'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'

export const name = 'SUBJECT_CATEGORY'

export type SubjectCategoryState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function subjectCategoryReducer(state: SubjectCategoryState = initialState, action: AnyAction): SubjectCategoryState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
