import {RouteType} from 'lib/crud'
import {name as groupClassName} from 'lib/groupClass'
import {name as groupsName} from 'lib/groups'
import {name as historyEntryName} from 'lib/historyEntry'
import {name as parentsName} from 'lib/parents'
import {name as schoolsName} from 'lib/schools'
import {name as studentsName} from 'lib/students'
import {name as subjectsName} from 'lib/subjects'
import {testAnalysisName, testDataName, testName, testResultName, testSummaryName} from 'lib/test'
import {testConfigName} from 'lib/testConfig'
import {name as usersName} from 'lib/users'
import {name as groupTeacherName} from 'lib/groupTeacher'
import {name as studentTeacherName} from 'lib/studentTeacher'
import {name as studentSubjectName} from 'lib/studentSubject'
import {name as groupChildrenName} from 'lib/groupChildren'
import {name as studentIndividualClassName} from 'lib/individualClass'
import {name as studentFilesName} from 'lib/studentFiles'
import {generatePath} from 'react-router'
import {studentSummaryName} from "../studentSummary";

export enum Routes {
    Auth = '/konto',
    AuthSignIn = '/konto/logowanie',
    AuthResetPassword = '/konto/reset-hasla',
    AuthResetPasswordToken = '/konto/reset-hasla/:token',
    TermsOfService = '/regulamin',
}

export enum ProtectedRoutes {
    Start = '/start',

    DictionaryList = '/slowniki/:type',
    DictionaryAdd = '/slowniki/:type/dodaj',
    DictionaryEdit = '/slowniki/:type/edytuj/:id',

    DictionarySpecialization = '/slowniki/specialization',
    DictionaryDisability = '/slowniki/disability',
    DictionaryEvent = '/slowniki/event',
    DictionaryQualification = '/slowniki/qualifications',

    TestPreview = '/dzieci/:parentId/testy/podglad/:id',

    TestCompareSchools = '/testy/analizy/placowki',
    TestCompareGroups = '/testy/analizy/grupy',
    TestCompareChildren = '/testy/analizy/dzieci',

    Root = '/',
}

export function getCrudPathLabels(name: string, type: RouteType) {
    switch (name) {
        case subjectsName: return getSubjectsHeader(type);
        case usersName: return getUsersHeader(type);
        case schoolsName: return getSchoolsHeader(type);
        case groupsName: return getGroupsHeader(type);
        case groupClassName:
        case groupTeacherName:
        case groupChildrenName:
            return 'Grupa';
        case studentsName: return getStudentsHeader(type);
        case historyEntryName:
        case parentsName:
        case studentTeacherName:
        case studentSubjectName:
        case studentFilesName:
        case studentIndividualClassName:
        case testName:
        case studentSummaryName:
            return 'Dziecko';
        case testConfigName: return getTestConfigsHeader(type);
        case 'specialization': return getSpecializationsHeader(type);
        case 'disability': return getDisabilitiesHeader(type);
        case 'qualifications': return getQualificationsHeader(type);
        case 'event': return getEventsHeader(type);
        default:
            return 'Witaj w Słonecznej Krainie'
    }
}

function getSubjectsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj zajęcia';
        case RouteType.EDIT: return 'Edycja zajęć';
        case RouteType.SHOW: return 'Zajęcia';
    }

    return 'Katalog zajęć';
}

function getSchoolsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj placówkę';
        case RouteType.EDIT: return 'Edycja placówki';
        case RouteType.SHOW: return 'Placówka';
    }

    return 'Placówki';
}

function getGroupsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj grupę';
        case RouteType.EDIT: return 'Edycja grupy';
        case RouteType.SHOW: return 'Grupa';
    }

    return 'Grupy';
}

function getStudentsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj dziecko';
        case RouteType.EDIT: return 'Edycja dziecka';
        case RouteType.SHOW: return 'Dziecko';
    }

    return 'Dzieci';
}

function getUsersHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj użytkownika';
        case RouteType.EDIT: return 'Edycja użytkownika';
        case RouteType.SHOW: return 'Użytkownik';
    }

    return 'Użytkownicy';
}

function getTestConfigsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj test';
        case RouteType.EDIT: return 'Edycja testu';
        case RouteType.SHOW: return 'Test';
    }

    return 'Katalog testów';
}

function getTestsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj test';
        case RouteType.EDIT: return 'Edycja testu';
        case RouteType.SHOW: return 'Test';
    }

    return 'Testy';
}

function getSpecializationsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj specjalizację';
        case RouteType.EDIT: return 'Edycja spacjalizacji';
        case RouteType.SHOW: return 'Specjalizacja';
    }

    return 'Specjalizacje';
}

function getDisabilitiesHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj typy niepełnosprawności';
        case RouteType.EDIT: return 'Edycja typu niepełnosprawności';
        case RouteType.SHOW: return 'Typ niepełnosprawności';
    }

    return 'Typy niepełnosprawności';
}

function getQualificationsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj uprawnienie';
        case RouteType.EDIT: return 'Edycja uprawnienia';
        case RouteType.SHOW: return 'Uprawnienie';
    }

    return 'Uprawnienia';
}

function getEventsHeader(type: RouteType) {
    switch(type) {
        case RouteType.ADD: return 'Dodaj zdarzenia';
        case RouteType.EDIT: return 'Edycja zdarzenia';
        case RouteType.SHOW: return 'Zdarzenie';
    }

    return 'Zdarzenia';
}

function getCrudPath(name: string) {
    switch (name) {
        case subjectsName:
            return '/katalog-zajec'
        case usersName:
            return '/uzytkownicy'
        case schoolsName:
            return '/placowki'
        case groupsName:
            return '/grupy'
        case groupClassName:
            return '/grupy/:parentId/zajecia'
        case groupTeacherName:
            return '/grupy/:parentId/wychowawcy'
        case groupChildrenName:
            return '/grupy/:parentId/dzieci'
        case studentsName:
            return '/dzieci'
        case historyEntryName:
            return '/dzieci/:parentId/historia'
        case studentSummaryName:
            return '/dzieci/:parentId/podsumowanie'
        case parentsName:
            return '/dzieci/:parentId/rodzice'
        case studentTeacherName:
            return '/dzieci/:parentId/kadra'
        case studentFilesName:
            return '/dzieci/:parentId/dokumenty'
        case studentSubjectName:
        case studentIndividualClassName:
            return '/dzieci/:parentId/zajecia'
        case testName:
            return '/dzieci/:parentId/testy'
        case testSummaryName:
            return '/dzieci/:parentId/podsumowanie-testu'
        case testAnalysisName:
            return '/dzieci/:parentId/analiza-testu'
        case testResultName:
            return '/dzieci/:parentId/wyniki-testu'
        case testConfigName:
            return '/katalog-testow'
        case 'specialization':
            return '/slowniki/specialization'
        case 'disability':
            return '/slowniki/disability'
        case 'qualifications':
            return '/slowniki/qualifications'
        case 'event':
            return '/slowniki/event'
        default:
            return '/'
    }
}

function getCrudType(type: string) {
    switch (type) {
        case RouteType.SHOW:
            return '/:id/:tab?'
        case RouteType.ADD:
            return '/dodaj'
        case RouteType.EDIT:
            return '/edytuj/:id'
        case RouteType.LIST:
        default:
            return ''
    }
}

export function generateRouterCrudPath(actionName: string, actionType: string): string {
    return getCrudPath(actionName) + getCrudType(actionType)
}

export function generateCrudPath(
    actionName: string,
    actionType: string,
    params?: { [paramName: string]: string | number | boolean | undefined },
): string {
    return generatePath(
        generateRouterCrudPath(actionName, actionType),
        params
    )
}