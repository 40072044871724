import {CrudAddHeader} from 'app/Components'
import {CrudAction, Type} from 'lib/crud'
import {GroupClass, name, newGroupClass} from 'lib/groupClass'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import {Tabs} from '../../Tabs'
import {Form} from './Form'

type DispatchToProps = {
    readonly handleSubmit: (groupClass: GroupClass) => void,
}

type Params = {
    parentId: string
}

class AddComponent extends React.Component<DispatchToProps & RouteComponentProps<Params>> {
    public render = () => (<>
        <CrudAddHeader actionName={name}/>
        <Tabs id={this.props.match.params.parentId} />
        <Form initialValues={newGroupClass(this.props.match.params.parentId)} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (groupclass: GroupClass) => {
        delete groupclass.id
        dispatch(CrudAction.execute(name, Type.CREATE, groupclass))
    },
})

export const Add = withRouter(connect(null, mapDispatchToProps)(AddComponent))