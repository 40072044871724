import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export enum Type {
    LIST = 'list',
    RESET = 'reset',
    GET = 'get',
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

export enum RouteType {
    ADD = 'add',
    SHOW = 'show',
    EDIT = 'edit',
    LIST = 'list',
}

export const emptyFilterId = '339d8b93-ac8a-430c-ae80-e1d113898b6d'

export const CrudActionType = {
    filter: (name: string): string => `[CRUD] ${name} filter`,

    execute: (name: string, type: Type): string => `[CRUD] ${name} ${type} start`,
    executeResponseFailure: (name: string, type: Type): string => `[CRUD] ${name} ${type} failure`,
    executeResponseSuccess: (name: string, type: Type): string => `[CRUD] ${name} ${type} success`,
}

export const CrudAction = {
    filter: (name: string, filters: any) => createAction(CrudActionType.filter(name), filters),

    execute: <R>(name: string, type: Type, request: R) => createAction(CrudActionType.execute(name, type), request),
    executeResponseFailure: (name: string, type: Type, error: any) => createAction(CrudActionType.executeResponseFailure(name, type), error),
    executeResponseSuccess: (name: string, type: Type, response: any) => createAction(CrudActionType.executeResponseSuccess(name, type), response),
}

export type CrudAction = ActionsUnion<typeof CrudAction>