import {ListItem, ListItemText} from '@material-ui/core'
import {ProtectedRoutes, Routes} from 'lib/router'
import {darken, rgba} from 'polished'
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'

type LinkStyledProps = {
    activeClassName: string
    component: unknown
} & { [key: string]: any }

const LinkStyled = styled(ListItem)<LinkStyledProps>`
  padding-left: ${props => props.theme.spacing(5)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
`

const TextStyled = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};

  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }

  margin-top: 0;
  margin-bottom: 0;
`

type LinkComponentProps = {
    name: string
    to: Routes | ProtectedRoutes | string
    active: boolean
}

export const SectionLink: React.FunctionComponent<LinkComponentProps> = ({name, to, active}) =>
    <LinkStyled
        button
        dense
        component={NavLink}
        to={to}
        activeClassName={active ? 'active' : ''}
    >
        <TextStyled>{name}</TextStyled>
    </LinkStyled>
