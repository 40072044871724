import {CrudAddHeader} from 'app/Components'
import {CrudAction, getRow, Type} from 'lib/crud'
import {UploadFile, name, newUploadFile} from 'lib/studentFiles'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import {Tabs} from "../Tabs";
import {Form} from './Form'

type StateToProps = {
    readonly student: Student
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
    readonly handleSubmit: (uploadFile: UploadFile) => void,
}

type Params = {
    parentId: string
}

class AddComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
    }

    public render = () => (<>
        <CrudAddHeader actionName={name}/>
        <Tabs id={this.props.match.params.parentId} />
        {this.props.student.id != undefined
            ? <Form initialValues={newUploadFile(this.props.student.id)} handleSubmit={this.props.handleSubmit}/>
            : null}
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    handleSubmit: (uploadFile: UploadFile) => {
        dispatch(CrudAction.execute(name, Type.CREATE, uploadFile))
    }
})

export const Add = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddComponent))