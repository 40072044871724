// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20023,
    InlineResponse20024,
    TestCollectionpostRead,
    TestCollectionpostWrite,
    TestItemgetRead,
    TestItemputRead,
    TestItemputWrite,
} from '../models';

export interface CompareTestCollectionRequest {
    config?: string;
    from?: string;
    to?: string;
    student?: string;
    group?: string;
    school?: string;
    page?: number;
    itemsPerPage?: number;
}

export interface ConfirmTestItemRequest {
    id: string;
}

export interface DeleteTestItemRequest {
    id: string;
}

export interface EvaluateTestItemRequest {
    id: string;
}

export interface ExportContentTestItemRequest {
    id: string;
}

export interface ExportEvaluationTestItemRequest {
    id: string;
}

export interface GetTestCollectionRequest {
    id?: Array<string>;
    student?: Array<string>;
    studentSchool?: Array<string>;
    teacher?: Array<string>;
    config?: Array<string>;
    configType?: Array<string>;
    studentSearch?: string;
    published?: boolean;
    createdAtBefore?: string;
    createdAtStrictlyBefore?: string;
    createdAtAfter?: string;
    createdAtStrictlyAfter?: string;
    publishedAtBefore?: string;
    publishedAtStrictlyBefore?: string;
    publishedAtAfter?: string;
    publishedAtStrictlyAfter?: string;
    orderCreatedAt?: GetTestCollectionOrderCreatedAtEnum;
    orderPublishedAt?: GetTestCollectionOrderPublishedAtEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetTestItemRequest {
    id: string;
}

export interface PostTestCollectionRequest {
    testCollectionpostWrite?: TestCollectionpostWrite;
}

export interface PutTestItemRequest {
    id: string;
    testItemputWrite?: TestItemputWrite;
}

export interface SummaryTestItemRequest {
    id: string;
}

/**
 * no description
 */
export class TestApi extends BaseAPI {

    /**
     * Retrieves the collection of Test resources.
     */
    compareTestCollection({ config, from, to, student, group, school, page, itemsPerPage }: CompareTestCollectionRequest): Observable<InlineResponse20024>
    compareTestCollection({ config, from, to, student, group, school, page, itemsPerPage }: CompareTestCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20024>>
    compareTestCollection({ config, from, to, student, group, school, page, itemsPerPage }: CompareTestCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20024 | RawAjaxResponse<InlineResponse20024>> {

        const query: HttpQuery = {};

        if (config != null) { query['config'] = config; }
        if (from != null) { query['from'] = (from as any).toISOString(); }
        if (to != null) { query['to'] = (to as any).toISOString(); }
        if (student != null) { query['student[]'] = student; }
        if (group != null) { query['group[]'] = group; }
        if (school != null) { query['school[]'] = school; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20024>({
            url: '/api/tests/compare',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the Test resource.
     */
    confirmTestItem({ id }: ConfirmTestItemRequest): Observable<void>
    confirmTestItem({ id }: ConfirmTestItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    confirmTestItem({ id }: ConfirmTestItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'confirmTestItem');

        return this.request<void>({
            url: '/api/tests/{id}/confirm'.replace('{id}', encodeURI(id)),
            method: 'PUT',
        }, opts?.responseOpts);
    };

    /**
     * Removes the Test resource.
     */
    deleteTestItem({ id }: DeleteTestItemRequest): Observable<void>
    deleteTestItem({ id }: DeleteTestItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteTestItem({ id }: DeleteTestItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteTestItem');

        return this.request<void>({
            url: '/api/tests/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Test resource.
     */
    evaluateTestItem({ id }: EvaluateTestItemRequest): Observable<object>
    evaluateTestItem({ id }: EvaluateTestItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<object>>
    evaluateTestItem({ id }: EvaluateTestItemRequest, opts?: OperationOpts): Observable<object | RawAjaxResponse<object>> {
        throwIfNullOrUndefined(id, 'id', 'evaluateTestItem');

        return this.request<object>({
            url: '/api/tests/{id}/evaluate'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Test resource.
     */
    exportContentTestItem({ id }: ExportContentTestItemRequest): Observable<string>
    exportContentTestItem({ id }: ExportContentTestItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<string>>
    exportContentTestItem({ id }: ExportContentTestItemRequest, opts?: OperationOpts): Observable<string | RawAjaxResponse<string>> {
        throwIfNullOrUndefined(id, 'id', 'exportContentTestItem');

        return this.request<string>({
            url: '/api/tests/{id}/export/content'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Test resource.
     */
    exportEvaluationTestItem({ id }: ExportEvaluationTestItemRequest): Observable<string>
    exportEvaluationTestItem({ id }: ExportEvaluationTestItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<string>>
    exportEvaluationTestItem({ id }: ExportEvaluationTestItemRequest, opts?: OperationOpts): Observable<string | RawAjaxResponse<string>> {
        throwIfNullOrUndefined(id, 'id', 'exportEvaluationTestItem');

        return this.request<string>({
            url: '/api/tests/{id}/export/evaluation'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of Test resources.
     */
    getTestCollection({ id, student, studentSchool, teacher, config, configType, studentSearch, published, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, publishedAtBefore, publishedAtStrictlyBefore, publishedAtAfter, publishedAtStrictlyAfter, orderCreatedAt, orderPublishedAt, page, itemsPerPage }: GetTestCollectionRequest): Observable<InlineResponse20023>
    getTestCollection({ id, student, studentSchool, teacher, config, configType, studentSearch, published, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, publishedAtBefore, publishedAtStrictlyBefore, publishedAtAfter, publishedAtStrictlyAfter, orderCreatedAt, orderPublishedAt, page, itemsPerPage }: GetTestCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20023>>
    getTestCollection({ id, student, studentSchool, teacher, config, configType, studentSearch, published, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, publishedAtBefore, publishedAtStrictlyBefore, publishedAtAfter, publishedAtStrictlyAfter, orderCreatedAt, orderPublishedAt, page, itemsPerPage }: GetTestCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20023 | RawAjaxResponse<InlineResponse20023>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (student != null) { query['student[]'] = student; }
        if (studentSchool != null) { query['student.school[]'] = studentSchool; }
        if (teacher != null) { query['teacher[]'] = teacher; }
        if (config != null) { query['config[]'] = config; }
        if (configType != null) { query['config.type[]'] = configType; }
        if (studentSearch != null) { query['studentSearch'] = studentSearch; }
        if (published != null) { query['published'] = published; }
        if (createdAtBefore != null) { query['createdAt[before]'] = createdAtBefore; }
        if (createdAtStrictlyBefore != null) { query['createdAt[strictly_before]'] = createdAtStrictlyBefore; }
        if (createdAtAfter != null) { query['createdAt[after]'] = createdAtAfter; }
        if (createdAtStrictlyAfter != null) { query['createdAt[strictly_after]'] = createdAtStrictlyAfter; }
        if (publishedAtBefore != null) { query['publishedAt[before]'] = publishedAtBefore; }
        if (publishedAtStrictlyBefore != null) { query['publishedAt[strictly_before]'] = publishedAtStrictlyBefore; }
        if (publishedAtAfter != null) { query['publishedAt[after]'] = publishedAtAfter; }
        if (publishedAtStrictlyAfter != null) { query['publishedAt[strictly_after]'] = publishedAtStrictlyAfter; }
        if (orderCreatedAt != null) { query['order[createdAt]'] = orderCreatedAt; }
        if (orderPublishedAt != null) { query['order[publishedAt]'] = orderPublishedAt; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20023>({
            url: '/api/tests',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Test resource.
     */
    getTestItem({ id }: GetTestItemRequest): Observable<TestItemgetRead>
    getTestItem({ id }: GetTestItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestItemgetRead>>
    getTestItem({ id }: GetTestItemRequest, opts?: OperationOpts): Observable<TestItemgetRead | RawAjaxResponse<TestItemgetRead>> {
        throwIfNullOrUndefined(id, 'id', 'getTestItem');

        return this.request<TestItemgetRead>({
            url: '/api/tests/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a Test resource.
     */
    postTestCollection({ testCollectionpostWrite }: PostTestCollectionRequest): Observable<TestCollectionpostRead>
    postTestCollection({ testCollectionpostWrite }: PostTestCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestCollectionpostRead>>
    postTestCollection({ testCollectionpostWrite }: PostTestCollectionRequest, opts?: OperationOpts): Observable<TestCollectionpostRead | RawAjaxResponse<TestCollectionpostRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TestCollectionpostRead>({
            url: '/api/tests',
            method: 'POST',
            headers,
            body: testCollectionpostWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the Test resource.
     */
    putTestItem({ id, testItemputWrite }: PutTestItemRequest): Observable<TestItemputRead>
    putTestItem({ id, testItemputWrite }: PutTestItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestItemputRead>>
    putTestItem({ id, testItemputWrite }: PutTestItemRequest, opts?: OperationOpts): Observable<TestItemputRead | RawAjaxResponse<TestItemputRead>> {
        throwIfNullOrUndefined(id, 'id', 'putTestItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TestItemputRead>({
            url: '/api/tests/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: testItemputWrite,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Test resource.
     */
    summaryTestItem({ id }: SummaryTestItemRequest): Observable<object>
    summaryTestItem({ id }: SummaryTestItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<object>>
    summaryTestItem({ id }: SummaryTestItemRequest, opts?: OperationOpts): Observable<object | RawAjaxResponse<object>> {
        throwIfNullOrUndefined(id, 'id', 'summaryTestItem');

        return this.request<object>({
            url: '/api/tests/{id}/summary'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetTestCollectionOrderCreatedAtEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetTestCollectionOrderPublishedAtEnum {
    Asc = 'asc',
    Desc = 'desc'
}
