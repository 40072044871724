import {Box, Card as MuiCard, CardContent as MuiCardContent, Typography} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import {CircularProgressBar, FilterType} from 'app/Components'
import {isLoading} from 'lib/progress'
import {getOptions, Source} from 'lib/select'
import {State} from 'lib/store'
import {getComparison, getComparisonFilter, TestAction} from 'lib/test'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import styled from 'styled-components'
import {schoolTable} from './ClaMEISR'
import {Filter} from './Filter'

type StateToProps = {
    readonly isLoading: boolean
    readonly comparison: any,
    readonly comparisonFilter: any,
    readonly schools: any
}

type DispatchToProps = {
    readonly resetAnalysis: () => void,
}

type Params = {
    parentId: string
    id: string
}

const Card = styled(MuiCard)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

class SchoolsComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount = () => {
        this.props.resetAnalysis()
    }

    public render = () => <>
        {this.props.isLoading
            ? <CircularProgressBar/>
            : <Card mt={3} variant="outlined">
                <CardContent>
                    <Filter
                        actionName={'Analysis'}
                        selector={(state: State) => state.schools}
                        // initialValues={{
                        //     config: '532028fb-af1b-4916-bf33-b3cff6cc3fc0',
                        //     from: '2021-01-01T00:00:00.000Z',
                        //     to: '2021-04-16T00:00:00.000Z',
                        //     school: ['5e3c126b-7cb4-4af9-b988-c4085c95593d'],
                        //     age: '3'
                        // }}
                        initialValues={{}}
                        fields={[
                            {name: 'config', label: 'Test', type: FilterType.SELECT, source: Source.TEST_FOR_ANALYSIS},
                            {name: 'from', label: 'Od (dd-mm-rrrr)', type: FilterType.DATE},
                            {name: 'to', label: 'Do (dd-mm-rrrr)', type: FilterType.DATE},
                            {name: 'school', label: 'Placówki', type: FilterType.MULTIPLE_SELECT, source: Source.SCHOOLS},
                            {name: 'age', label: 'Wiek', type: FilterType.SELECT, source: Source.AGE}
                        ]}
                    />
                    {this.props.comparison && this.props.comparison.schools && this.props.schools
                        ? schoolTable(
                            this.props.comparison.schools,
                            this.props.schools,
                            this.props.comparisonFilter.config || '',
                            this.props.comparisonFilter.age || ''
                        )
                        : <Box mt={10} mb={6}><Typography variant={'h5'} align={'center'}>Wybierz parametry do analizy</Typography></Box>
                    }
                </CardContent>
            </Card>}
    </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    comparison: getComparison(state),
    comparisonFilter: getComparisonFilter(state),
    schools: getOptions('school')(state)
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    resetAnalysis: () => {
        dispatch(TestAction.analyseReset({}))
    }
})

export const Schools = withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolsComponent))