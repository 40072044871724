import {Tab, Tabs as MuiTabs} from '@material-ui/core'
import {RouteType} from 'lib/crud'
import {generateCrudPath} from 'lib/router'
import {TabsAction} from 'lib/tabs'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'

type Tab = {
    label: string
    icon: JSX.Element
    path?: string
}

type Params = {
    id: string
    tab: string
}

type DispatchToProps = {
    readonly setTab: (index: number) => void,
}

type TabsProps = {
    tabs: Tab[]
    name: string
}

type TabsState = {
    value: number
    init: boolean
}

class TabsComponent extends React.Component<TabsProps & DispatchToProps & RouteComponentProps<Params>, TabsState> {
    state: TabsState = {
        value: 0,
        init: false
    }

    componentDidMount() {
        if (this.props.match.params.tab == undefined) {
            return
        }
        this.props.tabs.forEach((tab, index) => {
            if (tab.path == this.props.match.params.tab) {
                this.props.setTab(index)
                this.setState(prevState => {
                    return {...prevState, value: index}
                })
                return
            }
        })
    }

    componentWillUnmount() {
        this.props.setTab(0)
    }

    private showPath = generateCrudPath(this.props.name, RouteType.SHOW, {id: this.props.match.params.id})

    private handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState(prevState => {
            return {...prevState, value: newValue}
        })
        this.props.setTab(newValue)

        this.props.history.push(this.showPath + (this.props.tabs[newValue].path ? '/' + this.props.tabs[newValue].path : ''))
    }

    public render = () => {
        return <MuiTabs
            indicatorColor={"primary"}
            textColor={"secondary"}
            onChange={this.handleChange}
            value={this.state.value}
        >
            {this.props.tabs.map((tab, index) => <Tab key={index} icon={tab.icon} label={tab.label}/>, this)}
        </MuiTabs>
    }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, TabsProps> = (dispatch, props) => ({
    setTab: (index: number) => {
        dispatch(TabsAction.setTab(index))
    }
})

export const Tabs = withRouter(connect(null, mapDispatchToProps)(TabsComponent))