import {
    CrudAction,
    dispatchAfterFailureEpic,
    dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {NotificationsAction} from 'lib/notifications'
import {generateRouterCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {dao, testConfigName} from 'lib/testConfig'
import {combineEpics} from 'redux-observable'
import {NavigationAction} from "../navigation";

export const testConfigEpics = combineEpics(
    executeCrudEpic(testConfigName, Type.LIST)(dao.list),
    executeCrudEpic(testConfigName, Type.GET)(dao.get),
    executeCrudEpic(testConfigName, Type.UPDATE)(dao.update),

    redirectAfterSuccessEpic(testConfigName, Type.UPDATE, (action, state: State) => generateRouterCrudPath(testConfigName, RouteType.LIST)),

    dispatchAfterSuccessEpic(testConfigName, Type.GET, (action, state: State) => NavigationAction.setSubheader(state.testConfig.crud.row.name)),

    dispatchAfterSuccessEpic(testConfigName, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Katalog testów został zaktualizowany',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(testConfigName, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas aktualizacji katalogu testów',
        options: {variant: "error"}
    })),

    dispatchAfterFilterEpic(testConfigName, (action, state: State) => CrudAction.execute(testConfigName, Type.LIST, {
        orderName: state.testConfig.crud.listOptions.sortDirection,
        page: state.testConfig.crud.listOptions.page + 1,
        itemsPerPage: state.testConfig.crud.listOptions.pageSize,
        ...state.testConfig.crud.filters,
    }))
)