// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    GroupRead,
    GroupWrite,
    InlineResponse2005,
} from '../models';

export interface DeleteGroupItemRequest {
    id: string;
}

export interface GetGroupCollectionRequest {
    id?: Array<string>;
    name?: string;
    school?: Array<string>;
    active?: boolean;
    orderName?: GetGroupCollectionOrderNameEnum;
    orderActive?: GetGroupCollectionOrderActiveEnum;
    orderSchoolName?: GetGroupCollectionOrderSchoolNameEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetGroupItemRequest {
    id: string;
}

export interface PostGroupCollectionRequest {
    groupWrite?: GroupWrite;
}

export interface PutGroupItemRequest {
    id: string;
    groupWrite?: GroupWrite;
}

/**
 * no description
 */
export class GroupApi extends BaseAPI {

    /**
     * Removes the Group resource.
     */
    deleteGroupItem({ id }: DeleteGroupItemRequest): Observable<void>
    deleteGroupItem({ id }: DeleteGroupItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteGroupItem({ id }: DeleteGroupItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteGroupItem');

        return this.request<void>({
            url: '/api/groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of Group resources.
     */
    getGroupCollection({ id, name, school, active, orderName, orderActive, orderSchoolName, page, itemsPerPage }: GetGroupCollectionRequest): Observable<InlineResponse2005>
    getGroupCollection({ id, name, school, active, orderName, orderActive, orderSchoolName, page, itemsPerPage }: GetGroupCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2005>>
    getGroupCollection({ id, name, school, active, orderName, orderActive, orderSchoolName, page, itemsPerPage }: GetGroupCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2005 | RawAjaxResponse<InlineResponse2005>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (name != null) { query['name'] = name; }
        if (school != null) { query['school[]'] = school; }
        if (active != null) { query['active'] = active; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (orderSchoolName != null) { query['order[school.name]'] = orderSchoolName; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2005>({
            url: '/api/groups',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Group resource.
     */
    getGroupItem({ id }: GetGroupItemRequest): Observable<GroupRead>
    getGroupItem({ id }: GetGroupItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<GroupRead>>
    getGroupItem({ id }: GetGroupItemRequest, opts?: OperationOpts): Observable<GroupRead | RawAjaxResponse<GroupRead>> {
        throwIfNullOrUndefined(id, 'id', 'getGroupItem');

        return this.request<GroupRead>({
            url: '/api/groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a Group resource.
     */
    postGroupCollection({ groupWrite }: PostGroupCollectionRequest): Observable<GroupRead>
    postGroupCollection({ groupWrite }: PostGroupCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<GroupRead>>
    postGroupCollection({ groupWrite }: PostGroupCollectionRequest, opts?: OperationOpts): Observable<GroupRead | RawAjaxResponse<GroupRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<GroupRead>({
            url: '/api/groups',
            method: 'POST',
            headers,
            body: groupWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the Group resource.
     */
    putGroupItem({ id, groupWrite }: PutGroupItemRequest): Observable<GroupRead>
    putGroupItem({ id, groupWrite }: PutGroupItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<GroupRead>>
    putGroupItem({ id, groupWrite }: PutGroupItemRequest, opts?: OperationOpts): Observable<GroupRead | RawAjaxResponse<GroupRead>> {
        throwIfNullOrUndefined(id, 'id', 'putGroupItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<GroupRead>({
            url: '/api/groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: groupWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetGroupCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetGroupCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetGroupCollectionOrderSchoolNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
