// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20020,
    TestConfigRead,
    TestConfigWrite,
} from '../models';

export interface GetTestConfigCollectionRequest {
    id?: Array<string>;
    type?: Array<string>;
    specialties?: Array<string>;
    qualifications?: Array<string>;
    roles?: Array<string>;
    active?: boolean;
    page?: number;
    itemsPerPage?: number;
}

export interface GetTestConfigItemRequest {
    id: string;
}

export interface PutTestConfigItemRequest {
    id: string;
    testConfigWrite?: TestConfigWrite;
}

/**
 * no description
 */
export class TestConfigApi extends BaseAPI {

    /**
     * Retrieves the collection of TestConfig resources.
     */
    getTestConfigCollection({ id, type, specialties, qualifications, roles, active, page, itemsPerPage }: GetTestConfigCollectionRequest): Observable<InlineResponse20020>
    getTestConfigCollection({ id, type, specialties, qualifications, roles, active, page, itemsPerPage }: GetTestConfigCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20020>>
    getTestConfigCollection({ id, type, specialties, qualifications, roles, active, page, itemsPerPage }: GetTestConfigCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20020 | RawAjaxResponse<InlineResponse20020>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (type != null) { query['type[]'] = type; }
        if (specialties != null) { query['specialties[]'] = specialties; }
        if (qualifications != null) { query['qualifications[]'] = qualifications; }
        if (roles != null) { query['roles[]'] = roles; }
        if (active != null) { query['active'] = active; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20020>({
            url: '/api/test-configs',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a TestConfig resource.
     */
    getTestConfigItem({ id }: GetTestConfigItemRequest): Observable<TestConfigRead>
    getTestConfigItem({ id }: GetTestConfigItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestConfigRead>>
    getTestConfigItem({ id }: GetTestConfigItemRequest, opts?: OperationOpts): Observable<TestConfigRead | RawAjaxResponse<TestConfigRead>> {
        throwIfNullOrUndefined(id, 'id', 'getTestConfigItem');

        return this.request<TestConfigRead>({
            url: '/api/test-configs/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Replaces the TestConfig resource.
     */
    putTestConfigItem({ id, testConfigWrite }: PutTestConfigItemRequest): Observable<TestConfigRead>
    putTestConfigItem({ id, testConfigWrite }: PutTestConfigItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestConfigRead>>
    putTestConfigItem({ id, testConfigWrite }: PutTestConfigItemRequest, opts?: OperationOpts): Observable<TestConfigRead | RawAjaxResponse<TestConfigRead>> {
        throwIfNullOrUndefined(id, 'id', 'putTestConfigItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<TestConfigRead>({
            url: '/api/test-configs/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: testConfigWrite,
        }, opts?.responseOpts);
    };

}
