// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20012,
    SpecialtyRead,
    SpecialtyWrite,
} from '../models';

export interface DeleteSpecialtyItemRequest {
    id: string;
}

export interface GetSpecialtyCollectionRequest {
    id?: Array<string>;
    name?: string;
    active?: boolean;
    orderName?: GetSpecialtyCollectionOrderNameEnum;
    orderActive?: GetSpecialtyCollectionOrderActiveEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetSpecialtyItemRequest {
    id: string;
}

export interface PostSpecialtyCollectionRequest {
    specialtyWrite?: SpecialtyWrite;
}

export interface PutSpecialtyItemRequest {
    id: string;
    specialtyWrite?: SpecialtyWrite;
}

/**
 * no description
 */
export class SpecialtyApi extends BaseAPI {

    /**
     * Removes the Specialty resource.
     */
    deleteSpecialtyItem({ id }: DeleteSpecialtyItemRequest): Observable<void>
    deleteSpecialtyItem({ id }: DeleteSpecialtyItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteSpecialtyItem({ id }: DeleteSpecialtyItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteSpecialtyItem');

        return this.request<void>({
            url: '/api/specialties/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of Specialty resources.
     */
    getSpecialtyCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetSpecialtyCollectionRequest): Observable<InlineResponse20012>
    getSpecialtyCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetSpecialtyCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20012>>
    getSpecialtyCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetSpecialtyCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20012 | RawAjaxResponse<InlineResponse20012>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (name != null) { query['name'] = name; }
        if (active != null) { query['active'] = active; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20012>({
            url: '/api/specialties',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Specialty resource.
     */
    getSpecialtyItem({ id }: GetSpecialtyItemRequest): Observable<SpecialtyRead>
    getSpecialtyItem({ id }: GetSpecialtyItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SpecialtyRead>>
    getSpecialtyItem({ id }: GetSpecialtyItemRequest, opts?: OperationOpts): Observable<SpecialtyRead | RawAjaxResponse<SpecialtyRead>> {
        throwIfNullOrUndefined(id, 'id', 'getSpecialtyItem');

        return this.request<SpecialtyRead>({
            url: '/api/specialties/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a Specialty resource.
     */
    postSpecialtyCollection({ specialtyWrite }: PostSpecialtyCollectionRequest): Observable<SpecialtyRead>
    postSpecialtyCollection({ specialtyWrite }: PostSpecialtyCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SpecialtyRead>>
    postSpecialtyCollection({ specialtyWrite }: PostSpecialtyCollectionRequest, opts?: OperationOpts): Observable<SpecialtyRead | RawAjaxResponse<SpecialtyRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<SpecialtyRead>({
            url: '/api/specialties',
            method: 'POST',
            headers,
            body: specialtyWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the Specialty resource.
     */
    putSpecialtyItem({ id, specialtyWrite }: PutSpecialtyItemRequest): Observable<SpecialtyRead>
    putSpecialtyItem({ id, specialtyWrite }: PutSpecialtyItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SpecialtyRead>>
    putSpecialtyItem({ id, specialtyWrite }: PutSpecialtyItemRequest, opts?: OperationOpts): Observable<SpecialtyRead | RawAjaxResponse<SpecialtyRead>> {
        throwIfNullOrUndefined(id, 'id', 'putSpecialtyItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<SpecialtyRead>({
            url: '/api/specialties/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: specialtyWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetSpecialtyCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetSpecialtyCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
