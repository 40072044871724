import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export enum ReloadActionType {
    Reload = '[UI] Reload',
}

export const ReloadAction = {
    reload: () => createAction(ReloadActionType.Reload, null),
}

export type ReloadAction = ActionsUnion<typeof ReloadAction>
