import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import * as React from 'react'
import {NavLink} from 'react-router-dom'

type AddButtonProps = {
    label?: string
    url: string
}

export class AddButton extends React.Component<AddButtonProps> {
    public render = () => (
        <Button
            variant="contained"
            color="primary"
            component={NavLink} to={this.props.url}
            startIcon={<AddIcon/>}
        >
            {this.props.label || 'Dodaj'}
        </Button>)
}