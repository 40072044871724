// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse2008,
    QualificationRead,
    QualificationWrite,
} from '../models';

export interface DeleteQualificationItemRequest {
    id: string;
}

export interface GetQualificationCollectionRequest {
    id?: Array<string>;
    name?: string;
    active?: boolean;
    orderName?: GetQualificationCollectionOrderNameEnum;
    orderActive?: GetQualificationCollectionOrderActiveEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetQualificationItemRequest {
    id: string;
}

export interface PostQualificationCollectionRequest {
    qualificationWrite?: QualificationWrite;
}

export interface PutQualificationItemRequest {
    id: string;
    qualificationWrite?: QualificationWrite;
}

/**
 * no description
 */
export class QualificationApi extends BaseAPI {

    /**
     * Removes the Qualification resource.
     */
    deleteQualificationItem({ id }: DeleteQualificationItemRequest): Observable<void>
    deleteQualificationItem({ id }: DeleteQualificationItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteQualificationItem({ id }: DeleteQualificationItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteQualificationItem');

        return this.request<void>({
            url: '/api/qualifications/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of Qualification resources.
     */
    getQualificationCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetQualificationCollectionRequest): Observable<InlineResponse2008>
    getQualificationCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetQualificationCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2008>>
    getQualificationCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetQualificationCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2008 | RawAjaxResponse<InlineResponse2008>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (name != null) { query['name'] = name; }
        if (active != null) { query['active'] = active; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2008>({
            url: '/api/qualifications',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Qualification resource.
     */
    getQualificationItem({ id }: GetQualificationItemRequest): Observable<QualificationRead>
    getQualificationItem({ id }: GetQualificationItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<QualificationRead>>
    getQualificationItem({ id }: GetQualificationItemRequest, opts?: OperationOpts): Observable<QualificationRead | RawAjaxResponse<QualificationRead>> {
        throwIfNullOrUndefined(id, 'id', 'getQualificationItem');

        return this.request<QualificationRead>({
            url: '/api/qualifications/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a Qualification resource.
     */
    postQualificationCollection({ qualificationWrite }: PostQualificationCollectionRequest): Observable<QualificationRead>
    postQualificationCollection({ qualificationWrite }: PostQualificationCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<QualificationRead>>
    postQualificationCollection({ qualificationWrite }: PostQualificationCollectionRequest, opts?: OperationOpts): Observable<QualificationRead | RawAjaxResponse<QualificationRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<QualificationRead>({
            url: '/api/qualifications',
            method: 'POST',
            headers,
            body: qualificationWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the Qualification resource.
     */
    putQualificationItem({ id, qualificationWrite }: PutQualificationItemRequest): Observable<QualificationRead>
    putQualificationItem({ id, qualificationWrite }: PutQualificationItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<QualificationRead>>
    putQualificationItem({ id, qualificationWrite }: PutQualificationItemRequest, opts?: OperationOpts): Observable<QualificationRead | RawAjaxResponse<QualificationRead>> {
        throwIfNullOrUndefined(id, 'id', 'putQualificationItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<QualificationRead>({
            url: '/api/qualifications/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: qualificationWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetQualificationCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetQualificationCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
