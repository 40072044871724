import {authEpics} from 'lib/auth'
import {classDurationEpics} from 'lib/classDuration'
import {dictionariesEpics} from 'lib/dictionaries'
import {enumEpics} from 'lib/enums'
import {groupChildrenEpics} from 'lib/groupChildren'
import {groupClassesEpics} from 'lib/groupClass'
import {groupsEpics} from 'lib/groups'
import {groupTeachersEpics} from 'lib/groupTeacher'
import {historyEntryEpics} from 'lib/historyEntry'
import {individualClassesEpics} from 'lib/individualClass'
import {parentsEpics} from 'lib/parents'
import {previewEpics} from 'lib/preview'
import {progressEpics} from 'lib/progress'
import {rolesEpics} from 'lib/roles'
import {schoolsEpics} from 'lib/schools'
import {schoolTypeEpics} from 'lib/schoolType'
import {selectEpics} from 'lib/select'
import {specialistsEpics} from 'lib/specialists'
import {studentCategoryEpics} from 'lib/studentCategory'
import {studentFilesEpics} from 'lib/studentFiles'
import {studentFileTypesEpics} from 'lib/studentFileTypes'
import {studentsEpics} from 'lib/students'
import {studentsubjectsEpics} from 'lib/studentSubject'
import {studentSummaryEpics} from 'lib/studentSummary'
import {studentTeachersEpics} from 'lib/studentTeacher'
import {subjectsEpics} from 'lib/subjects'
import {subjectCategoryEpics} from 'lib/subjectsCategory'
import {testEpics} from 'lib/test'
import {testConfigEpics} from 'lib/testConfig'
import {usersEpics} from 'lib/users'
import {combineEpics} from 'redux-observable'

export const rootEpic = combineEpics(
    authEpics,
    dictionariesEpics,
    progressEpics,
    usersEpics,
    schoolsEpics,
    selectEpics,
    subjectsEpics,
    subjectCategoryEpics,
    rolesEpics,
    enumEpics,
    groupsEpics,
    previewEpics,
    studentsEpics,
    studentCategoryEpics,
    groupClassesEpics,
    classDurationEpics,
    historyEntryEpics,
    schoolTypeEpics,
    individualClassesEpics,
    parentsEpics,
    groupTeachersEpics,
    groupChildrenEpics,
    specialistsEpics,
    studentTeachersEpics,
    studentsubjectsEpics,
    testConfigEpics,
    studentFilesEpics,
    studentFileTypesEpics,
    testEpics,
    studentSummaryEpics,
)