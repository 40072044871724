import {Role} from 'lib/auth'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'

export const name = 'PARENTS'

export type ParentsState = {
    crud: CrudState,
}

const initialState = {
    crud: {
        ...initialCrudState,
        filters: {}
    },
}

export function parentsReducer(state: ParentsState = initialState, action: AnyAction): ParentsState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
