import * as React from 'react'
import styled from 'styled-components'
import {Grid, Hidden, List, ListItem as MuiListItem, ListItemText, Typography} from '@material-ui/core'
import {connect, MapStateToProps} from 'react-redux'
import {State} from 'lib/store'
import {selectTheme, Theme} from 'lib/theme'

const FooterStyled = styled.div`
  padding: ${props => props.theme.spacing(1) / 4}px
    ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.common.white};
  position: relative;
`

type UnknownProps = { [key: string]: unknown }

type ThemeProps = { theme: Theme }

const ListItem = styled(MuiListItem)<UnknownProps & ThemeProps>`
  display: inline-block;
  width: auto;
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`

const Logo = styled.img`
  height: 46px;
  margin-top: 5px;
`

const footerText = () => {
    return "Copyright © Słoneczna Kraina " + (new Date().getFullYear()).toString()
}

type StateToProps = {
    readonly theme: Theme
}

type FooterProps = StateToProps

const FooterComponent: React.FunctionComponent<FooterProps> = ({theme}) => (
    <FooterStyled>
        <Grid container spacing={0}>
            <Grid container item justify="flex-end" alignContent={"center"}>
                <Typography style={{padding: "20px", color: "gray"}}>Jakiekolwiek korzystanie z danych zawartych na platformie wymaga uzyskania uprzedniej zgody w formie pisemnej. Korzystanie z nich niezgodnie z <a href={"/regulamin"} style={{color: "#1976d2"}}>Regulaminem</a> lub bez uzyskania wymaganej zgody jest zabronione i może stanowić naruszenie prawa oraz może skutkować odpowiedzialnością cywilną lub karną</Typography>
                <Typography style={{padding: "20px"}}>{footerText()}</Typography>
                <Logo src={"/logo-192x192.png"}/>
            </Grid>
        </Grid>
    </FooterStyled>
)

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    theme: selectTheme(state)
})

export const Footer = connect(mapStateToProps)(FooterComponent)