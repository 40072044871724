import {AnyAction} from 'redux'
import {AuthActionType} from 'lib/auth/actions'
import {User} from 'lib/users'

export type AuthState = {
    isInitialized: boolean
    isAuthenticated: boolean
    isSubmitting: boolean
    isTokenValidating: boolean
    isTokenValidated: boolean
    user: User | null
    redirectPath: string
}

const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    isSubmitting: false,
    isTokenValidating: false,
    isTokenValidated: false,
    user: null,
    redirectPath: '/'
}

export function authReducer(state: AuthState = initialState, action: AnyAction): AuthState {
    switch (action.type) {
        case AuthActionType.InitializeFailure:
            return {
                ...state,
                isInitialized: true,
            }
        case AuthActionType.Login:
        case AuthActionType.PasswordResetInitialize:
        case AuthActionType.PasswordChange:
            return {
                ...state,
                isSubmitting: true,
            }
        case AuthActionType.InitializeSuccess:
        case AuthActionType.LoginSuccess:
        case AuthActionType.RefreshTokenSuccess:
            return {
                ...state,
                isInitialized: true,
                isAuthenticated: true,
                isSubmitting: false,
            }
        case AuthActionType.LoginFailure:
        case AuthActionType.PasswordResetInitializeFailure:
        case AuthActionType.PasswordResetInitializeSuccess:
        case AuthActionType.PasswordChangeFailure:
        case AuthActionType.PasswordChangeSuccess:
            return {
                ...state,
                isSubmitting: false
            }
        case AuthActionType.RefreshTokenFailure:
        case AuthActionType.LogoutSuccess:
            return {
                ...state,
                user: null,
                isAuthenticated: false
            }
        case AuthActionType.PasswordTokenValidation:
            return {
                ...state,
                isTokenValidating: true,
            }
        case AuthActionType.PasswordTokenValidationFailure:
            return {
                ...state,
                isTokenValidating: false,
            }
        case AuthActionType.PasswordTokenValidationSuccess:
            return {
                ...state,
                isTokenValidated: true,
                isTokenValidating: false,
            }
        case AuthActionType.UserInitializeSuccess:
            return {
                ...state,
                user: action.payload
            }
        case AuthActionType.SetRedirectAfterLogin:
            return {
                ...state,
                redirectPath: action.payload
            }
        default:
            return state
    }
}