import {Button, Card, CardContent, Typography} from '@material-ui/core'
import {StudentGroupsPreview, StudentPreview, Table, TestConfigPreview} from 'app/Components'
import {me} from 'lib/auth'
import {CrudAction, RouteType, Type} from 'lib/crud'
import {ListOptions} from 'lib/dto'
import {NavigationAction} from 'lib/navigation'
import {generateCrudPath, getCrudPathLabels} from 'lib/router'
import {State} from 'lib/store';
import {Test, testName} from 'lib/test';
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {NavLink} from "react-router-dom";

type StateToProps = {
    me: User
}

type DispatchToProps = {
    readonly setHeader: (actionName: string, type: RouteType) => void,
    readonly setAction: (action: JSX.Element) => void,
}

type StartProps = {}

const cols = [
    {
        id: "test",
        numeric: false,
        label: "Test",
        sort: false,
        template: (test: Test) => <TestConfigPreview id={test.config} />
    },
    {
        id: "student",
        numeric: false,
        label: "Dziecko",
        sort: false,
        template: (test: Test) => <StudentPreview id={test.student} />
    },
    {
        id: "group",
        numeric: false,
        label: "Grupa",
        sort: false,
        template: (test: Test) => <StudentGroupsPreview id={test.student}/>
    },
    {
        id: "action",
        numeric: true,
        label: "",
        sort: false,
        template: (test: Test) => <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to={generateCrudPath(testName, RouteType.EDIT, {parentId: test.student, id: test.id || ' '})}
        >
            Wykonaj test
        </Button>
    },
]

class StartComponent extends React.Component<StartProps & StateToProps & DispatchToProps> {
    public componentDidMount(): void {
        this.props.setHeader('start', RouteType.SHOW)
        this.props.setAction(<></>)
    }

    public render = () => {
        const getReloadAction = (options: ListOptions, filters: { [paramName: string]: string | number | boolean | undefined } ) => {
            return CrudAction.execute(testName, Type.LIST, {
                orderName: options.sortDirection,
                page: options.page + 1,
                itemsPerPage: options.pageSize,
                published: '0',
                teacher: [this.props.me.id]
            })
        }

        return <>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant={'h4'}>Testy do wykonania</Typography>
                    <Table
                        selector={(state: State) => state.tests}
                        getReloadAction={getReloadAction}
                        cols={cols}
                    />
                </CardContent>
            </Card>
        </>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, StartProps, State> = (state, props) => ({
    me: me(state)!,
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    setHeader: (actionName, type) => {
        dispatch(NavigationAction.setHeader(getCrudPathLabels(actionName, type)))
    },
    setAction: (action: JSX.Element) => {
        dispatch(NavigationAction.setAction(action))
    }
})

export const Start = connect(mapStateToProps, mapDispatchToProps)(StartComponent)