import {Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    section: GenericReflexTestSection[]
    preview?: boolean
}

type SectionState = {
    section: GenericReflexTestSection[]
}

export type GenericReflexTestSection = {
    name: string
    required: boolean
    meaning: string
    response: string
    result?: boolean
}

export class GenericReflexSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section[parseInt(event.target.name)].result = event.target.checked;

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => <Table>
        <TableHead>
            <TableRow>
                <TableCell>Odruch</TableCell>
                <TableCell align={"center"}>Czy występuje</TableCell>
                <TableCell>Odpowiedzi na bodźce</TableCell>
                <TableCell>Znaczenie odruchu</TableCell>
            </TableRow>
        </TableHead>
        <TableBody className={'gray-striped'}>
            {this.state.section.map(
                (item, index) =>
                    <TableRow key={index}>
                        <TableCell>
                            {item.name}
                        </TableCell>
                        <TableCell align={"center"}>
                            <Checkbox
                                name={index + ''}
                                checked={this.state.section[index].result}
                                onChange={this.handleChange}
                                disabled={this.props.preview}
                                color="primary"
                            />
                        </TableCell>
                        <TableCell>
                            {item.response}
                        </TableCell>
                        <TableCell>
                            {item.meaning}
                        </TableCell>
                    </TableRow>,
                this
            )}
        </TableBody>
    </Table>
}