// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20011,
    SchoolRead,
    SchoolWrite,
} from '../models';

export interface DeleteSchoolItemRequest {
    id: string;
}

export interface GetSchoolCollectionRequest {
    id?: Array<string>;
    type?: Array<string>;
    search?: string;
    active?: boolean;
    orderIdentifier?: GetSchoolCollectionOrderIdentifierEnum;
    orderName?: GetSchoolCollectionOrderNameEnum;
    orderLicensed?: GetSchoolCollectionOrderLicensedEnum;
    orderActive?: GetSchoolCollectionOrderActiveEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetSchoolItemRequest {
    id: string;
}

export interface PostSchoolCollectionRequest {
    schoolWrite?: SchoolWrite;
}

export interface PutSchoolItemRequest {
    id: string;
    schoolWrite?: SchoolWrite;
}

/**
 * no description
 */
export class SchoolApi extends BaseAPI {

    /**
     * Removes the School resource.
     */
    deleteSchoolItem({ id }: DeleteSchoolItemRequest): Observable<void>
    deleteSchoolItem({ id }: DeleteSchoolItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteSchoolItem({ id }: DeleteSchoolItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteSchoolItem');

        return this.request<void>({
            url: '/api/schools/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of School resources.
     */
    getSchoolCollection({ id, type, search, active, orderIdentifier, orderName, orderLicensed, orderActive, page, itemsPerPage }: GetSchoolCollectionRequest): Observable<InlineResponse20011>
    getSchoolCollection({ id, type, search, active, orderIdentifier, orderName, orderLicensed, orderActive, page, itemsPerPage }: GetSchoolCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20011>>
    getSchoolCollection({ id, type, search, active, orderIdentifier, orderName, orderLicensed, orderActive, page, itemsPerPage }: GetSchoolCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20011 | RawAjaxResponse<InlineResponse20011>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (type != null) { query['type[]'] = type; }
        if (search != null) { query['search'] = search; }
        if (active != null) { query['active'] = active; }
        if (orderIdentifier != null) { query['order[identifier]'] = orderIdentifier; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderLicensed != null) { query['order[licensed]'] = orderLicensed; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20011>({
            url: '/api/schools',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a School resource.
     */
    getSchoolItem({ id }: GetSchoolItemRequest): Observable<SchoolRead>
    getSchoolItem({ id }: GetSchoolItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SchoolRead>>
    getSchoolItem({ id }: GetSchoolItemRequest, opts?: OperationOpts): Observable<SchoolRead | RawAjaxResponse<SchoolRead>> {
        throwIfNullOrUndefined(id, 'id', 'getSchoolItem');

        return this.request<SchoolRead>({
            url: '/api/schools/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a School resource.
     */
    postSchoolCollection({ schoolWrite }: PostSchoolCollectionRequest): Observable<SchoolRead>
    postSchoolCollection({ schoolWrite }: PostSchoolCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SchoolRead>>
    postSchoolCollection({ schoolWrite }: PostSchoolCollectionRequest, opts?: OperationOpts): Observable<SchoolRead | RawAjaxResponse<SchoolRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<SchoolRead>({
            url: '/api/schools',
            method: 'POST',
            headers,
            body: schoolWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the School resource.
     */
    putSchoolItem({ id, schoolWrite }: PutSchoolItemRequest): Observable<SchoolRead>
    putSchoolItem({ id, schoolWrite }: PutSchoolItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SchoolRead>>
    putSchoolItem({ id, schoolWrite }: PutSchoolItemRequest, opts?: OperationOpts): Observable<SchoolRead | RawAjaxResponse<SchoolRead>> {
        throwIfNullOrUndefined(id, 'id', 'putSchoolItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<SchoolRead>({
            url: '/api/schools/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: schoolWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetSchoolCollectionOrderIdentifierEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetSchoolCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetSchoolCollectionOrderLicensedEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetSchoolCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
