// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20015,
    StudentFileRead,
} from '../models';

export interface ConfirmStudentFileItemRequest {
    id: string;
}

export interface ContentStudentFileItemRequest {
    id: string;
}

export interface DeleteStudentFileItemRequest {
    id: string;
}

export interface GetStudentFileCollectionRequest {
    id?: Array<string>;
    type?: Array<string>;
    student?: Array<string>;
    name?: string;
    orderType?: GetStudentFileCollectionOrderTypeEnum;
    orderName?: GetStudentFileCollectionOrderNameEnum;
    orderCreatedAt?: GetStudentFileCollectionOrderCreatedAtEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetStudentFileItemRequest {
    id: string;
}

export interface PostStudentFileCollectionRequest {
    student?: string;
    type?: string;
    file?: Blob;
}

/**
 * no description
 */
export class StudentFileApi extends BaseAPI {

    /**
     * Replaces the StudentFile resource.
     */
    confirmStudentFileItem({ id }: ConfirmStudentFileItemRequest): Observable<void>
    confirmStudentFileItem({ id }: ConfirmStudentFileItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    confirmStudentFileItem({ id }: ConfirmStudentFileItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'confirmStudentFileItem');

        return this.request<void>({
            url: '/api/student-files/{id}/confirm'.replace('{id}', encodeURI(id)),
            method: 'PUT',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a StudentFile resource.
     */
    contentStudentFileItem({ id }: ContentStudentFileItemRequest): Observable<Blob>
    contentStudentFileItem({ id }: ContentStudentFileItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Blob>>
    contentStudentFileItem({ id }: ContentStudentFileItemRequest, opts?: OperationOpts): Observable<Blob | RawAjaxResponse<Blob>> {
        throwIfNullOrUndefined(id, 'id', 'contentStudentFileItem');

        return this.request<Blob>({
            url: '/api/student-files/{id}/content'.replace('{id}', encodeURI(id)),
            method: 'GET',
            responseType: 'blob',
        }, opts?.responseOpts);
    };

    /**
     * Removes the StudentFile resource.
     */
    deleteStudentFileItem({ id }: DeleteStudentFileItemRequest): Observable<void>
    deleteStudentFileItem({ id }: DeleteStudentFileItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteStudentFileItem({ id }: DeleteStudentFileItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteStudentFileItem');

        return this.request<void>({
            url: '/api/student-files/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of StudentFile resources.
     */
    getStudentFileCollection({ id, type, student, name, orderType, orderName, orderCreatedAt, page, itemsPerPage }: GetStudentFileCollectionRequest): Observable<InlineResponse20015>
    getStudentFileCollection({ id, type, student, name, orderType, orderName, orderCreatedAt, page, itemsPerPage }: GetStudentFileCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20015>>
    getStudentFileCollection({ id, type, student, name, orderType, orderName, orderCreatedAt, page, itemsPerPage }: GetStudentFileCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20015 | RawAjaxResponse<InlineResponse20015>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (type != null) { query['type[]'] = type; }
        if (student != null) { query['student[]'] = student; }
        if (name != null) { query['name'] = name; }
        if (orderType != null) { query['order[type]'] = orderType; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderCreatedAt != null) { query['order[createdAt]'] = orderCreatedAt; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20015>({
            url: '/api/student-files',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a StudentFile resource.
     */
    getStudentFileItem({ id }: GetStudentFileItemRequest): Observable<StudentFileRead>
    getStudentFileItem({ id }: GetStudentFileItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StudentFileRead>>
    getStudentFileItem({ id }: GetStudentFileItemRequest, opts?: OperationOpts): Observable<StudentFileRead | RawAjaxResponse<StudentFileRead>> {
        throwIfNullOrUndefined(id, 'id', 'getStudentFileItem');

        return this.request<StudentFileRead>({
            url: '/api/student-files/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a StudentFile resource.
     */
    postStudentFileCollection({ student, type, file }: PostStudentFileCollectionRequest): Observable<StudentFileRead>
    postStudentFileCollection({ student, type, file }: PostStudentFileCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StudentFileRead>>
    postStudentFileCollection({ student, type, file }: PostStudentFileCollectionRequest, opts?: OperationOpts): Observable<StudentFileRead | RawAjaxResponse<StudentFileRead>> {

        const formData = new FormData();
        if (student !== undefined) { formData.append('student', student as any); }
        if (type !== undefined) { formData.append('type', type as any); }
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<StudentFileRead>({
            url: '/api/student-files',
            method: 'POST',
            body: formData,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetStudentFileCollectionOrderTypeEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetStudentFileCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetStudentFileCollectionOrderCreatedAtEnum {
    Asc = 'asc',
    Desc = 'desc'
}
