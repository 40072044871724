// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';

export interface MailScheduleRequest {
    studentId: string;
}

/**
 * no description
 */
export class MailsApi extends BaseAPI {

    /**
     * Send Student schedule to their parents.
     */
    mailSchedule({ studentId }: MailScheduleRequest): Observable<void>
    mailSchedule({ studentId }: MailScheduleRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    mailSchedule({ studentId }: MailScheduleRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(studentId, 'studentId', 'mailSchedule');

        return this.request<void>({
            url: '/api/mail/schedule/{studentId}'.replace('{studentId}', encodeURI(studentId)),
            method: 'POST',
        }, opts?.responseOpts);
    };

}
