// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    EventRead,
    EventWrite,
    InlineResponse2003,
} from '../models';

export interface DeleteEventItemRequest {
    id: string;
}

export interface GetEventCollectionRequest {
    id?: Array<string>;
    name?: string;
    active?: boolean;
    _protected?: boolean;
    orderName?: GetEventCollectionOrderNameEnum;
    orderActive?: GetEventCollectionOrderActiveEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetEventItemRequest {
    id: string;
}

export interface PostEventCollectionRequest {
    eventWrite?: EventWrite;
}

export interface PutEventItemRequest {
    id: string;
    eventWrite?: EventWrite;
}

/**
 * no description
 */
export class EventApi extends BaseAPI {

    /**
     * Removes the Event resource.
     */
    deleteEventItem({ id }: DeleteEventItemRequest): Observable<void>
    deleteEventItem({ id }: DeleteEventItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteEventItem({ id }: DeleteEventItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteEventItem');

        return this.request<void>({
            url: '/api/events/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of Event resources.
     */
    getEventCollection({ id, name, active, _protected, orderName, orderActive, page, itemsPerPage }: GetEventCollectionRequest): Observable<InlineResponse2003>
    getEventCollection({ id, name, active, _protected, orderName, orderActive, page, itemsPerPage }: GetEventCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2003>>
    getEventCollection({ id, name, active, _protected, orderName, orderActive, page, itemsPerPage }: GetEventCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2003 | RawAjaxResponse<InlineResponse2003>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (name != null) { query['name'] = name; }
        if (active != null) { query['active'] = active; }
        if (_protected != null) { query['protected'] = _protected; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2003>({
            url: '/api/events',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Event resource.
     */
    getEventItem({ id }: GetEventItemRequest): Observable<EventRead>
    getEventItem({ id }: GetEventItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<EventRead>>
    getEventItem({ id }: GetEventItemRequest, opts?: OperationOpts): Observable<EventRead | RawAjaxResponse<EventRead>> {
        throwIfNullOrUndefined(id, 'id', 'getEventItem');

        return this.request<EventRead>({
            url: '/api/events/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a Event resource.
     */
    postEventCollection({ eventWrite }: PostEventCollectionRequest): Observable<EventRead>
    postEventCollection({ eventWrite }: PostEventCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<EventRead>>
    postEventCollection({ eventWrite }: PostEventCollectionRequest, opts?: OperationOpts): Observable<EventRead | RawAjaxResponse<EventRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EventRead>({
            url: '/api/events',
            method: 'POST',
            headers,
            body: eventWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the Event resource.
     */
    putEventItem({ id, eventWrite }: PutEventItemRequest): Observable<EventRead>
    putEventItem({ id, eventWrite }: PutEventItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<EventRead>>
    putEventItem({ id, eventWrite }: PutEventItemRequest, opts?: OperationOpts): Observable<EventRead | RawAjaxResponse<EventRead>> {
        throwIfNullOrUndefined(id, 'id', 'putEventItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EventRead>({
            url: '/api/events/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: eventWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetEventCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetEventCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
