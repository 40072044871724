import {Enum} from 'app/Components'
import * as React from 'react'
import {connect} from 'react-redux'
import {name as rolesName} from "lib/roles";

type RolesEnumProps = {
    ids: string[]
}

class RolesEnumComponent extends React.Component<RolesEnumProps> {
    public render = () => this.props.ids
        .map((role: string, index: number) => (
            <Enum key={index} name={rolesName} id={role}/>)
        )
}

export const RolesEnum = connect()(RolesEnumComponent)