function createShadow(px: number) {
    return `0 0 ${px}px 0 rgba(53,64,82,.05)`
}

export const shadows = [
    "none",
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14),
    createShadow(14)
]
