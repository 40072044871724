import GroupIcon from '@material-ui/icons/EmojiPeopleOutlined'
import ChildrenIcon from '@material-ui/icons/FaceOutlined'
import SubjectIcon from '@material-ui/icons/LibraryBooksOutlined'
import TeacherIcon from '@material-ui/icons/PeopleAltOutlined'
import {Bookmarks} from 'app/Components'
import {RouteType} from 'lib/crud'
import {name as groupClassesName} from 'lib/groupClass'
import {name as groupTeacherName} from 'lib/groupTeacher'
import {name as groupChildrenName} from 'lib/groupChildren'
import {name} from 'lib/groups'
import {generateCrudPath} from 'lib/router'
import * as React from 'react'

type TabsProps = {
    readonly id: string
}

export class Tabs extends React.Component<TabsProps> {
    public render = () => <Bookmarks bookmarks={[
        {
            label: 'Grupa',
            icon: <GroupIcon/>,
            to: generateCrudPath(name, RouteType.SHOW, {id: this.props.id}),
            exact: true
        },
        {
            label: 'Wychowawcy',
            icon: <TeacherIcon/>,
            to: generateCrudPath(groupTeacherName, RouteType.LIST, {parentId: this.props.id})
        },
        {
            label: 'Dzieci',
            icon: <ChildrenIcon/>,
            to: generateCrudPath(groupChildrenName, RouteType.LIST, {parentId: this.props.id})
        },
        {
            label: 'Zajęcia',
            icon: <SubjectIcon/>,
            to: generateCrudPath(groupClassesName, RouteType.LIST, {parentId: this.props.id})
        }
    ]} />
}