import {CrudList, Enum, FilterType, Status} from 'app/Components'
import {rules} from 'lib/auth'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {name, Subject} from 'lib/subjects'
import {name as subjectCategoryName} from 'lib/subjectsCategory'
import * as React from 'react'

const cols = [
    {
        id: "name",
        numeric: false,
        label: "Nazwa",
        sort: false,
        template: (row: Subject) => <>{row.name}</>
    },
    {
        id: "category",
        numeric: false,
        label: "Kategoria",
        sort: false,
        width: '20%',
        template: (row: Subject) => <Enum name={subjectCategoryName} id={row.category} />
    },
    {
        id: "active",
        numeric: false,
        label: "Status",
        sort: false,
        width: '100px',
        template: (row: Subject) => <Status status={row.active} activeLabel={'Aktywne'} inactiveLabel={'Nieaktywne'}/>,
        rule: rules.subject.status
    },
]

export class List extends React.Component {
    public render = () => (
        <CrudList
            actionName={name}
            cols={cols}
            selector={(state: State) => state.subjects}
            deleteDialogText="Usunąć zajęcie?"
            rules={rules.subject}
            filter={{
                initialValues: {categories: [], name: '', active: '1'},
                fields: [
                    {name: 'category', label: 'Kategoria', type: FilterType.SELECT, source: Source.SUBJECTS_CATEGORY},
                    {name: 'name', label: 'Nazwa', type: FilterType.TEXT},
                    {
                        name: 'active',
                        label: 'Status',
                        type: FilterType.SELECT,
                        source: Source.STATUS,
                        requestOptions: {type: Source.SUBJECTS},
                        rule: rules.subject.status
                    }
                ]
            }}
        />
    )
}