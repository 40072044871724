import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export const TabsActionType = {
    setTab: (): string => `[DATA] Set tab index`,
}

export const TabsAction = {
    setTab: (index: number) => createAction(TabsActionType.setTab(), index),
}

export type TabsAction = ActionsUnion<typeof TabsAction>
