import {ActionsUnion, createAction} from "external-lib/redux-utils";

export const EnumActionType = {
    Load: (name: string) => `[ENUM] load (name: ${name})`,
    LoadFailure: (name: string) => `[ENUM] load failure (name: ${name})`,
    LoadSuccess: (name: string) => `[ENUM] load success (name: ${name})`,
}

export const EnumAction = {
    load: (name: string) => createAction(EnumActionType.Load(name), {name}),
    loadFailure: (name: string, error: any) => createAction(EnumActionType.LoadFailure(name), error),
    loadSuccess: (name: string, response: any) => createAction(EnumActionType.LoadSuccess(name), response),
}

export type EnumAction = ActionsUnion<typeof EnumAction>
