import {Box} from '@material-ui/core'
import Link from '@material-ui/core/Link'
import {RouteType} from 'lib/crud'
import {generateCrudPath} from 'lib/router'
import {name, User} from 'lib/users'
import * as React from 'react'
import {connect, MapStateToProps} from "react-redux";
import {NavLink} from 'react-router-dom'
import {me, Role} from 'lib/auth'
import {State} from 'lib/store'

type StateToProps = {
    readonly me: User
}

type UserLabelProps = {
    user: User
}

class UserLabelComponent extends React.Component<StateToProps & UserLabelProps> {
    public render = () => (this.props.me.roles.includes(Role.ROLE_PARENT)
        ? <>{this.props.user.lastName} {this.props.user.firstName}</>
        : <>
        <Link component={NavLink} to={generateCrudPath(name, RouteType.SHOW, {id: this.props.user.id})}>
            {this.props.user.lastName} {this.props.user.firstName}
        </Link>
        <Box>({this.props.user.identifier})</Box>
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    me: me(state)!
})

export const UserLabel = connect(mapStateToProps)(UserLabelComponent)