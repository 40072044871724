// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    IndividualClassRead,
    IndividualClassWrite,
    InlineResponse2007,
} from '../models';

export interface DeleteIndividualClassItemRequest {
    id: string;
}

export interface GetIndividualClassCollectionRequest {
    id?: Array<string>;
    studentId?: Array<string>;
    orderTeacherFirstName?: GetIndividualClassCollectionOrderTeacherFirstNameEnum;
    orderSubjectName?: GetIndividualClassCollectionOrderSubjectNameEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetIndividualClassItemRequest {
    id: string;
}

export interface PostIndividualClassCollectionRequest {
    individualClassWrite?: IndividualClassWrite;
}

export interface PutIndividualClassItemRequest {
    id: string;
    individualClassWrite?: IndividualClassWrite;
}

/**
 * no description
 */
export class IndividualClassApi extends BaseAPI {

    /**
     * Removes the IndividualClass resource.
     */
    deleteIndividualClassItem({ id }: DeleteIndividualClassItemRequest): Observable<void>
    deleteIndividualClassItem({ id }: DeleteIndividualClassItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteIndividualClassItem({ id }: DeleteIndividualClassItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteIndividualClassItem');

        return this.request<void>({
            url: '/api/individual-classes/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of IndividualClass resources.
     */
    getIndividualClassCollection({ id, studentId, orderTeacherFirstName, orderSubjectName, page, itemsPerPage }: GetIndividualClassCollectionRequest): Observable<InlineResponse2007>
    getIndividualClassCollection({ id, studentId, orderTeacherFirstName, orderSubjectName, page, itemsPerPage }: GetIndividualClassCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2007>>
    getIndividualClassCollection({ id, studentId, orderTeacherFirstName, orderSubjectName, page, itemsPerPage }: GetIndividualClassCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2007 | RawAjaxResponse<InlineResponse2007>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (studentId != null) { query['student.id[]'] = studentId; }
        if (orderTeacherFirstName != null) { query['order[teacher.firstName]'] = orderTeacherFirstName; }
        if (orderSubjectName != null) { query['order[subject.name]'] = orderSubjectName; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2007>({
            url: '/api/individual-classes',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a IndividualClass resource.
     */
    getIndividualClassItem({ id }: GetIndividualClassItemRequest): Observable<IndividualClassRead>
    getIndividualClassItem({ id }: GetIndividualClassItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<IndividualClassRead>>
    getIndividualClassItem({ id }: GetIndividualClassItemRequest, opts?: OperationOpts): Observable<IndividualClassRead | RawAjaxResponse<IndividualClassRead>> {
        throwIfNullOrUndefined(id, 'id', 'getIndividualClassItem');

        return this.request<IndividualClassRead>({
            url: '/api/individual-classes/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a IndividualClass resource.
     */
    postIndividualClassCollection({ individualClassWrite }: PostIndividualClassCollectionRequest): Observable<IndividualClassRead>
    postIndividualClassCollection({ individualClassWrite }: PostIndividualClassCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<IndividualClassRead>>
    postIndividualClassCollection({ individualClassWrite }: PostIndividualClassCollectionRequest, opts?: OperationOpts): Observable<IndividualClassRead | RawAjaxResponse<IndividualClassRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<IndividualClassRead>({
            url: '/api/individual-classes',
            method: 'POST',
            headers,
            body: individualClassWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the IndividualClass resource.
     */
    putIndividualClassItem({ id, individualClassWrite }: PutIndividualClassItemRequest): Observable<IndividualClassRead>
    putIndividualClassItem({ id, individualClassWrite }: PutIndividualClassItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<IndividualClassRead>>
    putIndividualClassItem({ id, individualClassWrite }: PutIndividualClassItemRequest, opts?: OperationOpts): Observable<IndividualClassRead | RawAjaxResponse<IndividualClassRead>> {
        throwIfNullOrUndefined(id, 'id', 'putIndividualClassItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<IndividualClassRead>({
            url: '/api/individual-classes/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: individualClassWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetIndividualClassCollectionOrderTeacherFirstNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetIndividualClassCollectionOrderSubjectNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
