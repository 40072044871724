import {ParentLabel, Preview} from 'app/Components'
import {Source} from 'lib/select'
import {User} from 'lib/users'
import * as React from 'react'

type ParentPreviewProps = {
    id: string,
}

export class ParentPreview extends React.Component<ParentPreviewProps> {
    public render = () => <Preview
        value={this.props.id}
        source={Source.USERS}
        template={(user: User) => <ParentLabel user={user} />}
    />
}
