import {IActionWithPayload} from 'external-lib/redux-utils'
import {dao as disabilitiesDao} from 'lib/disabilities'
import {dao as eventsDao} from 'lib/events'
import {dao as groupClassesDao} from 'lib/groupClass'
import {dao as individualClassesDao} from 'lib/individualClass'
import {dao as groupsDao} from 'lib/groups'
import {PreviewAction, PreviewActionType} from 'lib/preview'
import {dao as qualificationsDao} from 'lib/qualifications'
import {dao as schoolsDao} from 'lib/schools'
import {Source} from 'lib/select'
import {transformPayload} from 'lib/select/transformer'
import {dao as specialtiesDao} from 'lib/specialties'
import {State} from 'lib/store'
import {dao as studentsDao} from 'lib/students'
import {dao as subjectsDao} from 'lib/subjects'
import {dao as usersDao} from 'lib/users'
import {dao as testConfigDao} from 'lib/testConfig'
import {AnyAction} from 'redux'
import {combineEpics, Epic, ofType} from 'redux-observable'
import {Observable, of} from 'rxjs'
import {catchError, debounceTime, filter, map, switchMap, withLatestFrom} from 'rxjs/operators'

type PreviewEpic<R, P, I, O> = (apiCall: (payload: any) => Observable<R>) => Epic<AnyAction, IActionWithPayload<string, O>>

const loadPreviewEpic: <R, P, I, O>(source: string) => PreviewEpic<R, P, I, O> = (source: string) => apiCall => (action$, state$) => action$.pipe(
    ofType(PreviewActionType.Load(source)),
    debounceTime(500),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return apiCall(transformPayload(source, action.payload)).pipe(
            map(response => PreviewAction.loadSuccess(source, {source, response})),
            catchError(error => of(PreviewAction.loadFailure(source, {source, error}))),
        )
    })
)

const loadPreviewAfterIdChangeEpic: Epic<AnyAction, AnyAction, State> = (action$, state$) => action$.pipe(
    filter(action => action.type.startsWith('[PREVIEW] add value to')),
    withLatestFrom(state$),
    map(([action, state]) => {
        const ids = state.preview[action.payload.source] ? (state.preview[action.payload.source].ids || []) : []
        return PreviewAction.load(action.payload.source, ids)
    })
)

export const previewEpics = combineEpics(
    loadPreviewAfterIdChangeEpic,
    loadPreviewEpic(Source.USERS)(usersDao.list),
    loadPreviewEpic(Source.SCHOOLS)(schoolsDao.list),
    loadPreviewEpic(Source.GROUPS)(groupsDao.list),
    loadPreviewEpic(Source.QUALIFICATIONS)(qualificationsDao.list),
    loadPreviewEpic(Source.SPECIALTIES)(specialtiesDao.list),
    loadPreviewEpic(Source.STUDENTS)(studentsDao.list),
    loadPreviewEpic(Source.GROUP_CLASSES)(groupClassesDao.list),
    loadPreviewEpic(Source.INDIVIDUAL_CLASSES)(individualClassesDao.list),
    loadPreviewEpic(Source.SUBJECTS)(subjectsDao.list),
    loadPreviewEpic(Source.STUDENTS)(studentsDao.list),
    loadPreviewEpic(Source.GROUP_CLASSES)(groupClassesDao.list),
    loadPreviewEpic(Source.SUBJECTS)(subjectsDao.list),
    loadPreviewEpic(Source.DISABILITIES)(disabilitiesDao.list),
    loadPreviewEpic(Source.EVENTS)(eventsDao.list),
    loadPreviewEpic(Source.TEST_CONFIG)(testConfigDao.list)
)