// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20013,
    StudentCategoryRead,
} from '../models';

export interface GetStudentCategoryCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetStudentCategoryItemRequest {
    id: string;
}

/**
 * no description
 */
export class StudentCategoryApi extends BaseAPI {

    /**
     * Retrieves the collection of StudentCategory resources.
     */
    getStudentCategoryCollection({ page, itemsPerPage }: GetStudentCategoryCollectionRequest): Observable<InlineResponse20013>
    getStudentCategoryCollection({ page, itemsPerPage }: GetStudentCategoryCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20013>>
    getStudentCategoryCollection({ page, itemsPerPage }: GetStudentCategoryCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20013 | RawAjaxResponse<InlineResponse20013>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20013>({
            url: '/api/student-categories',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a StudentCategory resource.
     */
    getStudentCategoryItem({ id }: GetStudentCategoryItemRequest): Observable<StudentCategoryRead>
    getStudentCategoryItem({ id }: GetStudentCategoryItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StudentCategoryRead>>
    getStudentCategoryItem({ id }: GetStudentCategoryItemRequest, opts?: OperationOpts): Observable<StudentCategoryRead | RawAjaxResponse<StudentCategoryRead>> {
        throwIfNullOrUndefined(id, 'id', 'getStudentCategoryItem');

        return this.request<StudentCategoryRead>({
            url: '/api/student-categories/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
