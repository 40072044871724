import {ClassDurationApi} from 'client/apis'
import {configuration} from 'lib/client'

const ClassDurationApiClient = (): ClassDurationApi => {
    return new ClassDurationApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return ClassDurationApiClient().getClassDurationCollection(payload)
    },
    get: (payload: any) => {
        return ClassDurationApiClient().getClassDurationItem({id: payload.id})
    },
}