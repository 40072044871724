import {Card, CardContent, Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core'
import {CrudShowHeader, DisabilitiesPreview, Enum, GroupPreview, SchoolPreview, Status} from 'app/Components'
import {Tabs} from 'app/Views/Students/Tabs'
import {isGranted, me, rules} from 'lib/auth'
import {getRow} from 'lib/crud'
import {name as educationStatusName} from "lib/educationStatus";
import {State} from 'lib/store'
import {name, Student} from 'lib/students'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'

type StateToProps = {
    readonly student: Student
    readonly me: User
}

type ShowProps = StateToProps

class ShowComponent extends React.Component<ShowProps> {
    public render = () => (<>
        <CrudShowHeader actionName={name}/>

        <Tabs id={this.props.student.id || ' '}/>
        <Card variant="outlined">
            <CardContent>
                <TableContainer>
                    <Table>
                        <TableBody className={'gray-striped'}>
                            <TableRow>
                                <TableCell component="td">Nazwisko i Imię</TableCell>
                                <TableCell component="td">
                                    {this.props.student.lastName} {this.props.student.firstName}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Identyfikator</TableCell>
                                <TableCell component="td">
                                    {this.props.student.identifier}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Data rozpoczęcia edukacji</TableCell>
                                <TableCell component="td">
                                    {new Date(this.props.student.educationStartDate).toLocaleDateString()}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Data urodzenia</TableCell>
                                <TableCell component="td">
                                    {new Date(this.props.student.birthDate).toLocaleDateString()}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Grupa</TableCell>
                                <TableCell component="td">
                                    <GroupPreview ids={this.props.student.groups}/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Szkoła</TableCell>
                                <TableCell component="td">
                                    <SchoolPreview id={this.props.student.school}/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Typ niepełnosprawności</TableCell>
                                <TableCell component="td">
                                    <DisabilitiesPreview ids={this.props.student.disabilities}/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Choroby współistniejące</TableCell>
                                <TableCell component="td">
                                    {this.props.student.comorbidities}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Stosuje farmakoterapię</TableCell>
                                <TableCell component="td">
                                    <Status status={this.props.student.hasPharmacotherapy}/>
                                </TableCell>
                            </TableRow>
                            {this.props.student.hasPharmacotherapy ?
                                <TableRow>
                                    <TableCell component="td">Opis farmakoterapii</TableCell>
                                    <TableCell component="td">
                                        {this.props.student.pharmacotherapyDescription}
                                    </TableCell>
                                </TableRow>
                                : ''
                            }
                            <TableRow>
                                <TableCell component="td">Stosuje specjalną dietę</TableCell>
                                <TableCell component="td">
                                    <Status status={this.props.student.hasDiet}/>
                                </TableCell>
                            </TableRow>
                            {this.props.student.hasDiet ?
                                <TableRow>
                                    <TableCell component="td">Opis diety</TableCell>
                                    <TableCell component="td">
                                        {this.props.student.dietDescription}
                                    </TableCell>
                                </TableRow>
                                : ''
                            }
                            <TableRow>
                                <TableCell component="td">Posiada rodzeństwo</TableCell>
                                <TableCell component="td">
                                    <Status status={this.props.student.hasSiblings}/>
                                </TableCell>
                            </TableRow>
                            {this.props.student.hasSiblings ? <TableRow>
                                <TableCell component="td">Typ niepełnosprawności rodzeństwa</TableCell>
                                <TableCell component="td">
                                    <DisabilitiesPreview ids={this.props.student.siblingsDisabilities}/>
                                </TableCell>
                            </TableRow> : null}
                            {isGranted(rules.student.status, this.props.me) ? <TableRow>
                                <TableCell component="td">Status</TableCell>
                                <TableCell component="td">
                                    <Enum name={educationStatusName} id={this.props.student.status}/>
                                </TableCell>
                            </TableRow> : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    me: me(state)!
})

export const Show = connect(mapStateToProps)(ShowComponent)