import {CrudList, Enum, StudentLabel} from 'app/Components'
import {Tabs} from 'app/Views/Groups'
import {rules} from 'lib/auth'
import {CrudAction, emptyFilterId, getRow, Type} from 'lib/crud'
import {name as educationStatusName} from 'lib/educationStatus'
import {name as groupChildrenName} from 'lib/groupChildren'
import {Group, name as groupsName} from 'lib/groups'
import {State} from 'lib/store'
import {Student} from 'lib/students'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'

type StateToProps = {
    readonly group: Group
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
}

type Params = {
    parentId: string
}

const cols = [
    {
        id: "student",
        numeric: false,
        label: "Dziecko",
        sort: false,
        template: (student: Student) => <StudentLabel student={student}/>
    },
    {
        id: "category",
        numeric: false,
        label: "Kategoria",
        sort: false,
        width: '20%',
        template: (student: Student) => student.category
    },
    {
        id: "status",
        numeric: false,
        label: "Status",
        sort: false,
        width: '20%',
        template: (student: Student) => <Enum name={educationStatusName} id={student.status}/>
    },
]

export class ListComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(groupsName, this.props.match.params.parentId)
        }
    }

    public render = () => <>
        {this.props.group.id !== undefined ? <CrudList
            actionName={groupChildrenName}
            parentActionName={groupsName}
            cols={cols}
            selector={(state: State) => state.children}
            deleteDialogText="Usunąć dziecko?"
            rules={rules.groupChildren}
            filter={{
                initialValues: {
                    id: this.props.group.id !== undefined && this.props.group.students.length > 0
                        ? this.props.group.students
                        : [emptyFilterId]
                },
                fields: []
            }}
            tabs={<Tabs id={this.props.match.params.parentId} />}
        /> : null}
    </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    group: getRow(state => state.groups)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(ListComponent))