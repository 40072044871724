import {ProtectedRoute} from 'app/Components'
import {Add as DictionaryAdd, Edit as DictionaryEdit, List as DictionariesList} from 'app/Views/Dictionaries'
import {Add as GroupsAdd, Edit as GroupsEdit, List as GroupsList, Show as GroupsShow} from 'app/Views/Groups'
import {Edit as GroupChildrenEdit, List as GroupChildrenList} from 'app/Views/Groups/Tabs/GroupChildren'
import {
    Add as GroupClassesAdd,
    Edit as GroupClassesEdit,
    List as GroupClassesList
} from 'app/Views/Groups/Tabs/GroupClasses'
import {Edit as GroupTeachersEdit, List as GroupTeachersList} from 'app/Views/Groups/Tabs/GroupTeachers'
import {Add as SchoolAdd, Edit as SchoolEdit, List as SchoolsList, Show as SchoolShow} from 'app/Views/Schools'
import {Add as StudentAdd, Edit as StudentEdit, List as StudentList, Show as StudentShow} from 'app/Views/Students'
import {Add as StudentFilesAdd, List as StudentFilesList} from 'app/Views/Students/Tabs/Files'
import {Add as HistoryEntryAdd, Edit as HistoryEntryEdit, List as HistoryEntryList} from 'app/Views/Students/Tabs/HistoryEntry'
import {List as StudentSummary} from 'app/Views/Students/Tabs/Summary'
import {Add as ParentsAdd, List as ParentsList} from 'app/Views/Students/Tabs/Parents'
import {Add as StudentIndividualClassAdd, Edit as StudentIndividualClassEdit, List as StudentSubjectsList} from 'app/Views/Students/Tabs/Subjects'
import {Add as StudentTeacherAdd, List as StudentTeachersList} from 'app/Views/Students/Tabs/Teachers'
import {Add as TestsAdd, Edit as TestsEdit, List as TestsList, Preview as TestPreview} from 'app/Views/Students/Tabs/Tests'
import {Show as TestShow, Data as TestData, Analysis as TestAnalysis, Summary as TestSummary} from 'app/Views/Tests'
import {Schools, Groups, Students} from 'app/Views/Analysis'
import {Add as SubjectsAdd, Edit as SubjectsEdit, List as SubjectsList, Show as SubjectsShow} from 'app/Views/Subjects'
import {Edit as TestConfigEdit, List as TestConfigList} from 'app/Views/TestConfig'
import {Add as UserAdd, Edit as UserEdit, List as UsersList, Show as UserShow} from 'app/Views/Users'
import {rules} from 'lib/auth'
import {RouteType} from 'lib/crud'
import {name as groupChildrenName} from 'lib/groupChildren'
import {name as groupClassName} from 'lib/groupClass'
import {name as groupsName} from 'lib/groups'
import {name as groupTeacherName} from 'lib/groupTeacher'
import {name as historyEntryName} from 'lib/historyEntry'
import {name as studentIndividualClassName} from 'lib/individualClass'
import {name as parentsName} from 'lib/parents'
import {generateRouterCrudPath, ProtectedRoutes, Routes} from 'lib/router'
import {name as schoolsName} from 'lib/schools'
import {name as studentFilesName} from 'lib/studentFiles'
import {name as studentsName} from 'lib/students'
import {name as studentSubjectName} from 'lib/studentSubject'
import {name as studentTeacherName} from 'lib/studentTeacher'
import {name as subjectsName} from 'lib/subjects'
import {testConfigName} from 'lib/testConfig'
import {name as usersName} from 'lib/users'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import {testAnalysisName, testSummaryName, testDataName, testName, testResultName} from 'lib/test'
import {studentSummaryName} from "../lib/studentSummary";
import {Start} from './Views/Dashboard/Start'
import {TermsOfService} from "app/Views/TermsOfService";

type RouterProps = {}

export class Router extends React.Component<RouterProps> {
    public render(): React.ReactNode {
        return (
            <Switch>
                <ProtectedRoute path={ProtectedRoutes.Start} rule={rules.start.list}
                                fallback={generateRouterCrudPath(studentsName, RouteType.LIST)}>
                    <Start/>
                </ProtectedRoute>

                <Route path={Routes.TermsOfService}>
                    <TermsOfService/>
                </Route>

                <Route path={ProtectedRoutes.DictionaryAdd}>
                    <DictionaryAdd/>
                </Route>
                <Route path={ProtectedRoutes.DictionaryEdit}>
                    <DictionaryEdit/>
                </Route>
                <Route path={ProtectedRoutes.DictionaryList}>
                    <DictionariesList/>
                </Route>

                <Route path={generateRouterCrudPath(usersName, RouteType.ADD)}>
                    <UserAdd/>
                </Route>
                <Route path={generateRouterCrudPath(usersName, RouteType.EDIT)}>
                    <UserEdit/>
                </Route>
                <Route path={generateRouterCrudPath(usersName, RouteType.SHOW)}>
                    <UserShow/>
                </Route>
                <ProtectedRoute path={generateRouterCrudPath(usersName, RouteType.LIST)}
                                rule={rules.user.list}
                                fallback={ProtectedRoutes.Start}>
                    <UsersList/>
                </ProtectedRoute>

                <Route path={generateRouterCrudPath(schoolsName, RouteType.ADD)}>
                    <SchoolAdd/>
                </Route>
                <Route path={generateRouterCrudPath(schoolsName, RouteType.EDIT)}>
                    <SchoolEdit/>
                </Route>
                <Route path={generateRouterCrudPath(schoolsName, RouteType.SHOW)}>
                    <SchoolShow/>
                </Route>
                <Route path={generateRouterCrudPath(schoolsName, RouteType.LIST)}>
                    <SchoolsList/>
                </Route>

                <ProtectedRoute path={generateRouterCrudPath(subjectsName, RouteType.ADD)}
                                rule={rules.subject.add}
                                fallback={ProtectedRoutes.Start}>
                    <SubjectsAdd/>
                </ProtectedRoute>
                <ProtectedRoute path={generateRouterCrudPath(subjectsName, RouteType.EDIT)}>
                    <SubjectsEdit/>
                </ProtectedRoute>
                <ProtectedRoute path={generateRouterCrudPath(subjectsName, RouteType.SHOW)}
                                rule={rules.subject.show}
                                fallback={ProtectedRoutes.Start}>
                    <SubjectsShow/>
                </ProtectedRoute>
                <ProtectedRoute path={generateRouterCrudPath(subjectsName, RouteType.LIST)}
                                rule={rules.subject.list}
                                fallback={ProtectedRoutes.Start}>
                    <SubjectsList/>
                </ProtectedRoute>

                <Route path={generateRouterCrudPath(groupTeacherName, RouteType.ADD)}>
                    <GroupTeachersEdit/>
                </Route>
                <Route path={generateRouterCrudPath(groupTeacherName, RouteType.EDIT)}>
                    <GroupTeachersEdit/>
                </Route>
                <Route path={generateRouterCrudPath(groupTeacherName, RouteType.LIST)}>
                    <GroupTeachersList/>
                </Route>

                <Route path={generateRouterCrudPath(groupChildrenName, RouteType.ADD)}>
                    <GroupChildrenEdit/>
                </Route>
                <Route path={generateRouterCrudPath(groupChildrenName, RouteType.EDIT)}>
                    <GroupChildrenEdit/>
                </Route>
                <Route path={generateRouterCrudPath(groupChildrenName, RouteType.LIST)}>
                    <GroupChildrenList/>
                </Route>

                <Route path={generateRouterCrudPath(groupClassName, RouteType.ADD)}>
                    <GroupClassesAdd/>
                </Route>
                <Route path={generateRouterCrudPath(groupClassName, RouteType.EDIT)}>
                    <GroupClassesEdit/>
                </Route>
                <Route path={generateRouterCrudPath(groupClassName, RouteType.LIST)}>
                    <GroupClassesList/>
                </Route>

                <ProtectedRoute path={generateRouterCrudPath(groupsName, RouteType.ADD)}
                                rule={rules.group.add}
                                fallback={ProtectedRoutes.Start}>
                    <GroupsAdd/>
                </ProtectedRoute>
                <Route path={generateRouterCrudPath(groupsName, RouteType.EDIT)}>
                    <GroupsEdit/>
                </Route>
                <Route path={generateRouterCrudPath(groupsName, RouteType.SHOW)}>
                    <GroupsShow/>
                </Route>
                <ProtectedRoute path={generateRouterCrudPath(groupsName, RouteType.LIST)}
                                rule={rules.group.list}
                                fallback={ProtectedRoutes.Start}>
                    <GroupsList/>
                </ProtectedRoute>

                <Route path={generateRouterCrudPath(historyEntryName, RouteType.ADD)}>
                    <HistoryEntryAdd/>
                </Route>
                <Route path={generateRouterCrudPath(historyEntryName, RouteType.EDIT)}>
                    <HistoryEntryEdit/>
                </Route>
                <Route path={generateRouterCrudPath(historyEntryName, RouteType.LIST)}>
                    <HistoryEntryList/>
                </Route>

                <Route path={generateRouterCrudPath(studentSummaryName, RouteType.LIST)}>
                    <StudentSummary/>
                </Route>

                <Route path={ProtectedRoutes.TestPreview}>
                    <TestPreview/>
                </Route>
                <Route path={generateRouterCrudPath(testName, RouteType.ADD)}>
                    <TestsAdd/>
                </Route>
                <Route path={generateRouterCrudPath(testName, RouteType.EDIT)}>
                    <TestsEdit/>
                </Route>
                <Route path={generateRouterCrudPath(testAnalysisName, RouteType.SHOW)}>
                    <TestAnalysis/>
                </Route>
                <Route path={generateRouterCrudPath(testSummaryName, RouteType.SHOW)}>
                    <TestSummary/>
                </Route>
                <Route path={generateRouterCrudPath(testResultName, RouteType.SHOW)}>
                    <TestShow/>
                </Route>
                <Route path={generateRouterCrudPath(testName, RouteType.SHOW)}>
                    <TestData/>
                </Route>

                <Route path={generateRouterCrudPath(testName, RouteType.LIST)}>
                    <TestsList/>
                </Route>

                <Route path={ProtectedRoutes.TestCompareSchools}>
                    <Schools/>
                </Route>

                <Route path={ProtectedRoutes.TestCompareGroups}>
                    <Groups/>
                </Route>

                <Route path={ProtectedRoutes.TestCompareChildren}>
                    <Students/>
                </Route>

                <Route path={generateRouterCrudPath(parentsName, RouteType.ADD)}>
                    <ParentsAdd/>
                </Route>
                <Route path={generateRouterCrudPath(parentsName, RouteType.LIST)}>
                    <ParentsList/>
                </Route>

                <Route path={generateRouterCrudPath(studentTeacherName, RouteType.ADD)}>
                    <StudentTeacherAdd/>
                </Route>
                <Route path={generateRouterCrudPath(studentTeacherName, RouteType.LIST)}>
                    <StudentTeachersList/>
                </Route>

                <Route path={generateRouterCrudPath(studentFilesName, RouteType.ADD)}>
                    <StudentFilesAdd/>
                </Route>
                <Route path={generateRouterCrudPath(studentFilesName, RouteType.LIST)}>
                    <StudentFilesList/>
                </Route>

                <Route path={generateRouterCrudPath(studentIndividualClassName, RouteType.ADD)}>
                    <StudentIndividualClassAdd/>
                </Route>
                <Route path={generateRouterCrudPath(studentIndividualClassName, RouteType.EDIT)}>
                    <StudentIndividualClassEdit/>
                </Route>
                <Route path={generateRouterCrudPath(studentSubjectName, RouteType.LIST)}>
                    <StudentSubjectsList/>
                </Route>

                <Route path={generateRouterCrudPath(studentsName, RouteType.ADD)}>
                    <StudentAdd/>
                </Route>
                <Route path={generateRouterCrudPath(studentsName, RouteType.EDIT)}>
                    <StudentEdit/>
                </Route>
                <Route path={generateRouterCrudPath(studentsName, RouteType.SHOW)}>
                    <StudentShow/>
                </Route>
                <Route path={generateRouterCrudPath(studentsName, RouteType.LIST)}>
                    <StudentList/>
                </Route>

                <Route path={generateRouterCrudPath(testConfigName, RouteType.EDIT)}>
                    <TestConfigEdit/>
                </Route>
                <ProtectedRoute path={generateRouterCrudPath(testConfigName, RouteType.LIST)}
                                rule={rules.testConfig.list} fallback={ProtectedRoutes.Start}>
                    <TestConfigList/>
                </ProtectedRoute>

                <Route path={'/'}>
                    <Redirect to={ProtectedRoutes.Start}/>
                </Route>
            </Switch>
        )
    }
}