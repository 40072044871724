// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20019,
    SubjectRead,
    SubjectWrite,
} from '../models';

export interface DeleteSubjectItemRequest {
    id: string;
}

export interface GetSubjectCollectionRequest {
    id?: Array<string>;
    name?: string;
    category?: Array<string>;
    active?: boolean;
    orderName?: GetSubjectCollectionOrderNameEnum;
    orderActive?: GetSubjectCollectionOrderActiveEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetSubjectItemRequest {
    id: string;
}

export interface PostSubjectCollectionRequest {
    subjectWrite?: SubjectWrite;
}

export interface PutSubjectItemRequest {
    id: string;
    subjectWrite?: SubjectWrite;
}

/**
 * no description
 */
export class SubjectApi extends BaseAPI {

    /**
     * Removes the Subject resource.
     */
    deleteSubjectItem({ id }: DeleteSubjectItemRequest): Observable<void>
    deleteSubjectItem({ id }: DeleteSubjectItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteSubjectItem({ id }: DeleteSubjectItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteSubjectItem');

        return this.request<void>({
            url: '/api/subjects/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of Subject resources.
     */
    getSubjectCollection({ id, name, category, active, orderName, orderActive, page, itemsPerPage }: GetSubjectCollectionRequest): Observable<InlineResponse20019>
    getSubjectCollection({ id, name, category, active, orderName, orderActive, page, itemsPerPage }: GetSubjectCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20019>>
    getSubjectCollection({ id, name, category, active, orderName, orderActive, page, itemsPerPage }: GetSubjectCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20019 | RawAjaxResponse<InlineResponse20019>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (name != null) { query['name'] = name; }
        if (category != null) { query['category[]'] = category; }
        if (active != null) { query['active'] = active; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20019>({
            url: '/api/subjects',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Subject resource.
     */
    getSubjectItem({ id }: GetSubjectItemRequest): Observable<SubjectRead>
    getSubjectItem({ id }: GetSubjectItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SubjectRead>>
    getSubjectItem({ id }: GetSubjectItemRequest, opts?: OperationOpts): Observable<SubjectRead | RawAjaxResponse<SubjectRead>> {
        throwIfNullOrUndefined(id, 'id', 'getSubjectItem');

        return this.request<SubjectRead>({
            url: '/api/subjects/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a Subject resource.
     */
    postSubjectCollection({ subjectWrite }: PostSubjectCollectionRequest): Observable<SubjectRead>
    postSubjectCollection({ subjectWrite }: PostSubjectCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SubjectRead>>
    postSubjectCollection({ subjectWrite }: PostSubjectCollectionRequest, opts?: OperationOpts): Observable<SubjectRead | RawAjaxResponse<SubjectRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<SubjectRead>({
            url: '/api/subjects',
            method: 'POST',
            headers,
            body: subjectWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the Subject resource.
     */
    putSubjectItem({ id, subjectWrite }: PutSubjectItemRequest): Observable<SubjectRead>
    putSubjectItem({ id, subjectWrite }: PutSubjectItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SubjectRead>>
    putSubjectItem({ id, subjectWrite }: PutSubjectItemRequest, opts?: OperationOpts): Observable<SubjectRead | RawAjaxResponse<SubjectRead>> {
        throwIfNullOrUndefined(id, 'id', 'putSubjectItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<SubjectRead>({
            url: '/api/subjects/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: subjectWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetSubjectCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetSubjectCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
