import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import {State} from 'lib/store'
import {EnumValue, getEnums} from "lib/enums";
import {Typography} from "@material-ui/core";

type StateToProps = {
    readonly enums: EnumValue[]
}

type EnumProps = {
    name: string
    id: string
    valueOnly?: boolean
}

class EnumComponent extends React.Component<EnumProps & StateToProps> {
    public render() {
        const enums = this.props.enums
            ? this.props.enums.filter((enumValue) => enumValue.id === this.props.id)
            : []

        if (this.props.valueOnly) {
            return enums.length === 1 ? enums[0].value : ''
        }

        return <Typography>{ enums.length === 1 ? enums[0].value : '' }</Typography>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, EnumProps, State> = (state, ownProps) => ({
    enums: getEnums(ownProps.name)(state),
})

export const Enum = connect(mapStateToProps)(EnumComponent)