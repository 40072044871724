import {Box, Checkbox, Radio, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    index: number
    section: WodTestSection
    preview?: boolean
    prev: null|number
    next: null|number
}

type SectionState = {
    section: WodTestSection
}

export type WodTestSection = {
    section: string
    categories: Category[]
}

interface TestItem {
    name: string
    score: number|null
    required: boolean
}

interface Category {
    items: TestItem[]
    groups: string[]
    category: string
}

export class WodSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let keys = event.target.name.split('|')
        this.state.section.categories[parseInt(keys[0])].items[parseInt(keys[1])].score = event.target.checked ? parseInt(keys[2]) : 0;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => <Box style={this.props.preview ? {margin: '0px 0px 50px 0px'} : {}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}><Typography variant={"h4"}>{this.props.section.section}</Typography></TableCell>
                    <TableCell align={'center'} style={{width: '150px'}}>Jeszcze nie</TableCell>
                    <TableCell align={'center'} style={{width: '150px'}}>Czasem</TableCell>
                    <TableCell align={'center'} style={{width: '150px'}}>Zawsze</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {this.state.section.categories.map(
                    (category, categoryIndex) =>
                    <>
                        <TableRow key={categoryIndex} >
                            <TableCell colSpan={5}><Typography variant={"h6"}>{category.category}</Typography></TableCell>
                        </TableRow>
                        {category.items.map(
                            (item, itemIndex) =>
                                <TableRow key={itemIndex}>
                                    <TableCell align={'right'}
                                               style={{width: '50px'}}>{itemIndex + 1}.</TableCell>
                                    <TableCell>
                                        {this.props.preview && item.required && itemIndex == 0 ?
                                            <Box style={{color: 'red'}}>{item.name}</Box> : item.name}
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <Checkbox
                                            name={categoryIndex + '|' + itemIndex + '|0'}
                                            checked={item.score == 0}
                                            onChange={this.handleChange}
                                            disabled={this.props.preview || false}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <Checkbox
                                            name={categoryIndex + '|' + itemIndex + '|1'}
                                            checked={item.score == 1}
                                            onChange={this.handleChange}
                                            disabled={this.props.preview || false}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <Checkbox
                                            name={categoryIndex + '|' + itemIndex + '|2'}
                                            checked={item.score == 2}
                                            onChange={this.handleChange}
                                            disabled={this.props.preview || false}
                                            color="primary"
                                        />
                                    </TableCell>
                                </TableRow>
                        )}
                    </>,
                    this
                )}
            </TableBody>
        </Table>
    </Box>
}