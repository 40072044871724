import {Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    index: number
    section: ClameisrTestSection
    preview?: boolean
    prev: null|number
    next: null|number
}

type SectionState = {
    section: ClameisrTestSection
}

export type ClameisrTestSection = {
    section: string
    items: TestItem[]
}

type TestItem = {
    name: string
    age: number
    groups: any
    value: number
    required: number
}

export class ClameisrSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let keys = event.target.name.split('|')
        this.state.section.items[parseInt(keys[0])].value = event.target.checked ? parseInt(keys[1]) : 0;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => <Box style={this.props.preview ? {margin: '0px 0px 50px 0px'} : {}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align={'right'}
                               style={{width: '50px'}}>{this.props.index + 1}.</TableCell>
                    <TableCell>{this.state.section.section}</TableCell>
                    <TableCell align={'center'}>Wiek</TableCell>
                    <TableCell align={'center'} style={{width: '150px'}}>Jeszcze nie</TableCell>
                    <TableCell align={'center'} style={{width: '150px'}}>Czasem</TableCell>
                    <TableCell align={'center'} style={{width: '150px'}}>Często / Bardzo często</TableCell>
                    <TableCell align={'center'} style={{width: '50px'}}>A</TableCell>
                    <TableCell align={'center'} style={{width: '50px'}}>B</TableCell>
                    <TableCell align={'center'} style={{width: '50px'}}>C</TableCell>
                    <TableCell align={'center'} style={{width: '50px'}}>D</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {this.state.section.items.map(
                    (item, index) => <TableRow key={index}>
                        <TableCell align={'right'}
                                   style={{width: '50px'}}>{this.props.index + 1}.{index + 1}.</TableCell>
                        <TableCell>
                            {this.props.preview && item.required && item.value == 0 ?
                                <Box style={{color: 'red'}}>{item.name}</Box> : item.name}
                        </TableCell>
                        <TableCell align={'center'}>{item.age}</TableCell>
                        <TableCell align={'center'}>
                            <Checkbox
                                name={index + '|1'}
                                checked={item.value == 1}
                                onChange={this.handleChange}
                                disabled={this.props.preview || false}
                                color="primary"
                            />
                        </TableCell>
                        <TableCell align={'center'}>
                            <Checkbox
                                name={index + '|2'}
                                checked={item.value == 2}
                                onChange={this.handleChange}
                                disabled={this.props.preview || false}
                                color="primary"
                            />
                        </TableCell>
                        <TableCell align={'center'}>
                            <Checkbox
                                name={index + '|3'}
                                checked={item.value == 3}
                                onChange={this.handleChange}
                                disabled={this.props.preview || false}
                                color="primary"
                            />
                        </TableCell>
                        <TableCell align={'center'} padding={'none'}>{item.groups.a.join(', ')}</TableCell>
                        <TableCell align={'center'} padding={'none'}>{item.groups.b.join(', ')}</TableCell>
                        <TableCell align={'center'} padding={'none'}>{item.groups.c.join(', ')}</TableCell>
                        <TableCell align={'center'} padding={'none'}>{item.groups.d.join(', ')}</TableCell>
                    </TableRow>,
                    this
                )}
            </TableBody>
        </Table>
    </Box>
}