import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export const NavigationActionType = {
    setHeader: (): string => `[UI] Set header`,
    setSubheader: (): string => `[UI] Set subheader`,
    setAction: (): string => `[UI] Set action`,
}

export const NavigationAction = {
    setHeader: (header: string) => createAction(NavigationActionType.setHeader(), header),
    setSubheader: (subheader: string) => createAction(NavigationActionType.setSubheader(), subheader),
    setAction: (action: JSX.Element) => createAction(NavigationActionType.setAction(), action),
}

export type NavigationAction = ActionsUnion<typeof NavigationAction>