import {ListItemIcon, MenuItem} from '@material-ui/core'
import EditIcon from '@material-ui/icons/EditOutlined'
import * as React from 'react'
import {NavLink} from 'react-router-dom'

type EditMenuItemProps = {
    to: string
}

export class EditMenuItem extends React.Component<EditMenuItemProps> {
    public render = () =>
        <MenuItem
            selected={false}
            component={NavLink}
            to={this.props.to}
        >
            <ListItemIcon>
                <EditIcon/>
            </ListItemIcon>
            Edycja
        </MenuItem>
}
