// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    EducationStatusRead,
    InlineResponse2002,
} from '../models';

export interface GetEducationStatusCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetEducationStatusItemRequest {
    id: string;
}

/**
 * no description
 */
export class EducationStatusApi extends BaseAPI {

    /**
     * Retrieves the collection of EducationStatus resources.
     */
    getEducationStatusCollection({ page, itemsPerPage }: GetEducationStatusCollectionRequest): Observable<InlineResponse2002>
    getEducationStatusCollection({ page, itemsPerPage }: GetEducationStatusCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2002>>
    getEducationStatusCollection({ page, itemsPerPage }: GetEducationStatusCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2002 | RawAjaxResponse<InlineResponse2002>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2002>({
            url: '/api/education-statuses',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a EducationStatus resource.
     */
    getEducationStatusItem({ id }: GetEducationStatusItemRequest): Observable<EducationStatusRead>
    getEducationStatusItem({ id }: GetEducationStatusItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<EducationStatusRead>>
    getEducationStatusItem({ id }: GetEducationStatusItemRequest, opts?: OperationOpts): Observable<EducationStatusRead | RawAjaxResponse<EducationStatusRead>> {
        throwIfNullOrUndefined(id, 'id', 'getEducationStatusItem');

        return this.request<EducationStatusRead>({
            url: '/api/education-statuses/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
