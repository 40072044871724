import {CrudList, UserLabel} from 'app/Components'
import {Tabs} from 'app/Views/Students/Tabs/Tabs'
import {rules} from 'lib/auth'
import {CrudAction, emptyFilterId, getRow, Type} from 'lib/crud'
import {name} from 'lib/parents'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'

type StateToProps = {
    readonly student: Student
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
}

type Params = {
    parentId: string
}

const cols = [
    {
        id: "name",
        numeric: false,
        label: "Nazwisko i imię",
        sort: false,
        template: (parent: User) => <UserLabel user={parent}/>
    },
    {
        id: "email",
        numeric: false,
        label: "Email",
        sort: false,
        width: '30%',
        template: (parent: User) => (parent.email)
    },
    {
        id: "phone",
        numeric: false,
        label: "Telefon",
        sort: false,
        width: '30%',
        template: (parent: User) => (parent.phone)
    },
]

class ParentsComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
    }

    public render = () => (<>
        {this.props.student.id !== undefined ? <CrudList
            actionName={name}
            parentActionName={studentsName}
            cols={cols}
            deleteDialogText={"Usunąć rodzica?"}
            selector={(state: State) => state.parents}
            rules={rules.studentParents}
            filter={{
                initialValues: {
                    id: this.props.student.id !== undefined && this.props.student.parents.length > 0
                        ? this.props.student.parents
                        : [emptyFilterId]
                },
                fields: [],
            }}
            tabs={<Tabs id={this.props.student.id || ' '} />}
        /> : null}
    </>
    )
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(ParentsComponent))