import {configuration} from 'lib/client'
import {GroupClassApi} from 'client/apis'

const GroupClassApiClient = (): GroupClassApi => {
    return new GroupClassApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return GroupClassApiClient().getGroupClassCollection(payload)
    },
    get: (payload: any) => {
        return GroupClassApiClient().getGroupClassItem({id: payload.id})
    },
    create: (payload: any) => {
        return GroupClassApiClient().postGroupClassCollection({groupClassWrite: payload})
    },
    update: (payload: any) => {
        return GroupClassApiClient().putGroupClassItem({id: payload.id, groupClassWrite: payload.data})
    },
    delete: (payload: any) => {
        return GroupClassApiClient().deleteGroupClassItem({id: payload.id})
    }
}