import {
    Checkbox,
    FormControlLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from '@material-ui/core'
import * as React from 'react'
import {LFWTestSection} from "../LFWSection";

type Q4Props = {
    section: LFWTestSection
    preview: boolean
}

type Q4State = {
    section: LFWTestSection
}


export class Q4 extends React.Component<Q4Props, Q4State> {
    state: Q4State = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.section.response.hasOwnProperty(event.target.name)) {
            this.state.section.response[event.target.name] = event.target.checked
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.value;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'światło'}
                                        checked={this.state.section.response['światło']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'światło'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'duże kolorowe obiekty'}
                                        checked={this.state.section.response['duże kolorowe obiekty']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'duże kolorowe obiekty'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'błyszczące przedmioty'}
                                        checked={this.state.section.response['błyszczące przedmioty']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'błyszczące przedmioty'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'osoby (sylwetkę, postać)'}
                                        checked={this.state.section.response['osoby (sylwetkę, postać)']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'osoby (sylwetkę, postać)'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'twarze'}
                                        checked={this.state.section.response['twarze']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'twarze'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Grid container direction="row"
                                  justify="flex-start"
                                  alignItems="center" spacing={3}>
                                <Grid item>
                                    inne
                                </Grid>
                                <Grid item sm>
                                    <TextField
                                        name="inne"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 250,
                                        }}
                                        value={this.state.section.response['inne']}
                                        onChange={this.handleTextChange}
                                        multiline
                                        fullWidth
                                        disabled={this.props.preview}
                                    />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}