import {CircularProgressBar, CrudShowHeader} from 'app/Components'
import {getRow} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {State} from 'lib/store'
import {name, Subject} from 'lib/subjects'
import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'

type StateToProps = {
    readonly isLoading: boolean
    readonly subject: Subject
}

type ShowProps = StateToProps

class ShowComponent extends React.Component<ShowProps> {
    public render = () => (<>
        <CrudShowHeader actionName={name}/>

        {this.props.isLoading
            ? <CircularProgressBar/>
            : <>Zajęcia: {this.props.subject.name}</>
        }
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    subject: getRow(state => state.subjects)(state),
})

export const Show = connect(mapStateToProps)(ShowComponent)