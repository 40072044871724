import {
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from '@material-ui/icons/EditOutlined';
import {DeleteMenuItem, Enum, Menu, Preview, SimpleDialog, SubjectPreview, UserPreview} from 'app/Components'
import {Tabs} from 'app/Views/Students/Tabs/Tabs'
import {generateDialogName} from 'external-lib/utils/helpers'
import {name as classDurationName} from "lib/classDuration";
import {CrudAction, getRow, RouteType, Type} from 'lib/crud'
import {DialogAction} from 'lib/dialog'
import {GroupClass} from "lib/groupClass";
import {Group} from 'lib/groups'
import {IndividualClass, name} from 'lib/individualClass'
import {generateCrudPath} from 'lib/router'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {name as studentsName, Student} from "lib/students";
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {NavLink, RouteComponentProps} from 'react-router-dom'
import {me, Role} from 'lib/auth'
import {User} from 'lib/users'

type StateToProps = {
    readonly student: Student
    readonly me: User
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
    readonly openDeleteDialog: (actionName: string) => void,
    readonly delete: (id: string, actionName: string) => void,
}

type Params = {
    parentId: string
}

type ListState = {
    id: string
}

class SubjectsComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>, ListState> {
    state: {
        id: ''
    }

    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
    }

    private getParams = () => {
        return {parentId: this.props.match.params.parentId}
    }

    public render = () => {
        const handleDeleteDialogOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
            this.setState(prevState => {
                return {...prevState, id}
            })
            this.props.openDeleteDialog(name)
        }

        const handleDelete = () => {
            this.props.delete(this.state.id, name)
        }

        const editButton = (row: any) => <Button size="small"
                                                 component={NavLink}
                                                 color={'primary'}
                                                 to={generateCrudPath(name, RouteType.EDIT, {
                                                     ...this.getParams(),
                                                     id: row.id
                                                 })}
                                                 startIcon={<EditIcon/>}>Edytuj</Button>

        const deleteButton = (row: any) => <Button size="small"
                                                   color={'primary'}
                                                   variant="contained"
                                                   onClick={(event) => handleDeleteDialogOpen(event, row.id)}
                                                   startIcon={<DeleteIcon/>}>Usuń</Button>

        const getActionButton = (row: any) => {
            if (canEdit(row)) {
                return editButton(row)
            }

            if (canDelete(row)) {
                return deleteButton(row)
            }

            return null
        }

        const actions = (row: any) => {
            let menuActions = []
            if (canEdit(row) && canDelete(row)) {
                menuActions.push(<DeleteMenuItem onClickHandler={(event) => handleDeleteDialogOpen(event, row.id)}/>)
            }

            return <Menu
                id={row.id}
                button={getActionButton(row)}
                actions={menuActions}
                showSubmenu={canEdit(row) && canDelete(row)}
            />
        }

        const canEdit = (row: any): boolean => {
            return row.metadata !== undefined
                ? row.metadata.canEdit
                : false
        }

        const canDelete = (row: any): boolean => {
            return row.metadata !== undefined
                ? row.metadata.canDelete
                : false
        }

        return <>
            {this.props.student.id !== undefined ? <>
                <Tabs id={this.props.student.id || ' '}/>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <Typography variant={"h4"}>Zajęcia indywidualne</Typography>
                            </Grid>
                            {this.props.me.roles.includes(Role.ROLE_PARENT) ? <></> :
                                <Grid alignContent={"flex-end"} item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon/>}
                                        component={NavLink}
                                        to={generateCrudPath(name, RouteType.ADD, {parentId: this.props.match.params.parentId})}
                                    >
                                        {'Dodaj'}
                                    </Button>
                                </Grid>}

                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="td" style={{width: "35%"}}>Zajęcia</TableCell>
                                                <TableCell component="td" style={{width: "35%"}}>Nauczyciel</TableCell>
                                                <TableCell component="td" style={{width: "10%"}}>Czas
                                                    trwania</TableCell>
                                                <TableCell component="td" style={{width: "10%"}}>Ilość w
                                                    tygodniu</TableCell>
                                                <TableCell component="td" style={{width: "10%"}}/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={'gray-striped'}>
                                            {this.props.student.individualClasses?.length ?
                                                <Preview
                                                    value={this.props.student.individualClasses}
                                                    source={Source.INDIVIDUAL_CLASSES}
                                                    tableRow
                                                    colSpan={4}
                                                    template={(individualClass: IndividualClass) => <TableRow>
                                                        <TableCell>
                                                            <SubjectPreview id={individualClass.subject}/>
                                                        </TableCell>
                                                        <TableCell component="td">
                                                            <UserPreview id={individualClass.teacher}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Enum name={classDurationName}
                                                                  id={individualClass.duration}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            {individualClass.weeklySessions}
                                                        </TableCell>
                                                        <TableCell>
                                                            {actions(individualClass)}
                                                        </TableCell>
                                                    </TableRow>}
                                                />
                                                :
                                                <TableCell align={"center"} component="td" colSpan={5}>Brak przypisanych
                                                    zajęć indywidualnych</TableCell>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 30}}>
                                <Typography variant={"h4"}>Zajęcia grupowe</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="td" style={{width: "35%"}}>Zajęcia</TableCell>
                                                <TableCell component="td" style={{width: "35%"}}>Nauczyciel</TableCell>
                                                <TableCell component="td" style={{width: "10%"}}>Czas
                                                    trwania</TableCell>
                                                <TableCell component="td" style={{width: "10%"}}>Ilość w
                                                    tygodniu</TableCell>
                                                <TableCell component="td" style={{width: "10%"}}/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={'gray-striped'}>
                                            <Preview
                                                value={this.props.student.groups}
                                                source={Source.GROUPS}
                                                tableRow
                                                colSpan={4}
                                                template={(group: Group) =>
                                                    <>
                                                        {group.classes?.length ?
                                                            <Preview
                                                                value={group.classes}
                                                                source={Source.GROUP_CLASSES}
                                                                tableRow
                                                                colSpan={4}
                                                                template={(groupClass: GroupClass) => <TableRow>
                                                                    <TableCell>
                                                                        <SubjectPreview id={groupClass.subject}/>
                                                                    </TableCell>
                                                                    <TableCell component="td">
                                                                        <UserPreview id={groupClass.teacher}/>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Enum name={classDurationName}
                                                                              id={groupClass.duration}/>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {groupClass.weeklySessions}
                                                                    </TableCell>
                                                                    <TableCell/>
                                                                </TableRow>}
                                                            />
                                                            : ''}
                                                    </>}
                                            />
                                            {this.props.student.groups === null ?
                                                <TableCell align={"center"} component="td" colSpan={3}>Brak przypisanych
                                                    zajęć grupowych</TableCell> : ''}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </> : null}

            <SimpleDialog
                id={generateDialogName(name, Type.DELETE)}
                title={'Potwierdź usunięcie'}
                text={'Usunąć zajęcia indywidualne?'}
                confirmLabel={'Usuń'}
                handleConfirm={handleDelete}
            />
        </>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    me: me(state)!
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    openDeleteDialog: (actionName: string) => {
        dispatch(DialogAction.open(generateDialogName(actionName, Type.DELETE)))
    },
    delete: (id: string, actionName: string) => {
        dispatch(CrudAction.execute(actionName, Type.DELETE, {id}))
    }
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(SubjectsComponent))