import {createMuiTheme} from "@material-ui/core/styles"
import {Variant, variants} from "./variants"
import {breakpoints} from "./breakpoints"
import {overrides} from "./overrides"
import {props} from "./props"
import {typography} from "./typography"
import {shadows} from "./shadows"

const theme = (variant: Variant) => {
    return createMuiTheme(
        {
            spacing: 4,
            breakpoints: breakpoints,
            overrides: overrides,
            props: props,
            typography: typography,
            shadows: shadows,
            body: variant.body,
            header: variant.header,
            palette: variant.palette,
            sidebar: variant.sidebar
        },
        variant.name
    )
}

export const themes = variants.map(variant => theme(variant))

export * from './theme'