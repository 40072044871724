import {
    Checkbox,
    FormControlLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from '@material-ui/core'
import * as React from 'react'
import {LFWTestSection} from "../LFWSection";

type Q12Props = {
    section: LFWTestSection
    preview: boolean
}

type Q12State = {
    section: LFWTestSection
}


export class Q12 extends React.Component<Q12Props, Q12State> {
    state: Q12State = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.section.response.hasOwnProperty(event.target.name)) {
            this.state.section.response[event.target.name] = event.target.checked
        }

        if (event.target.name == 'nie') {
            this.state.section.response['z lewej/prawej strony'] = false
            this.state.section.response['u góry/na dole'] = false
            this.state.section.response['na wprost'] = false
        }

        if (event.target.name != 'nie') {
            this.state.section.response['nie'] = false
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.value;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'nie'}
                                        checked={this.state.section.response['nie']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'nie'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'z lewej/prawej strony'}
                                        checked={this.state.section.response['z lewej/prawej strony']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tak: z lewej/prawej strony'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'u góry/na dole'}
                                        checked={this.state.section.response['u góry/na dole']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tak: u góry/na dole'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'na wprost'}
                                        checked={this.state.section.response['na wprost']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tak: na wprost'}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}