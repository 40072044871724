import {Preview, SubjectLabel} from 'app/Components'
import {Source} from 'lib/select'
import {Subject} from 'lib/subjects'
import * as React from 'react'

type SubjectPreviewProps = {
    id: string,
}

export class SubjectPreview extends React.Component<SubjectPreviewProps> {
    public render = () => <Preview
        value={this.props.id}
        source={Source.SUBJECTS}
        template={(subject: Subject) => <SubjectLabel subject={subject} />}
    />
}
