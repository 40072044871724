import {configuration} from 'lib/client'
import {TestConfigApi} from 'client/apis'
import {of} from 'rxjs'
import {TestConfig} from "./dto";

const TestConfigApiClient = (): TestConfigApi => {
    return new TestConfigApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return TestConfigApiClient().getTestConfigCollection(payload)
    },
    get: (payload: any) => {
        return TestConfigApiClient().getTestConfigItem({id: payload.id})
    },
    update: (payload: any) => {
        return TestConfigApiClient().putTestConfigItem({id: payload.id, testConfigWrite: payload.data})
    },
    listForAnalysis: (payload: any) => {
        return of(<TestConfig[]>[
            {id: '532028fb-af1b-4916-bf33-b3cff6cc3fc0', name: 'ClaMEISR'},
            {id: '425e5e83-bdd2-447b-b2f9-4c767b1089ed', name: 'STARE'},
            {id: '617e1094-e8b2-4b89-a43d-509d71b5ecce', name: 'Wielospecjalistyczna Ocena Dziecka 6-letniego'},
        ])
    },
}