// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    GroupClassRead,
    GroupClassWrite,
    InlineResponse2004,
} from '../models';

export interface DeleteGroupClassItemRequest {
    id: string;
}

export interface GetGroupClassCollectionRequest {
    id?: Array<string>;
    groupId?: Array<string>;
    orderTeacherFirstName?: GetGroupClassCollectionOrderTeacherFirstNameEnum;
    orderSubjectName?: GetGroupClassCollectionOrderSubjectNameEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetGroupClassItemRequest {
    id: string;
}

export interface PostGroupClassCollectionRequest {
    groupClassWrite?: GroupClassWrite;
}

export interface PutGroupClassItemRequest {
    id: string;
    groupClassWrite?: GroupClassWrite;
}

/**
 * no description
 */
export class GroupClassApi extends BaseAPI {

    /**
     * Removes the GroupClass resource.
     */
    deleteGroupClassItem({ id }: DeleteGroupClassItemRequest): Observable<void>
    deleteGroupClassItem({ id }: DeleteGroupClassItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteGroupClassItem({ id }: DeleteGroupClassItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteGroupClassItem');

        return this.request<void>({
            url: '/api/group-classes/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of GroupClass resources.
     */
    getGroupClassCollection({ id, groupId, orderTeacherFirstName, orderSubjectName, page, itemsPerPage }: GetGroupClassCollectionRequest): Observable<InlineResponse2004>
    getGroupClassCollection({ id, groupId, orderTeacherFirstName, orderSubjectName, page, itemsPerPage }: GetGroupClassCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2004>>
    getGroupClassCollection({ id, groupId, orderTeacherFirstName, orderSubjectName, page, itemsPerPage }: GetGroupClassCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2004 | RawAjaxResponse<InlineResponse2004>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (groupId != null) { query['group.id[]'] = groupId; }
        if (orderTeacherFirstName != null) { query['order[teacher.firstName]'] = orderTeacherFirstName; }
        if (orderSubjectName != null) { query['order[subject.name]'] = orderSubjectName; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2004>({
            url: '/api/group-classes',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a GroupClass resource.
     */
    getGroupClassItem({ id }: GetGroupClassItemRequest): Observable<GroupClassRead>
    getGroupClassItem({ id }: GetGroupClassItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<GroupClassRead>>
    getGroupClassItem({ id }: GetGroupClassItemRequest, opts?: OperationOpts): Observable<GroupClassRead | RawAjaxResponse<GroupClassRead>> {
        throwIfNullOrUndefined(id, 'id', 'getGroupClassItem');

        return this.request<GroupClassRead>({
            url: '/api/group-classes/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a GroupClass resource.
     */
    postGroupClassCollection({ groupClassWrite }: PostGroupClassCollectionRequest): Observable<GroupClassRead>
    postGroupClassCollection({ groupClassWrite }: PostGroupClassCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<GroupClassRead>>
    postGroupClassCollection({ groupClassWrite }: PostGroupClassCollectionRequest, opts?: OperationOpts): Observable<GroupClassRead | RawAjaxResponse<GroupClassRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<GroupClassRead>({
            url: '/api/group-classes',
            method: 'POST',
            headers,
            body: groupClassWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the GroupClass resource.
     */
    putGroupClassItem({ id, groupClassWrite }: PutGroupClassItemRequest): Observable<GroupClassRead>
    putGroupClassItem({ id, groupClassWrite }: PutGroupClassItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<GroupClassRead>>
    putGroupClassItem({ id, groupClassWrite }: PutGroupClassItemRequest, opts?: OperationOpts): Observable<GroupClassRead | RawAjaxResponse<GroupClassRead>> {
        throwIfNullOrUndefined(id, 'id', 'putGroupClassItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<GroupClassRead>({
            url: '/api/group-classes/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: groupClassWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetGroupClassCollectionOrderTeacherFirstNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetGroupClassCollectionOrderSubjectNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
