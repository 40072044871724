import {Hidden, Paper as MuiPaper} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import {Notification} from 'app/Components/Notification'
import {Footer, Header, Sidebar} from 'app/Layout'
import {Router} from 'app/Router'
import {AuthAction, isInitialized} from "lib/auth";
import {State} from 'lib/store'
import {selectTheme} from 'lib/theme'
import {Theme} from 'lib/theme/variants'
import React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Route, Switch} from "react-router";
import styled from 'styled-components'
import {RouteType} from 'lib/crud'
import {generateRouterCrudPath, ProtectedRoutes} from 'lib/router'
import {testName} from 'lib/test'
import {PreviewFooter} from "./PreviewFooter";
import {TestMenu} from './Sidebar/TestMenu'
import 'external-lib/perfect-scrollbar/perfect-scrollbar.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {TestFooter} from "./TestFooter";

const drawerWidth = 260

const Scrollbar = styled(PerfectScrollbar)`
  height: 100vh;
  padding: 0 10px 10px;
`

type ThemeProps = {
    readonly theme: Theme,
}

const Drawer = styled.div<ThemeProps>`
  ${props => props.theme.breakpoints.up("lg")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`

const LayoutContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

type StateToProps = {
    readonly theme: Theme
    readonly isAuthInitialized: boolean
}

type DispatchToProps = {
    readonly initialize: () => void,
}

type LayoutProps = DispatchToProps & StateToProps

type LayoutState = {
    mobileOpen: boolean
    testOpen: boolean
}

class LayoutComponent extends React.Component<LayoutProps, LayoutState> {
    state: LayoutState = {
        mobileOpen: false,
        testOpen: false
    }

    public componentDidMount(): void {
        if (!this.props.isAuthInitialized) {
            this.props.initialize()
        }
    }

    public render() {

        return (
            <>
                <Drawer theme={this.props.theme}>
                    <Hidden lgUp implementation="js">
                        <Sidebar
                            PaperProps={{style: {width: drawerWidth}}}
                            variant="temporary"
                            open={this.state.mobileOpen}
                            onClose={this.toggleMobileOpen}
                        />
                    </Hidden>
                    <Hidden mdDown implementation="css">
                        <Sidebar
                            variant="permanent"
                            PaperProps={{style: {width: drawerWidth}}}
                        />
                    </Hidden>
                </Drawer>

                <Switch>
                    <Route path={generateRouterCrudPath(testName, RouteType.EDIT)}>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', height: '100vh'}}>
                            <Header
                                onDrawerToggle={this.toggleMobileOpen}
                                onTestMenuToggle={this.toggleTestOpen}
                            />
                            <Scrollbar>
                                <Router/>
                            </Scrollbar>
                            <TestFooter/>
                        </div>
                        <div hidden={!this.state.testOpen}>
                            <TestMenu />
                        </div>
                    </Route>
                    <Route path={ProtectedRoutes.TestPreview}>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', height: '100vh'}}>
                            <Header
                                onDrawerToggle={this.toggleMobileOpen}
                                onTestMenuToggle={this.toggleTestOpen}
                            />
                            <Scrollbar>
                                <Router/>
                            </Scrollbar>
                            <PreviewFooter/>
                        </div>
                    </Route>
                    <Route path={'/'}>
                        <LayoutContent>
                            <Header onDrawerToggle={this.toggleMobileOpen}/>
                            <MainContent p={5}>
                                <Router/>
                            </MainContent>
                            <Footer/>
                        </LayoutContent>
                    </Route>
                </Switch>

                <Notification/>
            </>
        )
    }

    private toggleMobileOpen = () => {
        this.setState(prevState => ({...prevState, mobileOpen: !prevState.mobileOpen}))
    }

    private toggleTestOpen = () => {
        this.setState(prevState => ({...prevState, testOpen: !prevState.testOpen}))
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    theme: selectTheme(state),
    isAuthInitialized: isInitialized(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    initialize: () => {
        dispatch(AuthAction.initialize())
    },
})

export const Layout = connect(mapStateToProps, mapDispatchToProps)(LayoutComponent)