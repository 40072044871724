import {Metadata} from 'lib/dto'

export type Test = {
    id: string
    config: string
    content?: { data: any }
    student: string
    publishedAt: string
    publishedBy: string
    teacher: string
    published: boolean
    scheduledAt?: string
    status?: string
    metadata?: Metadata
}

export const newTest = (studentId: string, teacherId: string): Test => ({
    id: '',
    config: '',
    student: studentId,
    publishedAt: '',
    publishedBy: '',
    teacher: teacherId,
    published: false
})
