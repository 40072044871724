import {authReducer} from 'lib/auth'
import {classDurationReducer} from 'lib/classDuration'
import {dialogReducer} from 'lib/dialog'
import {dictionariesReducer} from 'lib/dictionaries'
import {enumReducer} from 'lib/enums'
import {groupChildrenReducer} from 'lib/groupChildren'
import {groupClassReducer} from 'lib/groupClass'
import {groupReducer} from 'lib/groups'
import {groupTeachersReducer} from 'lib/groupTeacher'
import {historyEntryReducer} from 'lib/historyEntry'
import {individualClassReducer} from 'lib/individualClass'
import {navigationReducer} from 'lib/navigation'
import {notificationsReducer} from 'lib/notifications'
import {parentsReducer} from 'lib/parents'
import {previewReducer} from 'lib/preview'
import {progressReducer} from 'lib/progress'
import {reloadReducer} from 'lib/reload'
import {rolesReducer} from 'lib/roles'
import {history, routerReducer} from 'lib/router'
import {schoolsReducer} from 'lib/schools'
import {schoolTypeReducer} from 'lib/schoolType'
import {selectReducer} from 'lib/select'
import {specialistsReducer} from 'lib/specialists'
import {studentCategoryReducer} from 'lib/studentCategory'
import {studentFileReducer} from 'lib/studentFiles'
import {studentFileTypeReducer} from 'lib/studentFileTypes'
import {studentReducer} from 'lib/students'
import {studentSubjectsReducer} from 'lib/studentSubject'
import {studentSummaryReducer} from 'lib/studentSummary'
import {studentTeachersReducer} from 'lib/studentTeacher'
import {subjectReducer} from 'lib/subjects'
import {subjectCategoryReducer} from 'lib/subjectsCategory'
import {tabsReducer} from 'lib/tabs'
import {testReducer} from 'lib/test'
import {testConfigReducer} from 'lib/testConfig'
import {themeReducer} from 'lib/theme'
import {usersReducer} from 'lib/users'
import {combineReducers} from 'redux'
import {State} from '.'

export const rootReducer = combineReducers<State>({
    auth: authReducer,
    theme: themeReducer,
    notifications: notificationsReducer,
    dictionaries: dictionariesReducer,
    progress: progressReducer,
    dialog: dialogReducer,
    users: usersReducer,
    schools: schoolsReducer,
    select: selectReducer,
    subjects: subjectReducer,
    subjectsCategory: subjectCategoryReducer,
    roles: rolesReducer,
    router: routerReducer(history),
    enums: enumReducer,
    groups: groupReducer,
    preview: previewReducer,
    students: studentReducer,
    studentsCategory: studentCategoryReducer,
    tabs: tabsReducer,
    groupClass: groupClassReducer,
    classDuration: classDurationReducer,
    historyEntry: historyEntryReducer,
    schoolType: schoolTypeReducer,
    individualClass: individualClassReducer,
    parents: parentsReducer,
    teachers: groupTeachersReducer,
    children: groupChildrenReducer,
    specialists: specialistsReducer,
    studentTeachers: studentTeachersReducer,
    studentSubjects: studentSubjectsReducer,
    studentFiles: studentFileReducer,
    studentFileTypes: studentFileTypeReducer,
    testConfig: testConfigReducer,
    navigation: navigationReducer,
    tests: testReducer,
    reload: reloadReducer,
    studentSummary: studentSummaryReducer,
})