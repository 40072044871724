import {AnyAction} from 'redux'
import {Option, SelectActionType} from 'lib/select/index'
import {transformResponse} from "./transformer";

export type SelectState = {
    [key: string]: {
        selected: Option[],
        options: Option[],
        loading: number
    }
}

const initialState = {
    selected: [],
    options: [],
    loading: 0,
}

export function selectReducer(state: SelectState = {}, action: AnyAction): SelectState {
    if (action.type.startsWith('[SELECT] load options success') && state[action.payload.name]) {
        return {
            ...state,
            [action.payload.name]: {
                ...state[action.payload.name],
                loading: state[action.payload.name].loading - 1,
                options: [...state[action.payload.name].options].concat(transformResponse(action.payload.source, action.payload.response))
            }
        }
    }
    if (action.type.startsWith('[SELECT] load options next') && state[action.payload.name]) {
        return {
            ...state,
            [action.payload.name]: {
                ...state[action.payload.name],
                options: [...state[action.payload.name].options].concat(transformResponse(action.payload.source, action.payload.response))
            }
        }
    }

    if (action.type.startsWith('[SELECT] load selected success') && state[action.payload.name]) {
        return {
            ...state,
            [action.payload.name]: {
                ...state[action.payload.name],
                loading: state[action.payload.name].loading - 1,
                selected: transformResponse(action.payload.source, action.payload.response),
            }
        }
    }

    if ((action.type.startsWith('[SELECT] load options failed') || action.type.startsWith('[SELECT] load selected failed')) && state[action.payload.name]) {
        return {
            ...state,
            [action.payload.name]: {
                ...state[action.payload.name],
                loading: state[action.payload.name].loading - 1,
            }
        }
    }

    if ((action.type.startsWith('[SELECT] load options start') || action.type.startsWith('[SELECT] load selected start')) && state[action.payload.name]) {
        return {
            ...state,
            [action.payload.name]: {
                ...state[action.payload.name],
                loading: state[action.payload.name].loading + 1
            }
        }
    }

    switch (action.type) {
        case SelectActionType.Add():
            return {
                ...state,
                [action.payload]: initialState
            }
        case SelectActionType.Remove():
            const {[action.payload]: name, ...rest} = state
            return rest
        default:
            return state
    }
}