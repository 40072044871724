import * as React from 'react'
import {Box, Button, FormControl, TextField, Typography} from '@material-ui/core'
import {Link as RouterLink, RouteComponentProps, withRouter} from 'react-router-dom'
import {Routes} from "lib/router";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "lib/store";
import {AuthAction, isSubmitting, isTokenValidated, isTokenValidating} from "lib/auth";
import * as Yup from 'yup'
import {Formik} from 'formik';
import {Wrapper} from "app/Views/Auth/Wrapper";
import {CircularProgressBar, LinearProgressBar} from "app/Components/ProgressBar";

type StateToProps = {
    isSubmitting: boolean
    isTokenValidating: boolean
    isTokenValidated: boolean
}

type DispatchToProps = {
    readonly validateToken: (token: string) => void,
    readonly handlePasswordChange: (token: string, password: string) => void,
}

type Params = {
    token: string
}

type ChangePasswordProps = StateToProps & DispatchToProps & RouteComponentProps<Params>

const Schema = Yup.object().shape({
    password: Yup.string()
        .min(9, 'Hasło musi posiadać przynajmniej 9 znaków.')
        .matches(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&!+=]).*$'), 'Wymagana jest przynajmniej 1 duża litera oraz znak specjalny.')
        .required('Podaj nowe hasło'),
    repeatedPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Hasła muszą do siebie pasować.'),
})

class ChangePasswordComponent extends React.Component<ChangePasswordProps> {
    public componentDidMount(): void {
        this.props.validateToken(this.props.match.params.token)
    }

    public render() {
        return (
            <>
                {!this.props.isTokenValidating ? (
                    <Box mb={2}>
                        <LinearProgressBar isVisible={this.props.isSubmitting}/>
                        <Wrapper variant="outlined">
                            <Typography component="h1" variant="h4" align="center" gutterBottom>
                                Zmień hasło
                            </Typography>
                            <Formik
                                initialValues={{password: '', repeatedPassword: ''}}
                                validationSchema={Schema}
                                validateOnChange={false}
                                onSubmit={(values) => {
                                    this.props.handlePasswordChange(this.props.match.params.token, values.password)
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      /* and other goodies */
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                error={Boolean(errors.password && touched.password)}
                                                label="Nowe hasło*"
                                                id="password"
                                                name="password"
                                                type="password"
                                                inputProps={{
                                                    maxLength: 30,
                                                }}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                variant="outlined"
                                                helperText={errors.password}
                                            />
                                        </FormControl>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                error={Boolean(errors.repeatedPassword && touched.repeatedPassword)}
                                                label="Powtórz nowe hasło*"
                                                id="repeatedPassword"
                                                name="repeatedPassword"
                                                type="password"
                                                inputProps={{
                                                    maxLength: 30,
                                                }}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.repeatedPassword}
                                                variant="outlined"
                                                helperText={errors.repeatedPassword}
                                            />
                                        </FormControl>
                                        <Box mb={2} marginTop={3}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={this.props.isSubmitting}
                                            >
                                                Zmień hasło
                                            </Button>
                                        </Box>
                                        <Button
                                            fullWidth
                                            color="primary"
                                            component={RouterLink}
                                            to={Routes.AuthSignIn}
                                        >
                                            Anuluj
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        </Wrapper>
                    </Box>
                ) : (
                    <CircularProgressBar/>
                )
                }
            </>
        )
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isSubmitting: isSubmitting(state),
    isTokenValidated: isTokenValidated(state),
    isTokenValidating: isTokenValidating(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    validateToken: (token: string) => {
        dispatch(AuthAction.passwordResetValidation({
            token: token
        }))
    },
    handlePasswordChange: (token: string, password: string) => {
        dispatch(AuthAction.passwordResetChange({
            token: token,
            passwordResetRequest: {password},
        }))
    }
})

export const ResetPasswordChange = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordComponent))