import {of} from 'rxjs'
import {Source} from 'lib/select'
import {Status} from './dto'

export const dao = {
    list: (payload: any) => {
        switch(payload.type) {
            case Source.USERS:
                return of(<Status[]>[
                    {id: '-1', value: 'Wszyscy'},
                    {id: '1', value: 'Aktywni'},
                    {id: '0', value: 'Nieaktywni'},
                ])
            case Source.SCHOOLS:
            case Source.GROUPS:
            case Source.SUBJECTS:
            case Source.TEST_CONFIG:
                return of(<Status[]>[
                    {id: '-1', value: 'Wszystkie'},
                    {id: '1', value: 'Aktywne'},
                    {id: '0', value: 'Nieaktywne'},
                ])
            case Source.TEST_PUBLISHED:
                return of(<Status[]>[
                    {id: '1', value: 'Tak'},
                    {id: '0', value: 'Nie'},
                ])
        }

        return of(<Status[]>[])
    },
}