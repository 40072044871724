import * as React from 'react'
import {KsSection, KsTestSection} from ".";

type PreviewProps = {
    data?: any
    preview: boolean
    sectionIndex?: number
}

export class KsForm extends React.Component<PreviewProps> {
    public render = () => <>
        {this.props.data ? (this.props.data as KsTestSection[]).map(
            (section, index) => index == this.props.sectionIndex || this.props.sectionIndex == undefined ?
                <KsSection
                    section={section}
                    key={index}
                    index={index}
                    preview={this.props.preview}
                    prev={index == 0 ? null : index - 1}
                    next={index == this.props.data.length - 1 ? null : index + 1}
                /> : null,
            this
        ) : ''}
    </>
}