import {Box, ListItem, Typography} from "@material-ui/core";
import {getRow, RouteType} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {State} from 'lib/store'
import {Test, testName} from 'lib/test'
import {selectConfig} from 'lib/testConfig'
import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import styled from "styled-components";
import {generateCrudPath} from "lib/router";
import {SectionLink} from "./SectionLink";
import 'external-lib/perfect-scrollbar/perfect-scrollbar.css'
import PerfectScrollbar from 'react-perfect-scrollbar'

const Drawer = styled.div`
  width: 250px;
  height: 100vh;
  flex-shrink: 0;
  background: #1B2430;
`

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  height: calc(100vh - 102px);
`

type StateToProps = {
    readonly test: Test
    readonly isLoading: boolean
}

type DispatchToProps = {}

type Params = {
    parentId: string
    id: string
}

type TestMenuState = {
    section: number
}

class TestMenuComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>, TestMenuState> {
    state: TestMenuState = {
        section: 0
    }

    componentDidUpdate(prevProps: Readonly<StateToProps & DispatchToProps & RouteComponentProps<Params>>, prevState: Readonly<TestMenuState>, snapshot?: any) {
        const query = new URLSearchParams(this.props.location.search)
        const section = parseInt(query.get("section") || '1') - 1

        if (this.state.section !== section) {
            this.setState(prevState => {
                return {...prevState, section: section}
            })
        }
    }

    public render = () => {
        const testConfig = selectConfig(this.props.test.config)

        if (testConfig === undefined || testConfig.menu === null) {
            return <></>
        }

        return <Drawer>
            <Box style={{height: '102px', background: '#10151c'}} display="flex" justifyContent="center" alignItems="center">
                <Typography variant={'h4'} style={{color: '#eeeeee'}}>
                    Sekcje testu
                </Typography>
            </Box>
            <Scrollbar>
                {!this.props.isLoading && this.props.test.content
                    ? testConfig.menu.data(this.props.test).map(
                        (section: any, index: number) => <SectionLink
                            name={(index + 1) + '. ' + testConfig.menu.label(section)}
                            to={generateCrudPath(testName, RouteType.EDIT, {parentId: this.props.test.student, id: this.props.test.id}) + '?section=' + (index + 1)}
                            active={index === this.state.section}
                            key={index}
                        />,
                        this
                    ) : ''}
            </Scrollbar>
        </Drawer>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    test: getRow(state => state.tests)(state),
    isLoading: isLoading(state)
})

export const TestMenu = withRouter(connect(mapStateToProps)(TestMenuComponent))