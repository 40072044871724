// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    PasswordResetInitializationRequest,
    PasswordResetRequest,
} from '../models';

export interface PasswordResetChangeRequest {
    token: string;
    passwordResetRequest?: PasswordResetRequest;
}

export interface PasswordResetInitiateRequest {
    passwordResetInitializationRequest?: PasswordResetInitializationRequest;
}

export interface PasswordResetValidateRequest {
    token: string;
}

/**
 * no description
 */
export class PasswordResetApi extends BaseAPI {

    /**
     * Change the User password.
     */
    passwordResetChange({ token, passwordResetRequest }: PasswordResetChangeRequest): Observable<void>
    passwordResetChange({ token, passwordResetRequest }: PasswordResetChangeRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    passwordResetChange({ token, passwordResetRequest }: PasswordResetChangeRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(token, 'token', 'passwordResetChange');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            url: '/password-reset/{token}/change'.replace('{token}', encodeURI(token)),
            method: 'POST',
            headers,
            body: passwordResetRequest,
        }, opts?.responseOpts);
    };

    /**
     * Initiate the password reset process.
     */
    passwordResetInitiate({ passwordResetInitializationRequest }: PasswordResetInitiateRequest): Observable<void>
    passwordResetInitiate({ passwordResetInitializationRequest }: PasswordResetInitiateRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    passwordResetInitiate({ passwordResetInitializationRequest }: PasswordResetInitiateRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            url: '/password-reset/initiate',
            method: 'POST',
            headers,
            body: passwordResetInitializationRequest,
        }, opts?.responseOpts);
    };

    /**
     * Validate the password reset token.
     */
    passwordResetValidate({ token }: PasswordResetValidateRequest): Observable<void>
    passwordResetValidate({ token }: PasswordResetValidateRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    passwordResetValidate({ token }: PasswordResetValidateRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(token, 'token', 'passwordResetValidate');

        return this.request<void>({
            url: '/password-reset/{token}/validate'.replace('{token}', encodeURI(token)),
            method: 'POST',
        }, opts?.responseOpts);
    };

}
