import {IActionWithPayload} from 'external-lib/redux-utils'
import {dao as dictionariesDao} from 'lib/dictionaries'
import {dao as educationStatusDao} from 'lib/educationStatus'
import {dao as eventsDao} from 'lib/events'
import {dao as groupsDao} from 'lib/groups'
import {dao as rolesDao} from 'lib/roles'
import {dao as schoolsDao} from 'lib/schools'
import {dao as schoolTypeDao} from 'lib/schoolType'
import {SelectAction, SelectActionType, Source} from 'lib/select'
import {dao as statusDao} from 'lib/status'
import {dao as ageDao} from 'lib/age'
import {dao as classDurationDao} from 'lib/classDuration'
import {dao as studentsCategoryDao} from 'lib/studentCategory'
import {dao as studentsDao} from 'lib/students'
import {dao as studentFileTypesDao} from 'lib/studentFileTypes'
import {dao as subjectsDao} from 'lib/subjects'
import {dao as subjectsCategoryDao} from 'lib/subjectsCategory'
import {dao as usersDao} from 'lib/users'
import {dao as testConfigDao} from 'lib/testConfig'
import {AnyAction} from 'redux'
import {combineEpics, Epic, ofType} from 'redux-observable'
import {Observable, of} from 'rxjs'
import {catchError, debounceTime, map, mergeMap, switchMap} from 'rxjs/operators'
import {transformPayload} from './transformer'
import {PER_PAGE} from "./vars";

type SelectEpic<R, P, I, O> = (apiCall: (payload: P) => Observable<R>) => Epic<AnyAction, IActionWithPayload<string, O>>

const loadOptionsEpic: <R, P, I, O>(name: string, source: string) => SelectEpic<R, P, I, O> = (name: string, source: string) => apiCall => action$ => action$.pipe(
    ofType(SelectActionType.LoadOptions(name, source)),
    debounceTime(500),
    mergeMap(({payload}) => {
        const page = (payload.page == undefined ? 1 : payload.page)
        const options = {...transformPayload(source, payload), page}

        return apiCall(options).pipe(
            map(response => {
                const perPage = PER_PAGE
                const total = ((response as any).total as number)
                const pages = Math.ceil(total / perPage)

                if (pages > page) {
                    return SelectAction.loadNextOptions(name, source, {
                        ...transformPayload(source, payload),
                        page: page + 1
                    }, response)
                }

                return SelectAction.loadOptionsSuccess(name, source, {name, source, response})
            }),
            catchError(error => of(SelectAction.loadOptionsFailure(name, source, {name, source, error}))),
        )
    })
)

const loadNextOptionsEpic: Epic<SelectAction> = action$ => action$.pipe(
    ofType(SelectActionType.LoadNextOptions()),
    map(({payload}) => {
        return SelectAction.loadOptions(payload.name, payload.source, '', payload.options)
    })
)

const loadSelectedEpic: <R, P, I, O>(name: string, source: string) => SelectEpic<R, P, I, O> = (name: string, source: string) => apiCall => action$ => action$.pipe(
    ofType(SelectActionType.LoadSelected(name, source)),
    switchMap(({payload}) => {
        return apiCall(transformPayload(source, payload)).pipe(
            map(response => SelectAction.loadSelectedSuccess(name, source, {name, source, response})),
            catchError(error => of(SelectAction.loadSelectedFailure(name, source, {name, source, error}))),
        )
    })
)

export const selectEpics = combineEpics(
    loadNextOptionsEpic,
    loadOptionsEpic('school', Source.SCHOOLS)(schoolsDao.list),
    loadSelectedEpic('school', Source.SCHOOLS)(schoolsDao.list),
    loadOptionsEpic('category', Source.SUBJECTS_CATEGORY)(subjectsCategoryDao.list),
    loadSelectedEpic('category', Source.SUBJECTS_CATEGORY)(subjectsCategoryDao.list),
    loadOptionsEpic('category', Source.STUDENTS_CATEGORY)(studentsCategoryDao.list),
    loadSelectedEpic('category', Source.STUDENTS_CATEGORY)(studentsCategoryDao.list),
    loadOptionsEpic('status', Source.EDUCATION_STATUS)(educationStatusDao.list),
    loadSelectedEpic('status', Source.EDUCATION_STATUS)(educationStatusDao.list),
    loadOptionsEpic('roles', Source.ROLES)(rolesDao.list),
    loadSelectedEpic('roles', Source.ROLES)(rolesDao.list),
    loadOptionsEpic('role', Source.ROLES)(rolesDao.list),
    loadSelectedEpic('role', Source.ROLES)(rolesDao.list),
    loadOptionsEpic('specialties', Source.DICTIONARIES)(dictionariesDao.list),
    loadSelectedEpic('specialties', Source.DICTIONARIES)(dictionariesDao.list),
    loadOptionsEpic('qualifications', Source.DICTIONARIES)(dictionariesDao.list),
    loadSelectedEpic('qualifications', Source.DICTIONARIES)(dictionariesDao.list),
    loadOptionsEpic('disabilities', Source.DICTIONARIES)(dictionariesDao.list),
    loadSelectedEpic('disabilities', Source.DICTIONARIES)(dictionariesDao.list),
    loadOptionsEpic('siblingsDisabilities', Source.DICTIONARIES)(dictionariesDao.list),
    loadSelectedEpic('siblingsDisabilities', Source.DICTIONARIES)(dictionariesDao.list),
    loadOptionsEpic('teachers', Source.USERS)(usersDao.list),
    loadSelectedEpic('teachers', Source.USERS)(usersDao.list),
    loadOptionsEpic('teacher', Source.USERS)(usersDao.list),
    loadSelectedEpic('teacher', Source.USERS)(usersDao.list),
    loadOptionsEpic('principals', Source.USERS)(usersDao.list),
    loadSelectedEpic('principals', Source.USERS)(usersDao.list),
    loadOptionsEpic('groups', Source.GROUPS)(groupsDao.list),
    loadSelectedEpic('groups', Source.GROUPS)(groupsDao.list),
    loadOptionsEpic('group', Source.GROUPS)(groupsDao.list),
    loadSelectedEpic('group', Source.GROUPS)(groupsDao.list),
    loadOptionsEpic('event', Source.EVENTS)(eventsDao.list),
    loadSelectedEpic('event', Source.EVENTS)(eventsDao.list),
    loadOptionsEpic('type', Source.SCHOOL_TYPE)(schoolTypeDao.list),
    loadSelectedEpic('type', Source.SCHOOL_TYPE)(schoolTypeDao.list),
    loadOptionsEpic('active', Source.STATUS)(statusDao.list),
    loadSelectedEpic('active', Source.STATUS)(statusDao.list),
    loadOptionsEpic('subject', Source.SUBJECTS)(subjectsDao.list),
    loadSelectedEpic('subject', Source.SUBJECTS)(subjectsDao.list),
    loadOptionsEpic('duration', Source.CLASS_DURATION)(classDurationDao.list),
    loadSelectedEpic('duration', Source.CLASS_DURATION)(classDurationDao.list),
    loadOptionsEpic('parents', Source.PARENTS)(usersDao.list),
    loadSelectedEpic('parents', Source.PARENTS)(usersDao.list),
    loadOptionsEpic('teachers', Source.TEACHERS)(usersDao.list),
    loadSelectedEpic('teachers', Source.TEACHERS)(usersDao.list),
    loadOptionsEpic('teacher', Source.SPECIALISTS)(usersDao.list),
    loadSelectedEpic('teacher', Source.SPECIALISTS)(usersDao.list),
    loadOptionsEpic('individualTeachers', Source.TEACHERS)(usersDao.list),
    loadSelectedEpic('individualTeachers', Source.TEACHERS)(usersDao.list),
    loadOptionsEpic('students', Source.STUDENTS)(studentsDao.list),
    loadSelectedEpic('students', Source.STUDENTS)(studentsDao.list),
    loadOptionsEpic('student', Source.STUDENTS)(studentsDao.list),
    loadSelectedEpic('student', Source.STUDENTS)(studentsDao.list),
    loadOptionsEpic('id', Source.STUDENTS)(studentsDao.list),
    loadSelectedEpic('id', Source.STUDENTS)(studentsDao.list),
    loadOptionsEpic('type', Source.STUDENT_FILE_TYPES)(studentFileTypesDao.list),
    loadSelectedEpic('type', Source.STUDENT_FILE_TYPES)(studentFileTypesDao.list),
    loadOptionsEpic('config', Source.TEST_CONFIG)(testConfigDao.list),
    loadSelectedEpic('config', Source.TEST_CONFIG)(testConfigDao.list),
    loadOptionsEpic('config', Source.TEST_FOR_ANALYSIS)(testConfigDao.listForAnalysis),
    loadSelectedEpic('config', Source.TEST_FOR_ANALYSIS)(testConfigDao.listForAnalysis),
    loadOptionsEpic('published', Source.STATUS)(statusDao.list),
    loadSelectedEpic('published', Source.STATUS)(statusDao.list),
    loadOptionsEpic('age', Source.AGE)(ageDao.list),
    loadSelectedEpic('age', Source.AGE)(ageDao.list),
)