import styled from "styled-components";
import {Paper} from "@material-ui/core";

export const Wrapper = styled(Paper)`
  max-width: 450px;
  padding: ${props => props.theme.spacing(6)}px;
  
  ${props => props.theme.breakpoints.up("sm")} {
    width: 480px;
  }

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`

