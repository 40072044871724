import {Box, Button, Link} from "@material-ui/core";
import {isGranted, me, rules} from 'lib/auth'
import {State} from 'lib/store'
import {StudentFile} from 'lib/studentFiles'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {CrudAction, Type} from 'lib/crud'
import {name} from 'lib/studentFiles'
import {ParentPreview} from "../Preview";
import {FormattedDate} from './FormattedDate'

type StateToProps = {
    readonly user: User
}

type DispatchToProps = {
    confirm: (id: string) => void
}

type StudentFileStatusProps = {
    file: StudentFile
}

class StudentFileStatusComponent extends React.Component<StudentFileStatusProps & StateToProps & DispatchToProps> {
    public render = () => {
        const status = this.props.file.confirmedBy
            ? <Box>
                Odebrany przez: <ParentPreview id={this.props.file.confirmedBy} />
                <Box>dnia: <FormattedDate date={this.props.file.confirmedAt} /></Box>
              </Box>
            : null

        if (!isGranted(rules.studentFiles.needConfirmationToDownload, this.props.user) && this.props.file.needsConfirmation) {
            return 'Oczekuje na odbór'
        }

        if (isGranted(rules.studentFiles.needConfirmationToDownload, this.props.user) && this.props.file.needsConfirmation) {
            return <>
                {this.props.file.needsConfirmation ? status : ''}
                <Button variant="contained" color="primary" onClick={() => this.props.confirm(this.props.file.id)}>Potwierdź odbiór</Button>
            </>
        }

        return <>{status}<Button href={'/api/student-files/' + this.props.file.id + '/content'} target={'_blank'} color="primary" variant={"contained"}>Pobierz</Button></>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, StudentFileStatusProps, State> = (state, ownProps) => ({
    user: me(state)!,
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    confirm: (id: string) => {
        dispatch(CrudAction.execute(name, Type.UPDATE, {id: id}))
    }
})

export const StudentFileStatus = connect(mapStateToProps, mapDispatchToProps)(StudentFileStatusComponent)