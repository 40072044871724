import {Box, Link} from '@material-ui/core'
import {Preview} from 'app/Components'
import {RouteType} from 'lib/crud'
import {generateCrudPath} from 'lib/router'
import {name as schoolsName, School} from 'lib/schools'
import {Source} from 'lib/select'
import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {User} from 'lib/users'
import {me, Role} from 'lib/auth'
import {State} from 'lib/store'

type StateToProps = {
    readonly me: User
}

type SchoolPreviewProps = {
    id: string,
}

class SchoolPreviewComponent extends React.Component<StateToProps & SchoolPreviewProps> {
    public render = () =>
        <Preview
            value={this.props.id}
            source={Source.SCHOOLS}
            template={(school: School) => (this.props.me.roles.includes(Role.ROLE_PARENT)
                    ? <>{school.name}</>
                    : <>
                        <Link component={NavLink}
                              to={generateCrudPath(schoolsName, RouteType.SHOW, {id: school.id})}>{school.name}</Link>
                        <Box>({school.identifier})</Box>
                    </>
            )}
        />
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    me: me(state)!
})

export const SchoolPreview = connect(mapStateToProps)(SchoolPreviewComponent)