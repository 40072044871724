import {crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'

export const name = 'STUDENT_TEACHERS'

export type StudentTeachersState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function studentTeachersReducer(state: StudentTeachersState = initialState, action: AnyAction): StudentTeachersState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
