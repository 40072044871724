import {isAuthenticated, isGranted, me, AccessRule} from 'lib/auth'
import {Routes} from 'lib/router'
import React, {FunctionComponent} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, RouteProps} from 'react-router'


type ProtectedRouteProps = RouteProps & {
    readonly fallback?: string
    readonly rule?: AccessRule
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = props => {
    const user = useSelector(me)

    if (!useSelector(isAuthenticated)) {
        return <Redirect to={props.fallback || Routes.AuthSignIn}/>
    }

    if (props.rule && !isGranted(props.rule, user!)) {
        return <Redirect to={props.fallback || Routes.AuthSignIn}/>
    }

    return <Route {...props}/>
}