import {CrudAddHeader} from 'app/Components'
import {CrudAction, getRow, Type} from 'lib/crud'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import {Tabs} from '../Tabs'
import {Form} from './Form'
import {newTest, Test, testName} from "lib/test";
import {User} from "lib/users";
import {me} from "lib/auth";

type StateToProps = {
    readonly student: Student
    readonly me: User
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
    readonly handleSubmit: (test: Test) => void,
}

type Params = {
    parentId: string
}

class AddComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
    }

    public render = () => (<>
        <CrudAddHeader actionName={testName}/>
        <Tabs id={this.props.match.params.parentId} />
        {this.props.student.id != undefined
            ? <Form initialValues={newTest(this.props.student.id, this.props.me.id)} handleSubmit={this.props.handleSubmit}/>
            : null}
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    me: me(state)!,
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    handleSubmit: (test: Test) => {
        delete test.id
        dispatch(CrudAction.execute(testName, Type.CREATE, test))
    }
})

export const Add = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddComponent))