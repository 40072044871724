import {SubjectCategoryApi} from 'client/apis'
import {configuration} from 'lib/client'

const SubjectCategoryApiClient = (): SubjectCategoryApi => {
    return new SubjectCategoryApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return SubjectCategoryApiClient().getSubjectCategoryCollection(payload)
    },
    get: (payload: any) => {
        return SubjectCategoryApiClient().getSubjectCategoryItem({id: payload.id})
    },
}