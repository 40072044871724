import * as React from 'react'

type FormattedDateProps = {
    date: string
    dayOnly?: boolean
}

export class FormattedDate extends React.Component<FormattedDateProps> {
    public render() {
        let options

        if (!this.props.dayOnly) {
            options = {hour: "2-digit", minute: "2-digit", timeZone:'Europe/Warsaw'}
        } else {
            options = {timeZone:'Europe/Warsaw'}
        }

        return <>
            {this.props.date != '' && this.props.date !== null ? new Date(this.props.date).toLocaleDateString('pl-PL', options) : ''}
        </>
    }
}
