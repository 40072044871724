import {Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    index: number
    section: ISSSTestSection
    preview?: boolean
    prev: null | number
    next: null | number
}

type SectionState = {
    section: ISSSTestSection
}

export type ISSSTestSection = {
    system: string,
    required: boolean,
    result: number,
}

export class ISSSSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.result = event.target.checked ? parseInt(event.target.name) : 0;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => <Box style={this.props.preview ? {margin: '0px 0px 50px 0px'} : {}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>
                        {this.props.preview ? <Box>{(this.props.index + 1) + '. ' + this.state.section.system}</Box> : ''}
                        Obserwacja
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                <TableRow>
                    <TableCell align={"center"} style={{width: '50px'}}>
                        <Checkbox
                            name={'1'}
                            checked={this.state.section.result == 1}
                            onChange={this.handleChange}
                            disabled={this.props.preview || false}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell>
                        Nadreaktywność
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align={"center"} style={{width: '50px'}}>
                        <Checkbox
                            name={'2'}
                            checked={this.state.section.result == 2}
                            onChange={this.handleChange}
                            disabled={this.props.preview || false}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell>
                        Podreaktywność
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align={"center"} style={{width: '50px'}}>
                        <Checkbox
                            name={'3'}
                            checked={this.state.section.result == 3}
                            onChange={this.handleChange}
                            disabled={this.props.preview || false}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell>
                        Poszukiwacz
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align={"center"} style={{width: '50px'}}>
                        <Checkbox
                            name={'4'}
                            checked={this.state.section.result == 4}
                            onChange={this.handleChange}
                            disabled={this.props.preview || false}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell>
                        Norma
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </Box>
}