import {
    CrudAction, dispatchAfterFailureEpic, dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {NotificationsAction} from 'lib/notifications'
import {generateRouterCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {dao, name} from 'lib/subjects'
import {combineEpics} from 'redux-observable'

export const subjectsEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.CREATE)(dao.create),
    executeCrudEpic(name, Type.UPDATE)(dao.update),
    executeCrudEpic(name, Type.DELETE)(dao.delete),

    redirectAfterSuccessEpic(name, Type.CREATE, (action, state: State) => generateRouterCrudPath(name, RouteType.LIST)),
    redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => generateRouterCrudPath(name, RouteType.LIST)),

    dispatchAfterSuccessEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nowe zajęcie',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas dodawania nowego zajęcia',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Zajecie zostało zaktualizowane',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas aktualizacji zajęcia',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Zajęcie zostało usunięte',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas usuwania zajęcia',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.subjects.crud.listOptions.sortDirection,
        page: state.subjects.crud.listOptions.page + 1,
        itemsPerPage: state.subjects.crud.listOptions.pageSize,
        ...state.subjects.crud.filters,
    })),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.subjects.crud.listOptions.sortDirection,
        page: state.subjects.crud.listOptions.page + 1,
        itemsPerPage: state.subjects.crud.listOptions.pageSize,
        ...state.subjects.crud.filters,
    }))
)