import {IActionWithPayload} from 'external-lib/redux-utils'
import {AuthActionType} from 'lib/auth'
import {EnumAction, EnumActionType} from 'lib/enums'
import {dao as rolesDao, name as rolesName} from 'lib/roles'
import {dao as subjectsCategoryDao, name as subjectCategoryName} from 'lib/subjectsCategory'
import {dao as educationStatusDao, name as educationStatusName} from 'lib/educationStatus'
import {dao as classDurationDao, name as classDurationName} from 'lib/classDuration'
import {dao as schoolTypeDao, name as schoolTypeName} from 'lib/schoolType'
import {dao as testTypeDao, name as testTypeName} from 'lib/testType'
import {AnyAction} from 'redux'
import {combineEpics, Epic, ofType} from 'redux-observable'
import {Observable, of} from 'rxjs'
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators'

type EnumEpic<R, P, I, O> = (apiCall: (payload: P) => Observable<R>) => Epic<AnyAction, IActionWithPayload<string, O>>

const loadEnumEpic: <R, P, I, O>(name: string) => EnumEpic<R, P, I, O> = (name: string) => apiCall => action$ => action$.pipe(
    ofType(EnumActionType.Load(name)),
    switchMap(({payload}) => {
        return apiCall(payload).pipe(
            map(response => EnumAction.loadSuccess(name, {name, response})),
            catchError(error => of(EnumAction.loadFailure(name, {name, error}))),
        )
    })
)

const loadEnumsAfterAuthenticationsSuccessfulEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.InitializeSuccess),
    mergeMap(() => {
        return [
            EnumAction.load(subjectCategoryName),
            EnumAction.load(rolesName),
            EnumAction.load(educationStatusName),
            EnumAction.load(classDurationName),
            EnumAction.load(schoolTypeName),
            EnumAction.load(testTypeName),
        ]
    })
)

export const enumEpics = combineEpics(
    loadEnumsAfterAuthenticationsSuccessfulEpic,
    loadEnumEpic(subjectCategoryName)(subjectsCategoryDao.list),
    loadEnumEpic(rolesName)(rolesDao.list),
    loadEnumEpic(educationStatusName)(educationStatusDao.list),
    loadEnumEpic(classDurationName)(classDurationDao.list),
    loadEnumEpic(schoolTypeName)(schoolTypeDao.list),
    loadEnumEpic(testTypeName)(testTypeDao.list),
)