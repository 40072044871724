// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20016,
    InlineResponse20017,
    StudentRead,
    StudentWrite,
} from '../models';

export interface DeleteStudentItemRequest {
    id: string;
}

export interface EvaluationsStudentItemRequest {
    id: string;
    config?: string;
    from?: string;
    to?: string;
}

export interface GetStudentCollectionRequest {
    id?: Array<string>;
    school?: Array<string>;
    status?: Array<string>;
    category?: Array<string>;
    groups?: Array<string>;
    search?: string;
    orderIdentifier?: GetStudentCollectionOrderIdentifierEnum;
    orderFirstName?: GetStudentCollectionOrderFirstNameEnum;
    orderLastName?: GetStudentCollectionOrderLastNameEnum;
    orderSchoolName?: GetStudentCollectionOrderSchoolNameEnum;
    orderCategory?: GetStudentCollectionOrderCategoryEnum;
    orderStatus?: GetStudentCollectionOrderStatusEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetStudentItemRequest {
    id: string;
}

export interface PostStudentCollectionRequest {
    studentWrite?: StudentWrite;
}

export interface PutStudentItemRequest {
    id: string;
    studentWrite?: StudentWrite;
}

export interface SummariesStudentItemRequest {
    id: string;
    config?: Array<string>;
}

/**
 * no description
 */
export class StudentApi extends BaseAPI {

    /**
     * Removes the Student resource.
     */
    deleteStudentItem({ id }: DeleteStudentItemRequest): Observable<void>
    deleteStudentItem({ id }: DeleteStudentItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteStudentItem({ id }: DeleteStudentItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteStudentItem');

        return this.request<void>({
            url: '/api/students/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Student resource.
     */
    evaluationsStudentItem({ id, config, from, to }: EvaluationsStudentItemRequest): Observable<Array<InlineResponse20017>>
    evaluationsStudentItem({ id, config, from, to }: EvaluationsStudentItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<InlineResponse20017>>>
    evaluationsStudentItem({ id, config, from, to }: EvaluationsStudentItemRequest, opts?: OperationOpts): Observable<Array<InlineResponse20017> | RawAjaxResponse<Array<InlineResponse20017>>> {
        throwIfNullOrUndefined(id, 'id', 'evaluationsStudentItem');

        const query: HttpQuery = {};

        if (config != null) { query['config'] = config; }
        if (from != null) { query['from'] = (from as any).toISOString(); }
        if (to != null) { query['to'] = (to as any).toISOString(); }

        return this.request<Array<InlineResponse20017>>({
            url: '/api/students/{id}/evaluations'.replace('{id}', encodeURI(id)),
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of Student resources.
     */
    getStudentCollection({ id, school, status, category, groups, search, orderIdentifier, orderFirstName, orderLastName, orderSchoolName, orderCategory, orderStatus, page, itemsPerPage }: GetStudentCollectionRequest): Observable<InlineResponse20016>
    getStudentCollection({ id, school, status, category, groups, search, orderIdentifier, orderFirstName, orderLastName, orderSchoolName, orderCategory, orderStatus, page, itemsPerPage }: GetStudentCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20016>>
    getStudentCollection({ id, school, status, category, groups, search, orderIdentifier, orderFirstName, orderLastName, orderSchoolName, orderCategory, orderStatus, page, itemsPerPage }: GetStudentCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20016 | RawAjaxResponse<InlineResponse20016>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (school != null) { query['school[]'] = school; }
        if (status != null) { query['status[]'] = status; }
        if (category != null) { query['category[]'] = category; }
        if (groups != null) { query['groups[]'] = groups; }
        if (search != null) { query['search'] = search; }
        if (orderIdentifier != null) { query['order[identifier]'] = orderIdentifier; }
        if (orderFirstName != null) { query['order[firstName]'] = orderFirstName; }
        if (orderLastName != null) { query['order[lastName]'] = orderLastName; }
        if (orderSchoolName != null) { query['order[school.name]'] = orderSchoolName; }
        if (orderCategory != null) { query['order[category]'] = orderCategory; }
        if (orderStatus != null) { query['order[status]'] = orderStatus; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20016>({
            url: '/api/students',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Student resource.
     */
    getStudentItem({ id }: GetStudentItemRequest): Observable<StudentRead>
    getStudentItem({ id }: GetStudentItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StudentRead>>
    getStudentItem({ id }: GetStudentItemRequest, opts?: OperationOpts): Observable<StudentRead | RawAjaxResponse<StudentRead>> {
        throwIfNullOrUndefined(id, 'id', 'getStudentItem');

        return this.request<StudentRead>({
            url: '/api/students/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a Student resource.
     */
    postStudentCollection({ studentWrite }: PostStudentCollectionRequest): Observable<StudentRead>
    postStudentCollection({ studentWrite }: PostStudentCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StudentRead>>
    postStudentCollection({ studentWrite }: PostStudentCollectionRequest, opts?: OperationOpts): Observable<StudentRead | RawAjaxResponse<StudentRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<StudentRead>({
            url: '/api/students',
            method: 'POST',
            headers,
            body: studentWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the Student resource.
     */
    putStudentItem({ id, studentWrite }: PutStudentItemRequest): Observable<StudentRead>
    putStudentItem({ id, studentWrite }: PutStudentItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StudentRead>>
    putStudentItem({ id, studentWrite }: PutStudentItemRequest, opts?: OperationOpts): Observable<StudentRead | RawAjaxResponse<StudentRead>> {
        throwIfNullOrUndefined(id, 'id', 'putStudentItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<StudentRead>({
            url: '/api/students/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: studentWrite,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Student resource.
     */
    summariesStudentItem({ id, config }: SummariesStudentItemRequest): Observable<{ [key: string]: object; }>
    summariesStudentItem({ id, config }: SummariesStudentItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<{ [key: string]: object; }>>
    summariesStudentItem({ id, config }: SummariesStudentItemRequest, opts?: OperationOpts): Observable<{ [key: string]: object; } | RawAjaxResponse<{ [key: string]: object; }>> {
        throwIfNullOrUndefined(id, 'id', 'summariesStudentItem');

        const query: HttpQuery = {};

        if (config != null) { query['config[]'] = config; }

        return this.request<{ [key: string]: object; }>({
            url: '/api/students/{id}/summaries'.replace('{id}', encodeURI(id)),
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetStudentCollectionOrderIdentifierEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetStudentCollectionOrderFirstNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetStudentCollectionOrderLastNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetStudentCollectionOrderSchoolNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetStudentCollectionOrderCategoryEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetStudentCollectionOrderStatusEnum {
    Asc = 'asc',
    Desc = 'desc'
}
