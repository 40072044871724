import {CrudAction, dispatchAfterSuccessEpic, executeCrudEpic, redirectAfterSuccessEpic, Type} from 'lib/crud'
import {dao, name} from 'lib/dictionaries'
import {State} from 'lib/store'
import {combineEpics} from 'redux-observable'
import {NotificationsAction} from 'lib/notifications'

export const dictionariesEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.CREATE)(dao.create),
    executeCrudEpic(name, Type.UPDATE)(dao.update),
    executeCrudEpic(name, Type.DELETE)(dao.delete),

    redirectAfterSuccessEpic(name, Type.CREATE, (action, state: State) => `/slowniki/${state.dictionaries.type}`),
    redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => `/slowniki/${state.dictionaries.type}`),

    dispatchAfterSuccessEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nowy element do słownika',
        options: {variant: "success"}
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Element słownika został zaktualizowany',
        options: {variant: "success"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Element słownika został usunięty',
        options: {variant: "success"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        type: state.dictionaries.type,
        data: {
            type: [state.dictionaries.type],
            orderName: state.dictionaries.crud.listOptions.sortDirection,
            page: state.dictionaries.crud.listOptions.page + 1,
            itemsPerPage: state.dictionaries.crud.listOptions.pageSize
        }
    }))
)