import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export const DictionaryActionType = {
    setType: (): string => `[DATA] Set type`,
}

export const DictionaryAction = {
    setType: (type: string) => createAction(DictionaryActionType.setType(), type),
}

export type DictionaryAction = ActionsUnion<typeof DictionaryAction>