import {Box, Button, FormControl, Grid, TextField} from '@material-ui/core'
import {KeyboardDatePicker} from '@material-ui/pickers'
import {Autocomplete} from 'app/Components/Form'
import {format} from 'date-fns'
import {deepEqual} from 'external-lib/utils'
import {AccessRule, isGranted, me} from 'lib/auth'
import {CrudAction, getFilters} from 'lib/crud'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {User} from 'lib/users'
import React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {RouteComponentProps, withRouter} from "react-router-dom";

type StateToProps = {
    filters: { [paramName: string]: string | number | boolean | undefined }
    user: User
}

type DispatchToProps = {
    readonly filter: (filters: { [paramName: string]: string | number | boolean | undefined }) => void,
}

export enum FilterType {
    TEXT,
    SELECT,
    MULTIPLE_SELECT,
    DATE,
}

export type Field = {
    name: string
    label: string
    type: FilterType
    source?: Source
    requestOptions?: any
    rule?: AccessRule
}

type Params = {}

type FilterProps = {
    actionName: string
    selector: (state: State) => any,
    initialValues: any
    fields: Field[]
} & RouteComponentProps<Params>

type FilterState = {
    filtered: boolean
    filters: { [paramName: string]: string | number | boolean | any }
}

class FilterComponent extends React.Component<FilterProps & StateToProps & DispatchToProps, FilterState> {
    state: FilterState = {
        filtered: false,
        filters: deepEqual(this.props.filters, {}) ? this.props.initialValues : this.props.filters
    }

    componentDidMount() {
        this.setState(prevState => {
            return {...prevState, filtered: !deepEqual(this.props.filters, {})}
        })
        this.props.filter(this.state.filters)
    }

    componentWillUnmount() {
        this.props.filter({})
    }

    private handleSubmit = () => {
        if (!(!deepEqual(this.state.filters, {}) && !deepEqual(this.state.filters, this.props.initialValues))) {
            return
        }
        this.setState(prevState => {
            return {...prevState, filtered: true}
        })
        this.props.filter(this.state.filters)
        this.props.history.push('?page=1')
    }

    private clear = () => {
        this.setState(prevState => {
            return {...prevState, filters: this.props.initialValues, filtered: false}
        })
        this.props.filter(this.props.initialValues)
    }

    private setFieldValue = (field: string, value: any) => {
        this.setState(prevState => {
            return {...prevState, filters: {...prevState.filters, [field]: value}}
        })
    }

    private handleChange = (event: any) => this.setFieldValue(event.target.name, event.target.value)

    public render = () => this.props.fields.length == 0
        ? <></>
        : <Box mb={6}>
            <Grid container spacing={2}>
                {this.props.fields.map(
                    (field, index) =>
                        field.rule == undefined || isGranted(field.rule, this.props.user) ? <Grid item xs key={index}>
                            {field.type === FilterType.TEXT ? <FormControl fullWidth>
                                <TextField
                                    label={field.label}
                                    name={field.name}
                                    variant="outlined"
                                    value={this.state.filters[field.name] || ''}
                                    onChange={this.handleChange}
                                    size={'small'}
                                    error={false}
                                    style={{background: '#ffffff'}}
                                />
                            </FormControl> : null}

                            {field.type === FilterType.SELECT ? <FormControl fullWidth>
                                <Autocomplete
                                    label={field.label}
                                    field={field.name}
                                    source={field.source!}
                                    value={this.state.filters[field.name] || ''}
                                    error={false}
                                    setFieldValue={this.setFieldValue}
                                    requestOptions={field.requestOptions}
                                    size={'small'}
                                    isClearable={false}
                                />
                            </FormControl>: null}

                            {field.type === FilterType.MULTIPLE_SELECT ? <FormControl fullWidth>
                                <Autocomplete
                                    label={field.label}
                                    field={field.name}
                                    source={field.source!}
                                    value={this.state.filters[field.name] || []}
                                    error={false}
                                    multiple={true}
                                    setFieldValue={this.setFieldValue}
                                    requestOptions={field.requestOptions}
                                    size={'small'}
                                    isClearable={false}
                                />
                            </FormControl> : null}

                            {field.type === FilterType.DATE ? <FormControl fullWidth>
                                <KeyboardDatePicker
                                format="dd-MM-yyyy"
                                label={field.label}
                                value={this.state.filters[field.name] || null}
                                KeyboardButtonProps={{
                                    'aria-label': 'zmień datę',
                                }}
                                onChange={val => {
                                    let date

                                    try {
                                        date = format(val as Date, 'yyyy-MM-dd')
                                    } catch (error) {
                                        date = val
                                    }

                                    this.setFieldValue(field.name, date);
                                }}
                                size={'small'}
                                style={{background: '#ffffff'}}
                                invalidDateMessage="Wybrana data jest niepoprawna"
                                minDateMessage="Wybrany rok jest niepoprawny"
                                maxDateMessage="Wybrana data jest późniejsza niż dzisiejsza"
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                disableToolbar
                                disableFuture
                            />
                            </FormControl>: null}

                        </Grid> : '',
                    this
                )}
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => this.handleSubmit()}>
                        Szukaj
                    </Button> {this.state.filtered ?
                    <Button variant="outlined" color="primary" onClick={() => this.clear()}>Wyczyść filtry</Button> : null}
                </Grid>
            </Grid>
        </Box>
}

const mapStateToProps: MapStateToProps<StateToProps, FilterProps, State> = (state, props) => ({
    filters: getFilters(props.selector)(state),
    user: me(state)!,
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, FilterProps> = (dispatch, props) => ({
    filter: (filters: { [paramName: string]: string | number | boolean | undefined }) => {
        dispatch(CrudAction.filter(props.actionName, filters))
    }
})

export const Filter = withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterComponent))