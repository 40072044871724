import {AnyAction} from 'redux'

export type PreviewState = {
    [name: string]: {
        ids: string[],
        rows: any[]
    }
}

const initialState = {}

export function previewReducer(state: PreviewState = initialState, action: AnyAction): PreviewState {
    if (action.type.startsWith('[PREVIEW] add')) {
        let ids: string[] = action.payload.ids.filter((id: string|null) => id)
        if (state[action.payload.source]) {
            ids.push(...state[action.payload.source].ids)
        }

        return {
            ...state,
            [action.payload.source]: {
                ...state[action.payload.source] || {},
                ids: ids.filter((id, pos) => ids.indexOf(id) == pos)
            }
        }
    }

    if (action.type.startsWith('[PREVIEW] remove')) {
        if (state[action.payload.source]) {
            const ids = state[action.payload.source].ids.filter((id, pos) => action.payload.ids.indexOf(id) == -1)

            return {
                ...state,
                [action.payload.source]: {
                    ...(state[action.payload.source] || {}),
                    ids: ids,
                    rows: ids.length > 0 ? state[action.payload.source].rows : []
                }
            }
        }
    }

    if (action.type.startsWith('[PREVIEW] load success')) {
        if (state[action.payload.source]) {
            return {
                ...state,
                [action.payload.source]: {
                    ...state[action.payload.source],
                    rows: action.payload.response!.items as []
                }
            }
        }
    }

    return state
}