import {Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    index: number
    section: OdfTestSurveySection
    preview?: boolean
    prev: null|number
    next: null|number
}

type SectionState = {
    section: OdfTestSurveySection
}

export type OdfTestSurveySection = {
    items: TestItem[]
    section: string
}

interface TestItem {
    age: string
    hints: string
    value: number
    groups: string[]
    activity: string
    required: boolean
    description: string
}

export class OdfSurveySection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let keys = event.target.name.split('|')
        this.state.section.items[parseInt(keys[0])].value = event.target.checked ? parseInt(keys[1]) : 0;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => <Box style={this.props.preview ? {margin: '0px 0px 50px 0px'} : {}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2} style={{whiteSpace: "nowrap", width: '20%'}}>Czyność ruchowa</TableCell>
                    <TableCell style={{width: '40%'}}>Opis</TableCell>
                    <TableCell align={'center'} style={{width: '5%'}}>Nie potrafi</TableCell>
                    <TableCell align={'center'} style={{width: '5%'}}>Potrafi słabo</TableCell>
                    <TableCell align={'center'} style={{width: '5%'}}>Potrafi dobrze</TableCell>
                    <TableCell style={{width: '20%'}}>Wskazówki</TableCell>
                    <TableCell style={{width: '5%'}}>Wiek/Uwagi</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {this.props.section.items.map(
                    (item, index) =>
                        <TableRow key={index}>
                            <TableCell align={'right'}
                                       style={{width: '50px'}}>{index + 1}.</TableCell>
                            <TableCell style={{maxWidth: '150px'}}>
                                {this.props.preview && item.required && index == 0 ?
                                    <Box style={{color: 'red'}}>{item.activity}</Box> : item.activity}
                            </TableCell>
                            <TableCell>
                                {item.description}
                            </TableCell>
                            <TableCell align={'center'}>
                                <Checkbox
                                    name={index + '|1'}
                                    checked={item.value == 1}
                                    onChange={this.handleChange}
                                    disabled={this.props.preview || false}
                                    color="primary"
                                />
                            </TableCell>
                            <TableCell align={'center'}>
                                <Checkbox
                                    name={index + '|2'}
                                    checked={item.value == 2}
                                    onChange={this.handleChange}
                                    disabled={this.props.preview || false}
                                    color="primary"
                                />
                            </TableCell>
                            <TableCell align={'center'}>
                                <Checkbox
                                    name={index + '|3'}
                                    checked={item.value == 3}
                                    onChange={this.handleChange}
                                    disabled={this.props.preview || false}
                                    color="primary"
                                />
                            </TableCell>
                            <TableCell>
                                {item.hints}
                            </TableCell>
                            <TableCell>
                                {item.age}
                            </TableCell>
                        </TableRow>
                )}
            </TableBody>
        </Table>
    </Box>
}