import {EducationStatusApi} from 'client/apis'
import {configuration} from 'lib/client'

const EducationStatusApiClient = (): EducationStatusApi => {
    return new EducationStatusApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return EducationStatusApiClient().getEducationStatusCollection(payload)
    },
    get: (payload: any) => {
        return EducationStatusApiClient().getEducationStatusItem({id: payload.id})
    },
}