// tslint:disable no-magic-numbers

export const seconds = (amount: number) => amount * 1000
export const minutes = (amount: number) => amount * seconds(60)

export const toSeconds = (amount: number) => amount / 1000
export const toMinutes = (amount: number) => toSeconds(amount) / 60
export const toHours = (amount: number) => toMinutes(amount) / 60

export const hoursToMinutes = (amount: number) => amount * 60
export const hoursToSeconds = (amount: number) => hoursToMinutes(amount) * 60

export const secondsToHours = (amount: number) => amount / 3600
