import {STARESection, STARETestSection} from 'app/Views/Tests'
import * as React from 'react'

type PreviewProps = {
    data?: any
    preview: boolean
    sectionIndex?: number
}

export class STAREForm extends React.Component<PreviewProps> {
    public render = () => <>{this.props.data ? (this.props.data as STARETestSection[]).map(
        (section, index) => index == this.props.sectionIndex || this.props.sectionIndex == undefined ?
            <STARESection
                section={section}
                key={index}
                index={index}
                preview={this.props.preview}
                prev={index == 0 ? null : index - 1}
                next={index == this.props.data.length - 1 ? null : index + 1}
            /> : null,
        this
    ) : ''}</>
}