import {CrudEditHeader} from 'app/Components'
import {CrudAction, getRow, Type} from 'lib/crud'
import {HistoryEntry, name as historyEntryName} from 'lib/historyEntry'
import {State} from 'lib/store'
import {name, Student} from "lib/students";
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {Tabs} from "../Tabs";
import {Form} from './Form'

type StateToProps = {
    readonly student: Student
    readonly historyEntry: HistoryEntry
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
    readonly handleSubmit: (historyEntry: HistoryEntry) => void,
}

type Params = {
    parentId: string
}

class EditComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(name, this.props.match.params.parentId)
        }
    }

    public render = () => (<>
        <CrudEditHeader actionName={historyEntryName}/>
        <Tabs id={this.props.match.params.parentId} />
        {this.props.historyEntry.id != undefined
            ? <Form initialValues={this.props.historyEntry} handleSubmit={this.props.handleSubmit}/>
            : null}
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    historyEntry: getRow(state => state.historyEntry)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    handleSubmit: (historyEntry: HistoryEntry) => {
        const historyEntryId = historyEntry.id
        delete historyEntry.id
        dispatch(CrudAction.execute(historyEntryName, Type.UPDATE, {
            id: historyEntryId,
            data: historyEntry
        }))
    }
})

export const Edit = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditComponent))