// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20026,
    UserRead,
    UserWrite,
} from '../models';

export interface DeleteUserItemRequest {
    id: string;
}

export interface GetUserCollectionRequest {
    id?: Array<string>;
    school?: Array<string>;
    specialties?: Array<string>;
    qualifications?: Array<string>;
    search?: string;
    roles?: Array<string>;
    active?: boolean;
    orderIdentifier?: GetUserCollectionOrderIdentifierEnum;
    orderFirstName?: GetUserCollectionOrderFirstNameEnum;
    orderLastName?: GetUserCollectionOrderLastNameEnum;
    orderLogin?: GetUserCollectionOrderLoginEnum;
    orderEmail?: GetUserCollectionOrderEmailEnum;
    orderActive?: GetUserCollectionOrderActiveEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetUserItemRequest {
    id: string;
}

export interface PostUserCollectionRequest {
    userWrite?: UserWrite;
}

export interface PutUserItemRequest {
    id: string;
    userWrite?: UserWrite;
}

/**
 * no description
 */
export class UserApi extends BaseAPI {

    /**
     * Removes the User resource.
     */
    deleteUserItem({ id }: DeleteUserItemRequest): Observable<void>
    deleteUserItem({ id }: DeleteUserItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteUserItem({ id }: DeleteUserItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteUserItem');

        return this.request<void>({
            url: '/api/users/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of User resources.
     */
    getUserCollection({ id, school, specialties, qualifications, search, roles, active, orderIdentifier, orderFirstName, orderLastName, orderLogin, orderEmail, orderActive, page, itemsPerPage }: GetUserCollectionRequest): Observable<InlineResponse20026>
    getUserCollection({ id, school, specialties, qualifications, search, roles, active, orderIdentifier, orderFirstName, orderLastName, orderLogin, orderEmail, orderActive, page, itemsPerPage }: GetUserCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20026>>
    getUserCollection({ id, school, specialties, qualifications, search, roles, active, orderIdentifier, orderFirstName, orderLastName, orderLogin, orderEmail, orderActive, page, itemsPerPage }: GetUserCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20026 | RawAjaxResponse<InlineResponse20026>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (school != null) { query['school[]'] = school; }
        if (specialties != null) { query['specialties[]'] = specialties; }
        if (qualifications != null) { query['qualifications[]'] = qualifications; }
        if (search != null) { query['search'] = search; }
        if (roles != null) { query['roles[]'] = roles; }
        if (active != null) { query['active'] = active; }
        if (orderIdentifier != null) { query['order[identifier]'] = orderIdentifier; }
        if (orderFirstName != null) { query['order[firstName]'] = orderFirstName; }
        if (orderLastName != null) { query['order[lastName]'] = orderLastName; }
        if (orderLogin != null) { query['order[login]'] = orderLogin; }
        if (orderEmail != null) { query['order[email]'] = orderEmail; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20026>({
            url: '/api/users',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a User resource.
     */
    getUserItem({ id }: GetUserItemRequest): Observable<UserRead>
    getUserItem({ id }: GetUserItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserRead>>
    getUserItem({ id }: GetUserItemRequest, opts?: OperationOpts): Observable<UserRead | RawAjaxResponse<UserRead>> {
        throwIfNullOrUndefined(id, 'id', 'getUserItem');

        return this.request<UserRead>({
            url: '/api/users/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a User resource.
     */
    postUserCollection({ userWrite }: PostUserCollectionRequest): Observable<UserRead>
    postUserCollection({ userWrite }: PostUserCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserRead>>
    postUserCollection({ userWrite }: PostUserCollectionRequest, opts?: OperationOpts): Observable<UserRead | RawAjaxResponse<UserRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<UserRead>({
            url: '/api/users',
            method: 'POST',
            headers,
            body: userWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the User resource.
     */
    putUserItem({ id, userWrite }: PutUserItemRequest): Observable<UserRead>
    putUserItem({ id, userWrite }: PutUserItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserRead>>
    putUserItem({ id, userWrite }: PutUserItemRequest, opts?: OperationOpts): Observable<UserRead | RawAjaxResponse<UserRead>> {
        throwIfNullOrUndefined(id, 'id', 'putUserItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<UserRead>({
            url: '/api/users/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: userWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetUserCollectionOrderIdentifierEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetUserCollectionOrderFirstNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetUserCollectionOrderLastNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetUserCollectionOrderLoginEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetUserCollectionOrderEmailEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetUserCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
