import {Location} from 'history'
import {AuthAction} from 'lib/auth'
import {getLocation, Routes} from 'lib/router'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Redirect} from "react-router";
import {RouteComponentProps, withRouter} from 'react-router-dom'

type Params = {}

type StateToProps = {
    location: Location
}

type DispatchToProps = {
    setRedirect(path: string): void
}

type BeforeRedirectProps = {}

class BeforeRedirectComponent extends React.Component<BeforeRedirectProps & DispatchToProps & RouteComponentProps<Params>> {
    componentDidMount() {
        this.props.setRedirect(this.props.location.pathname)
    }

    public render = () => {
        return <Redirect to={Routes.AuthSignIn}/>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, BeforeRedirectProps, State> = (state, props) => ({
    location: getLocation(state)
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, BeforeRedirectProps> = (dispatch, props) => ({
    setRedirect: (path: string) => dispatch(AuthAction.setRedirectAfterLogin(path))
})

export const BeforeRedirect = withRouter(connect(mapStateToProps, mapDispatchToProps)(BeforeRedirectComponent))