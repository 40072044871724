import {
    CrudAction,
    dispatchAfterFailureEpic,
    dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {State} from 'lib/store'
import {dao, TestAction, TestActionType, testName} from 'lib/test'
import {generatePath} from "react-router";
import {combineEpics, Epic, ofType} from 'redux-observable'
import {of} from "rxjs";
import {catchError, map, switchMap} from 'rxjs/operators'
import {NotificationsAction} from "../notifications";
import {generateCrudPath, ProtectedRoutes} from "../router";

const loadTestEvaluation: Epic<TestAction> = action$ => action$.pipe(
    ofType(TestActionType.execute(testName, 'evaluate')),
    switchMap(({payload}) => dao.evaluate(payload).pipe(
        map((response) => TestAction.executeResponseSuccess(testName, 'evaluate', response)),
        catchError(error => of(TestAction.executeResponseFailure(testName, 'evaluate', error))),
    ))
)

const loadTestSummary: Epic<TestAction> = action$ => action$.pipe(
    ofType(TestActionType.execute(testName, 'summary')),
    switchMap(({payload}) => dao.summary(payload).pipe(
        map((response) => TestAction.executeResponseSuccess(testName, 'summary', response)),
        catchError(error => of(TestAction.executeResponseFailure(testName, 'summary', error))),
    ))
)

const compareTests: Epic<TestAction> = action$ => action$.pipe(
    ofType('[CRUD] Analysis filter'),
    switchMap(({payload}) => {
        return dao.compare({...payload, from: new Date(payload.from), to: new Date(payload.to)}).pipe(
            map((response) => TestAction.compareSuccess(response)),
            catchError(error => of(TestAction.compareFailure(error))),
        )
    })
)

export const testEpics = combineEpics(
    executeCrudEpic(testName, Type.LIST)(dao.list),
    executeCrudEpic(testName, Type.GET)(dao.get),
    executeCrudEpic(testName, Type.CREATE)(dao.create),
    executeCrudEpic(testName, Type.UPDATE)(dao.update),
    executeCrudEpic(testName, Type.DELETE)(dao.delete),


    redirectAfterSuccessEpic(testName, Type.CREATE, (action, state: State) => {
        return generateCrudPath(testName, RouteType.LIST, {parentId: state.students.crud.row.id})
    }),

    redirectAfterSuccessEpic(testName, Type.UPDATE, (action, state: State) => {
        if(action.payload.published) {
            return generateCrudPath(testName, RouteType.SHOW, {parentId: action.payload.student, id: action.payload.id})
        }

        return generatePath(ProtectedRoutes.TestPreview, {parentId: action.payload.student, id: action.payload.id})
    }),

    dispatchAfterSuccessEpic(testName, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nowy test',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(testName, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas dodawania nowego testu',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(testName, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Stan testu został zapisany',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(testName, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas zapisu testu',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(testName, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Test został usunięty',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(testName, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas usuwania testu',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(testName, Type.DELETE, (action, state: State) => CrudAction.execute(testName, Type.LIST, {
        orderName: state.tests.crud.listOptions.sortDirection,
        page: state.tests.crud.listOptions.page + 1,
        itemsPerPage: state.tests.crud.listOptions.pageSize,
        ...state.tests.crud.filters,
    })),

    dispatchAfterFilterEpic(testName, (action, state: State) => CrudAction.execute(testName, Type.LIST, {
        orderName: state.tests.crud.listOptions.sortDirection,
        page: state.tests.crud.listOptions.page + 1,
        itemsPerPage: state.tests.crud.listOptions.pageSize,
        ...state.tests.crud.filters,
    })),

    loadTestEvaluation,
    loadTestSummary,
    compareTests,
)