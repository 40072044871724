import {Subject} from 'lib/subjects'
import * as React from 'react'
import {name as subjectCategoryName} from "lib/subjectsCategory";
import {Enum} from "app/Components/Enum";
import {Typography} from "@material-ui/core";

type SubjectLabelProps = {
    subject: Subject
}

export class SubjectLabel extends React.Component<SubjectLabelProps> {
    public render = () => <><Typography variant={"subtitle1"}>{this.props.subject.name}</Typography> <Enum name={subjectCategoryName} id={this.props.subject.category}/></>
}