import {Metadata} from 'lib/dto'

export type GroupClass = {
    id: string
    group: string
    weeklySessions: number
    duration: string
    teacher: string
    subject: string
    metadata?: Metadata
}

export const newGroupClass = (groupId: string): GroupClass => ({
    id: '',
    group: groupId,
    weeklySessions: 0,
    duration: '',
    teacher: '',
    subject: ''
})
