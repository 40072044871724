// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse2009,
    RoleRead,
} from '../models';

export interface GetRoleCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetRoleItemRequest {
    id: string;
}

/**
 * no description
 */
export class RoleApi extends BaseAPI {

    /**
     * Retrieves the collection of Role resources.
     */
    getRoleCollection({ page, itemsPerPage }: GetRoleCollectionRequest): Observable<InlineResponse2009>
    getRoleCollection({ page, itemsPerPage }: GetRoleCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2009>>
    getRoleCollection({ page, itemsPerPage }: GetRoleCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2009 | RawAjaxResponse<InlineResponse2009>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2009>({
            url: '/api/roles',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Role resource.
     */
    getRoleItem({ id }: GetRoleItemRequest): Observable<RoleRead>
    getRoleItem({ id }: GetRoleItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<RoleRead>>
    getRoleItem({ id }: GetRoleItemRequest, opts?: OperationOpts): Observable<RoleRead | RawAjaxResponse<RoleRead>> {
        throwIfNullOrUndefined(id, 'id', 'getRoleItem');

        return this.request<RoleRead>({
            url: '/api/roles/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
