// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20018,
    SubjectCategoryRead,
} from '../models';

export interface GetSubjectCategoryCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetSubjectCategoryItemRequest {
    id: string;
}

/**
 * no description
 */
export class SubjectCategoryApi extends BaseAPI {

    /**
     * Retrieves the collection of SubjectCategory resources.
     */
    getSubjectCategoryCollection({ page, itemsPerPage }: GetSubjectCategoryCollectionRequest): Observable<InlineResponse20018>
    getSubjectCategoryCollection({ page, itemsPerPage }: GetSubjectCategoryCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20018>>
    getSubjectCategoryCollection({ page, itemsPerPage }: GetSubjectCategoryCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20018 | RawAjaxResponse<InlineResponse20018>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20018>({
            url: '/api/subject-categories',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a SubjectCategory resource.
     */
    getSubjectCategoryItem({ id }: GetSubjectCategoryItemRequest): Observable<SubjectCategoryRead>
    getSubjectCategoryItem({ id }: GetSubjectCategoryItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<SubjectCategoryRead>>
    getSubjectCategoryItem({ id }: GetSubjectCategoryItemRequest, opts?: OperationOpts): Observable<SubjectCategoryRead | RawAjaxResponse<SubjectCategoryRead>> {
        throwIfNullOrUndefined(id, 'id', 'getSubjectCategoryItem');

        return this.request<SubjectCategoryRead>({
            url: '/api/subject-categories/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
