import * as React from 'react'
import {connect} from 'react-redux'
import {Box, Paper, Typography} from '@material-ui/core'

type TermsOfServiceProps = {}

class TermsOfServiceComponent extends React.Component<TermsOfServiceProps> {

    public render() {
        return (
            <>
                <Box mb={2} mt={3} lineHeight={"1.6"}>
                    <Paper variant="outlined" style={{padding: "20px", paddingRight: "50px", maxWidth: "800px", textAlign: "justify", textJustify: "inter-word"}}>
                        <Typography component="h1" variant="h4" align="center" gutterBottom>
                            REGULAMIN ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ ZA POŚREDNICTWEM PORTALU SŁONECZNAPLATFORMA.PL
                        </Typography>
                        <Typography component="h2" variant="h4" align="center" gutterBottom>
                            § 1. Postanowienia ogólne
                        </Typography>
                        <ol>
                            <li>Niniejszy regulamin (dalej: „Regulamin”) określa zasady, warunki i zakres korzystania z Portalu internetowego pod adresem www.slonecznaplatforma.pl (dalej jako „Portal”).</li>
                            <li>Portal stanowi miejsce, w którym Użytkownik może zapoznać się z Usługami Portalu oraz uzyskać dostęp do danych osobowych podopiecznych Placówek oświatowych, które współpracują z właściciel Portalu.</li>
                            <li>Niniejszy Regulamin jest regulaminem w rozumieniu art. 8 ust. 1 pkt 1 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2020 r. poz. 344 t.j.). Do Regulaminu mają również zastosowanie przepisy Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE Rozporządzenia ogólnego o ochronie danych osobowych (dalej: „RODO”).</li>
                            <li>Właścicielem, administratorem oraz operatorem Portalu jest Pro Homine Instytut Wspierania Edukacji Słoneczna Kraina Spółka z ograniczoną odpowiedzialnością Spółka komandytowa, z siedzibą przy ul. Bielskiej 64, 43-400 Cieszyn, NIP: 5482664958, REGON: 243443858, KRS: 0000492057 (dalej jako: „Administrator”).</li>
                            <li>Ilekroć w Regulaminie jest mowa o:
                                <ol type={"a"}>
                                    <li>Użytkowniku lub Kliencie – należy przez to rozumieć osobę fizyczną posiadającą pełną zdolność do czynności prawnych (pełnoletnią), która korzysta z Platformy, na zasadach określonych Regulaminem i obowiązującymi w tym zakresie przepisami prawa.</li>
                                    <li>Konsumencie – należy przez to rozumieć osobę fizyczną (Użytkownika), korzystającą z Portalu, w celu niezwiązanym bezpośrednio jej działalnością gospodarczą lub zawodową.</li>
                                    <li>Podopiecznym – należy przez to rozumieć osobę fizyczną małoletnią, nad którym opiekę prawną sprawuje Użytkownik, a na rzecz której prowadzone są zajęcia przez Placówkę oświatową.</li>
                                    <li>Placówce oświatowej – należy przez to rozumieć ProHomine Instytut Wspierania Edukacji</li>
                                    <li>Portalu – należy przez to rozumieć platformę diagnostyczno-informacyjną slonecznaplatforma.pl.</li>
                                    <li>Usłudze – należy przez to rozumieć ogół usług oferowanych przez Portal.</li>
                                    <li>Danych osobowych – należy przez to rozumieć wszelkie informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej, zgodnie z art. 4 pkt. 1) RODO.</li>
                                    <li>Danych osobowych szczególnych kategorii (tzw. danych wrażliwych) – należy przez to rozumieć dane osobowe ujawniające m.in. stan zdrowia, w rozumieniu art. 9 pkt. 1) RODO.</li>
                                    <li>Koncie Użytkownika – należy przez to rozumieć zarejestrowane na Platformie konto Użytkownika.</li>
                                </ol>
                            </li>
                            <li>Administrator jest uprawniony w każdym czasie do zmiany funkcjonalności Portalu, jak również ich rozszerzenia. Użytkownik przyjmuje do wiadomości, że Portal jest systemem podlegającym procesowi rozwoju technicznego i technologicznego, a Administrator dąży do jej ciągłego i systematycznego ulepszania, co wiąże się dokonywaniem jej aktualizacji i zmian oprogramowania.</li>
                            <li>Akceptując Regulamin, Użytkownik zawiera z Administratorem umowę o świadczenie usług drogą elektroniczną przy użyciu Portalu na czas nieokreślony i w zakresie, i na warunkach określonych w Regulaminie.</li>
                            <li>Korzystanie z Portalu:
                                <ol type={"a"}>
                                    <li>jest możliwe po zalogowaniu się do Konta Użytkownika.</li>
                                    <li>oznacza akceptację treści niniejszego Regulaminu przez Użytkownika. W przypadku braku zgody Użytkownika na akceptację Regulaminu, należy zaprzestać korzystania z niego. Akceptacja Regulaminu oznacza, że Użytkownik zapoznał się z jego treścią, rozumie ją i będzie go stosował. Ponadto oznacza, że Użytkownik zobowiązuje się przekazywać wyłącznie prawdziwe dane osobowe i informacje.</li>
                                </ol>
                            </li>
                        </ol>
                        <Typography component="h2" variant="h4" align="center" gutterBottom>
                            § 2. Rodzaje usług oraz wymagania niezbędne do korzystania z Portalu
                        </Typography>
                        <ol>
                            <li>Za pośrednictwem Portalu Administrator świadczy następujące Usługi: umożliwienie Użytkownikom szybkiego dostępu do danych dotyczących diagnozy oraz udzielanej terapii.</li>
                            <li>Do korzystania z Portalu niezbędne jest:
                                <ol type={"a"}>
                                    <li>posiadanie urządzenia końcowego (komputer lub inne urządzenie multimedialne) z dostępem do Internetu.</li>
                                    <li>posiadanie przeglądarki internetowej typu Microsoft Internet Explorer min. 11.0, Google Chrome min. 90.0.0, Mozilla Firefox min. 88.0.0, Opera min. 76.0.0 lub Safari min. 14.0 we wskazanych lub nowszych wersjach z obsługą JavaScript oraz umożliwiającą zapisywanie cookies.</li>
                                    <li>posiadanie aktywnego konta poczty elektronicznej (e-mail) oraz aktywnego numeru telefonii komórkowej.</li>
                                    <li>zalogowanie się na Portalu. Użytkownik otrzymuje dane do pierwszego logowania w wiadomości e-mail doręczonej przez Administratora, na adres poczty elektronicznej przekazany przez Placówkę oświatową.</li>
                                </ol>
                            </li>
                            <li>Administrator nie jest dostawcą usług transmisji danych. Koszty transmisji danych wymaganych do korzystania z Portalu obciążają Użytkownika w ramach obowiązującej go taryfy zgodnie z umową, jaką Użytkownik zawarł ze swoim operatorem telekomunikacyjnym lub dostawcą usług internetowych. W tym zakresie Administrator nie ponosi odpowiedzialności za wysokość opłat, jakie zostaną naliczone z tytułu wykorzystania transmisji danych niezbędnych do działania, funkcjonowania i korzystania z Portalu.</li>
                        </ol>
                        <Typography component="h2" variant="h4" align="center" gutterBottom>
                            § 3. Zasady korzystania z Portalu i rezygnacji z korzystania z Portalu
                        </Typography>
                        <ol>
                            <li>Użytkownik zobowiązany jest do korzystania z Portalu:
                                <ol type={"a"}>
                                    <li>w sposób zgodny z obowiązującym prawem, niniejszym Regulaminem i regulaminami witryn/serwisów, w których Portal został udostępniony.</li>
                                    <li>w sposób zgodny z zasadami współżycia społecznego, w tym ogólnymi zasadami korzystania z sieci Internet.</li>
                                    <li>w sposób niezakłócający jego funkcjonowanie.</li>
                                    <li>w sposób nieuciążliwy dla innych Użytkowników oraz operatora usługi dostępu do sieci Internet.</li>
                                    <li>z poszanowaniem dóbr osobistych osób trzecich (w tym prawa do prywatności) i wszelkich innych przysługującym im praw.</li>
                                </ol>
                            </li>
                            <li>Użytkownik zobowiązany jest do korzystania z wszelkich informacji i materiałów udostępnionych za pośrednictwem Portalu jedynie w zakresie dozwolonego użytku.</li>
                            <li>Zgodnie z art. 8 ust. 3 pkt 2 lit. b ustawy z dnia 18 lipca 2002 r. oświadczeniu usług drogą elektroniczną Użytkownik nie może wprowadzać do Portalu treści o charakterze bezprawnym.</li>
                            <li>Użytkownik korzysta z Portalu dobrowolnie, na własną odpowiedzialność i powinien zapewnić możliwość poprawnego funkcjonowania Portalu w zakresie, w jakim jest to zależne od Użytkownika.</li>
                            <li>Korzystanie z Portalu z użyciem fałszywych danych lub podszywanie się pod osobą trzecią jest prawnie zabronione pod rygorem odpowiedzialności cywilnej i karnej.</li>
                            <li>Użytkownik jest zobowiązany niezwłocznie powiadomić Administratora o każdym przypadku naruszenia praw w związku z korzystaniem z Portalu.</li>
                            <li>W przypadku stwierdzenia, że Użytkownik dopuszcza się działań zabronionych prawem lub Regulaminem, albo naruszających zasady współżycia społecznego lub godzących w usprawiedliwiony interes Administratora, Administrator może podjąć wszelkie działania przewidziane prawem, w tym ograniczyć możliwość korzystania przez Użytkownika z Portalu i świadczonych Usług.</li>
                        </ol>
                        <Typography component="h2" variant="h4" align="center" gutterBottom>
                            § 4. Odpowiedzialność
                        </Typography>
                        <ol>
                            <li>Administrator zapewnia ciągły dostęp do Portalu, jednak zastrzega sobie prawo do stosowania przerw lub ograniczeń w funkcjonalności Portalu wskutek przyczyn technicznych. Ograniczenia lub przerwy techniczne mogą również wystąpić w systemach/witrynach/serwisach, z których korzysta Portal, co może skutkować czasową niedostępnością pewnych Usług, czy zalogowania się do Portalu. Administrator dołoży wszelkich starań, aby ograniczenia z korzystania z Portalu czy przerwy techniczne, trwały jak najkrócej.</li>
                            <li>Administrator nie ponosi odpowiedzialności z tytułu niepoprawnego działania Portalu lub przerw w funkcjonowaniu, gdy do przerwy w działaniu doszło z przyczyn niezależnych od Administratora dokłada wszelkich starań, by Portal działał nieprzerwanie bezbłędnie i w sposób umożliwiający korzystanie z Portalu.</li>
                            <li>Administrator nie ponosi odpowiedzialności za ograniczenia lub przerwy techniczne w systemach teleinformatycznych, z których korzystają urządzenia mobilne Użytkowników, a które uniemożliwiają lub ograniczają Użytkownikom korzystanie z Portalu.</li>
                            <li>Administrator nie ponosi odpowiedzialności za poprawność działania Portalu w urządzeniu należącym do Użytkownika ani za brak tej poprawności działania.</li>
                            <li>Administrator nie ponosi odpowiedzialności za szkody poniesione przez Użytkownika w wyniku użytkowania Portalu w sposób niezgodny z przeznaczeniem i wbrew postanowieniom Regulaminu.</li>
                            <li>Administrator nie ponosi odpowiedzialności za jakiekolwiek straty bądź szkody poniesione przez Użytkownika bądź osobę trzecią w wyniku korzystania z Portalu. W szczególności nie ponosi odpowiedzialności za jakiekolwiek zdarzenia, na które wpływ miał Użytkownik bądź osoba trzecia.</li>
                        </ol>
                        <Typography component="h2" variant="h4" align="center" gutterBottom>
                            §5. Ochrona danych osobowych i polityka prywatności
                        </Typography>
                        <ol style={{listStyleType: "none"}}>
                            <li>Zasady przetwarzania danych osobowych w ramach Platformy określone zostały w Polityce Prywatności, dostępnej pod adresem: słonecznaplatforma.pl</li>
                        </ol>
                        <Typography component="h2" variant="h4" align="center" gutterBottom>
                            § 6. Procedura reklamacyjna
                        </Typography>
                        <ol>
                            <li>Wszelkie reklamacje dotyczące korzystania z Portalu należy zgłaszać:
                                <ol type={"a"}>
                                    <li>za pośrednictwem poczty elektronicznej na adres e-mail: <a href={"mailto:biuro@prohomineiwe.pl"}>biuro@prohomineiwe.pl</a> (z dopiskiem reklamacja)</li>
                                    <li>pod numerem telefonu: 571 340 744</li>
                                    <li>pisemnie na adres: ProHomine. Instytut Wspierania Edukacji, ul. Bielska 64, 43-400 Cieszyn</li>
                                </ol>
                            </li>
                            <li>Zgłoszenie reklamacji powinno zawierać dane umożliwiające ustalenie Użytkownika oraz złożonego przez niego Zamówienia, jak również wskazywać przyczynę reklamacji, w szczególności rodzaj i daty wystąpienia wady; określenie żądania dotyczącego sposobu usunięcia wady oraz podanie danych kontaktowych Użytkownika składającego reklamację.</li>
                            <li>W terminie 14 dni od dnia otrzymania reklamacji Administrator rozpatrzy reklamację oraz poinformuje Użytkownika o sposobie jej rozpatrzenia.</li>
                        </ol>
                        <Typography component="h2" variant="h4" align="center" gutterBottom>
                            § 7. Własność intelektualna i prawa autorskie
                        </Typography>
                        <ol>
                            <li>Portal, prezentowane materiały graficzne, tekstowe, wizualne i audiowizualne, filmowe, dźwiękowe, ich układ oraz funkcjonalności, rozwiązania systemowe, należą do Administratora lub podmiotów trzecich współpracujących z Administratorem oraz podlegają ochronie na podstawie powszechnie obowiązującego prawa, w tym na podstawie ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych, ustawy z dnia 30 czerwca 2000 r. Prawo własności przemysłowej, a także ustawy z dnia 27 lipca 2001 r. o ochronie baz danych. Oznacza to, że jakiekolwiek korzystanie z tych praw wymaga uzyskania uprzedniej zgody w formie pisemnej. Korzystanie z nich niezgodnie z Regulaminem lub bez uzyskania wymaganej zgody jest zabronione i może stanowić naruszenie prawa oraz może skutkować odpowiedzialnością cywilną lub karną.</li>
                            <li>W szczególności zabronione jest modyfikowanie, kopiowanie, rozpowszechnianie, przesyłanie, prezentowanie, odtwarzanie, powielanie, publikowanie, licencjonowanie lub wykorzystywanie jakichkolwiek praw lub informacji dostępnych na Portalu, w celach komercyjnych. Udostępnienie jakichkolwiek praw, materiałów lub informacji na Portalu nie oznacza udzielenia Użytkownikowi licencji, upoważnienia lub prawa do udzielania dalszych licencji.</li>
                            <li>Żadna część, jak i całość treści, z których Użytkownik korzystał, nie może być powielana i rozpowszechniana, w jakiejkolwiek formie i w jakikolwiek inny sposób (elektroniczny lub mechaniczny), włącznie z kopiowaniem i zamieszczaniem w Internecie. Użycie lub wykorzystanie treści w całości lub w części, bez uzyskania pisemnej zgody, o której powyżej mowa, jest zabronione.</li>
                        </ol>
                        <Typography component="h2" variant="h4" align="center" gutterBottom>
                            § 8. Postanowienia końcowe
                        </Typography>
                        <ol>
                            <li>Portal może zawierać linki do witryn/serwisów zewnętrznych, które prowadzone są przez inne podmioty. Administrator nie ponosi odpowiedzialności za zawartość, bezpieczeństwo czy przetwarzanie danych osobowych przez te witryny/serwisy. Korzystając z takich stron Użytkownik musi zapoznać się z regulaminami i politykami prywatności na nich dostępnymi.</li>
                            <li>Administrator informuje Klienta będącego Konsumentem o możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji Produktów, w tym poprzez złożenie przez Klienta po zakończeniu postępowania reklamacyjnego wniosku o wszczęcie mediacji lub wniosku o rozpatrzenie sprawy przed sądem polubownym (wniosek można pobrać na stronie internetowej http://www.uokik.gov.pl/download.php?plik=6223). Wykaz Stałych Polubownych Sądów Konsumenckich działających przy Wojewódzkich Inspektoratach Inspekcji Handlowej dostępny jest na stronie internetowej: http://www.uokik.gov.pl/wazne_adresy.php#faq596. Pozasądowe dochodzenie roszczeń po zakończeniu postępowania reklamacyjnego jest bezpłatne. W przypadku Klienta będącego Konsumentem lub Przedsiębiorcą na prawach Konsumenta, chcącego skorzystać z pozasądowego sposobu dochodzenia roszczeń, istnieje dodatkowo możliwość złożenia skargi za pośrednictwem unijnej platformy internetowej ODR, dostępnej pod adresem http://ec.europa.eu/consumers/odr/.</li>
                            <li>Administrator zastrzega sobie prawo zawieszenia w dowolnym czasie i z dowolnych przyczyn działalności Portalu lub poszczególnych funkcjonalności, a także prawo zmiany, wycofania lub dodania nowych Usług.</li>
                            <li>Administrator może wydawać dodatkowe regulaminy i warunki regulujące świadczone Usługi.</li>
                            <li>Administrator zastrzega sobie prawo zmiany Regulaminu z ważnych przyczyn, takich jak zmiana powszechnie obowiązujących przepisów prawa mająca bezpośredni wpływ na treść Regulaminu, wydanie orzeczenia lub decyzji, mających bezpośredni wpływ na treść Regulaminu przez sąd lub organ administracji publicznej, wprowadzenie nowych funkcjonalności i usług Portalu, zapobieganie naruszeniom prawa lub naruszeniom Regulaminu, usunięcie niejasności lub wątpliwości interpretacyjnych postanowień Regulaminu. W każdym wypadku zmiana Regulaminu nie ma wpływu na prawa Użytkowników lub obowiązki Administratora, powstałe przed datą związania Użytkowników zmienionym Regulaminem.</li>
                            <li>O zmianie treści Regulaminu Administrator informuje użytkowników poprzez zamieszczenie informacji w Portalu z udostępnieniem nowej treści Regulaminu oraz stosownym zawiadomieniem Użytkowników na wskazane przez nich adresy poczty elektronicznej.</li>
                            <li>Prawem właściwym dla rozstrzygania wszelkich sporów powstałych na gruncie Regulaminu jest prawo polskie. Wszelkie spory powstałe na gruncie Regulaminu, których Stronom nie uda się w pierwszej kolejności rozwiązać ugodowo, podlegają rozstrzygnięciu przez sąd powszechny właściwy miejscowo dla siedziby Administratora, a w odniesieniu do Konsumentów, sąd powszechny właściwy zgodnie z ogólnymi zasadami określonymi przepisami Kodeksu postępowania cywilnego.</li>
                            <li>Nie stanowi zmiany Regulaminu zmiana zawartych w nim danych teleadresowych dotyczących Administratora ani zmiana danych Administratora wynikająca ze zmiany formy prawnej prowadzenia przez niego działalności, w tym tych wskazanych w polityce prywatności.</li>
                            <li>W sprawach nieuregulowanych Regulaminem zastosowanie mają odpowiednie przepisy prawa polskiego, a w szczególności Kodeksu cywilnego i ustawy o świadczeniu usług drogą elektroniczną oraz Prawa Konsumentów i RODO.</li>
                            <li>Regulamin dostępny jest za pośrednictwem Portalu oraz na stronie internetowej pod adresem: www slonecznaplatforma.pl</li>
                            <li>Regulamin obowiązuje od dnia 1.02.2021 r.</li>
                        </ol>
                    </Paper>
                </Box>
            </>
        )
    }
}

export const TermsOfService = connect(null, null)(TermsOfServiceComponent)
