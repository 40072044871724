import {
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import {Preview, RolesEnum, SpecialtiesPreview, UserLabel} from 'app/Components'
import {Tabs} from 'app/Views/Students/Tabs/Tabs'
import {CrudAction, getRow, RouteType, Type} from 'lib/crud'
import {Group} from 'lib/groups'
import {generateCrudPath} from 'lib/router'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {name as studentsName, Student} from "lib/students";
import {name} from 'lib/studentTeacher'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {NavLink, RouteComponentProps} from 'react-router-dom'

type StateToProps = {
    readonly student: Student
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
}

type Params = {
    parentId: string
}

class ParentsComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
    }

    public render = () => (<>
        {/*<CrudShowHeader actionName={name} />*/}
        {this.props.student.id !== undefined ? <>
            <Tabs id={this.props.student.id || ' '} />
            <Card variant="outlined">
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                            <Typography variant={"h4"}>Indywidualni nauczyciele</Typography>
                        </Grid>
                        <Grid alignContent={"flex-end"} item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon/>}
                                component={NavLink}
                                to={generateCrudPath(name, RouteType.ADD, {parentId: this.props.match.params.parentId})}
                            >
                                {'Dodaj'}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="td" style={{width: "50%"}}>Nazwisko i Imię</TableCell>
                                            <TableCell component="td" style={{width: "20%"}}>Rola</TableCell>
                                            <TableCell component="td" style={{width: "30%"}}>Specjalizacje</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={'gray-striped'}>
                                        {this.props.student.individualTeachers?.length ?
                                            <Preview
                                                value={this.props.student.individualTeachers}
                                                source={Source.USERS}
                                                tableRow
                                                colSpan={4}
                                                template={(teacher: User) =>
                                                    <TableRow>
                                                        <TableCell component="td">
                                                            <UserLabel user={teacher}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            <RolesEnum ids={teacher.roles} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <SpecialtiesPreview ids={teacher.specialties}/>
                                                        </TableCell>
                                                    </TableRow>}
                                            />
                                            : <TableCell align={"center"} component="td" colSpan={3}>Brak przypisanych nauczycieli indywidualnych</TableCell>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: 30}}>
                            <Typography variant={"h4"}>Wychowawcy</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="td" style={{width: "50%"}}>Nazwisko i Imię</TableCell>
                                            <TableCell component="td" style={{width: "20%"}}>Rola</TableCell>
                                            <TableCell component="td" style={{width: "30%"}}>Specjalizacje</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={'gray-striped'}>
                                        <Preview
                                            value={this.props.student.groups}
                                            source={Source.GROUPS}
                                            tableRow
                                            colSpan={4}
                                            template={(group: Group) =>
                                                <>
                                                    {group.teachers?.length ?
                                                        <Preview
                                                            value={group.teachers}
                                                            source={Source.USERS}
                                                            tableRow
                                                            colSpan={4}
                                                            template={(teacher: User) => (
                                                                <TableRow>
                                                                    <TableCell component="td">
                                                                        <UserLabel user={teacher} />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <RolesEnum ids={teacher.roles} />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <SpecialtiesPreview ids={teacher.specialties} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        />
                                                        : ''}
                                                </>
                                            }
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </> : null}
    </>
    )
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(ParentsComponent))