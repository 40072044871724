import {DisabilityTypeApi} from 'client/apis'
import {configuration} from 'lib/client'

const DisabilityTypeApiClient = (): DisabilityTypeApi => {
    return new DisabilityTypeApi(configuration)
}

export const dao = {
    list: (payload: any) => DisabilityTypeApiClient().getDisabilityTypeCollection(payload),
    get: (payload: any) => DisabilityTypeApiClient().getDisabilityTypeItem({id: payload.id}),
    create: (payload: any) => DisabilityTypeApiClient().postDisabilityTypeCollection({disabilityTypeWrite: payload}),
    update: (payload: any) => DisabilityTypeApiClient().putDisabilityTypeItem({id: payload.data.id, disabilityTypeWrite: payload.data}),
    delete: (payload: any) => DisabilityTypeApiClient().deleteDisabilityTypeItem({id: payload.id})
}