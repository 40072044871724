// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20022,
    TestTypeRead,
} from '../models';

export interface GetTestTypeCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetTestTypeItemRequest {
    id: string;
}

/**
 * no description
 */
export class TestTypeApi extends BaseAPI {

    /**
     * Retrieves the collection of TestType resources.
     */
    getTestTypeCollection({ page, itemsPerPage }: GetTestTypeCollectionRequest): Observable<InlineResponse20022>
    getTestTypeCollection({ page, itemsPerPage }: GetTestTypeCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20022>>
    getTestTypeCollection({ page, itemsPerPage }: GetTestTypeCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20022 | RawAjaxResponse<InlineResponse20022>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20022>({
            url: '/api/test-types',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a TestType resource.
     */
    getTestTypeItem({ id }: GetTestTypeItemRequest): Observable<TestTypeRead>
    getTestTypeItem({ id }: GetTestTypeItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestTypeRead>>
    getTestTypeItem({ id }: GetTestTypeItemRequest, opts?: OperationOpts): Observable<TestTypeRead | RawAjaxResponse<TestTypeRead>> {
        throwIfNullOrUndefined(id, 'id', 'getTestTypeItem');

        return this.request<TestTypeRead>({
            url: '/api/test-types/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
