import {BackButton} from 'app/Layout/Content'
import {RouteType} from 'lib/crud'
import {NavigationAction} from 'lib/navigation'
import {generateCrudPath, getCrudPathLabels} from 'lib/router'
import * as React from 'react'
import {connect, MapDispatchToProps} from "react-redux";
import {RouteComponentProps, withRouter} from 'react-router-dom'

type Params = {
    parentId?: string
}

type AddProps = {
    actionName: string,
}

type DispatchToProps = {
    readonly setHeader: (actionName: string, type: RouteType) => void,
    readonly setAction: (action: JSX.Element) => void,
}

class CrudAddHeaderComponent extends React.Component<AddProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        this.props.setHeader(this.props.actionName, RouteType.ADD)
        this.props.setAction(<BackButton url={generateCrudPath(this.props.actionName, RouteType.LIST, this.getParams())}/>)
    }

    private getParams = () => {
        return {parentId: this.props.match.params.parentId}
    }

    public render = () => <></>
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    setHeader: (actionName, type) => {
        dispatch(NavigationAction.setHeader(getCrudPathLabels(actionName, type)))
    },
    setAction: (action: JSX.Element) => {
        dispatch(NavigationAction.setAction(action))
    }
})

export const CrudAddHeader =  withRouter(connect(null, mapDispatchToProps)(CrudAddHeaderComponent))