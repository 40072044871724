import {AnyAction, applyMiddleware, compose, createStore, Middleware, Reducer, Store} from 'redux'
import {createEpicMiddleware} from 'redux-observable'
import {routerMiddlewareWithHistory} from 'lib/router'
import {rootEpic, rootReducer, State} from '.'
import {isDevelopment} from 'lib/env'

const epicMiddleware = createEpicMiddleware()

const middleware: ReadonlyArray<Middleware> = [
    routerMiddlewareWithHistory,
    epicMiddleware,
]

const composeEnhancers = isDevelopment && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancer = composeEnhancers(applyMiddleware(...middleware))

export const store = (): Store<State> => {
    const storeTemp = createStore<State, AnyAction, any, any>((rootReducer as Reducer<State>), enhancer) // tslint:disable-line:no-any
    epicMiddleware.run(rootEpic)

    return storeTemp
}