import {CrudAction, dispatchAfterFilterEpic, executeCrudEpic, Type} from 'lib/crud'
import {State} from 'lib/store'
import {dao} from 'lib/students'
import {combineEpics} from 'redux-observable'
import {studentSummaryName} from './reducer'

export const studentSummaryEpics = combineEpics(
    executeCrudEpic(studentSummaryName, Type.LIST)(dao.summary),

    dispatchAfterFilterEpic(studentSummaryName, (action, state: State) => CrudAction.execute(studentSummaryName, Type.LIST, {
        id: state.students.crud.row.id || ' ',
        orderName: state.studentSummary.crud.listOptions.sortDirection,
        page: state.studentSummary.crud.listOptions.page + 1,
        itemsPerPage: state.studentSummary.crud.listOptions.pageSize,
        ...state.studentSummary.crud.filters
    }))
)