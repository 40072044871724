import {CrudAddHeader} from 'app/Components'
import {CrudAction, Type} from 'lib/crud'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {Form} from './Form'
import {Student, name, newStudent} from "lib/students";

type DispatchToProps = {
    readonly handleSubmit: (student: Student) => void,
}

type AddProps = DispatchToProps

class AddComponent extends React.Component<AddProps> {
    public render = () => (<>
        <CrudAddHeader actionName={name}/>
        <Form initialValues={newStudent()} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (student: Student) => {
        delete student.id
        dispatch(CrudAction.execute(name, Type.CREATE, student))
    },
})

export const Add = connect(null, mapDispatchToProps)(AddComponent)