import DocumentsIcon from '@material-ui/icons/DescriptionOutlined'
import ChildrenIcon from '@material-ui/icons/FaceOutlined'
import TestIcon from '@material-ui/icons/FormatListNumberedOutlined'
import HistoryIcon from '@material-ui/icons/HistoryOutlined'
import SubjectIcon from '@material-ui/icons/LibraryBooksOutlined'
import TeachersIcon from '@material-ui/icons/PeopleOutlined'
import ParentsIcon from '@material-ui/icons/SupervisorAccountOutlined'
import SummaryIcon from '@material-ui/icons/EqualizerOutlined'
import {Bookmarks} from 'app/Components'
import {RouteType} from 'lib/crud'
import {name as historyEntryName} from 'lib/historyEntry'
import {name as parentsName} from 'lib/parents'
import {generateCrudPath} from 'lib/router'
import {name as studentFilesName} from 'lib/studentFiles'
import {name as studentsName} from 'lib/students'
import {name as studentSubjectName} from 'lib/studentSubject'
import {name as studentTeacherName} from 'lib/studentTeacher'
import {testName} from 'lib/test'
import * as React from 'react'
import {studentSummaryName} from 'lib/studentSummary'
import {User} from 'lib/users'
import {connect, MapStateToProps} from "react-redux";
import {me, Role} from 'lib/auth'
import {State} from 'lib/store'

type StateToProps = {
    readonly me: User
}

type TabsProps = {
    id: string
}

class TabsComponent extends React.Component<StateToProps & TabsProps> {
    public render = () => {
        const bookmarks = [
            {
                label: 'Dane podstawowe',
                icon: <ChildrenIcon/>,
                to: generateCrudPath(studentsName, RouteType.SHOW, {id: this.props.id}),
                exact: true
            },
            {
                label: 'Rodzice',
                icon: <ParentsIcon/>,
                to: generateCrudPath(parentsName, RouteType.LIST, {parentId: this.props.id})
            },
            {
                label: 'Kadra',
                icon: <TeachersIcon/>,
                to: generateCrudPath(studentTeacherName, RouteType.LIST, {parentId: this.props.id})
            },
            {
                label: 'Zajęcia',
                icon: <SubjectIcon/>,
                to: generateCrudPath(studentSubjectName, RouteType.LIST, {parentId: this.props.id})
            },
            {
                label: 'Testy',
                icon: <TestIcon/>,
                to: generateCrudPath(testName, RouteType.LIST, {parentId: this.props.id})
            },
            {
                label: 'Historia',
                icon: <HistoryIcon/>,
                to: generateCrudPath(historyEntryName, RouteType.LIST, {parentId: this.props.id})
            },
            {
                label: 'Dokumenty',
                icon: <DocumentsIcon/>,
                to: generateCrudPath(studentFilesName, RouteType.LIST, {parentId: this.props.id})
            },
            {
                label: 'Podsumowanie',
                icon: <SummaryIcon/>,
                to: generateCrudPath(studentSummaryName, RouteType.LIST, {parentId: this.props.id})
            },
        ]

        if (this.props.me.roles.includes(Role.ROLE_PARENT)) {
            delete bookmarks[2]
            delete bookmarks[4]
            delete bookmarks[5]
            delete bookmarks[7]
        }

        return <Bookmarks bookmarks={bookmarks}/>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    me: me(state)!
})

export const Tabs = connect(mapStateToProps)(TabsComponent)