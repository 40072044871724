import {AnyAction} from 'redux'
import {ReloadActionType} from 'lib/reload'

export type ReloadState = {}

const initialState = {}

export function reloadReducer(state: ReloadState = initialState, action: AnyAction): ReloadState {
    switch (action.type) {
        case ReloadActionType.Reload:
            window.location.reload(false);
        default:
            return state
    }
}
