import {AnyAction} from 'redux'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'

export const name = 'STUDENT_FILE'

export type StudentFileState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function studentFileReducer(state: StudentFileState = initialState, action: AnyAction): StudentFileState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
