import {configuration} from 'lib/client'
import {DisabilityTypeApi, EventApi, QualificationApi, SpecialtyApi} from 'client/apis'
import {throwError} from "rxjs";
import {Dictionaries} from 'lib/dictionaries'

const SpecialtyApiClient = (): SpecialtyApi => {
    return new SpecialtyApi(configuration)
}

const DisabilityTypeApiClient = (): DisabilityTypeApi => {
    return new DisabilityTypeApi(configuration)
}

const EventApiClient = (): EventApi => {
    return new EventApi(configuration)
}

const QualificationApiClient = (): QualificationApi => {
    return new QualificationApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        switch (payload.type) {
            case Dictionaries.Specialty: return SpecialtyApiClient().getSpecialtyCollection(payload.data)
            case Dictionaries.DisabilityType: return DisabilityTypeApiClient().getDisabilityTypeCollection(payload.data)
            case Dictionaries.Event: return EventApiClient().getEventCollection(payload.data)
            case Dictionaries.Qualification: return QualificationApiClient().getQualificationCollection(payload.data)
        }

        return throwError('Wrong dictionary type')
    },
    get: (payload: any) => {
        switch (payload.type) {
            case Dictionaries.Specialty: return SpecialtyApiClient().getSpecialtyItem({id: payload.id})
            case Dictionaries.DisabilityType: return DisabilityTypeApiClient().getDisabilityTypeItem({id: payload.id})
            case Dictionaries.Event: return EventApiClient().getEventItem({id: payload.id})
            case Dictionaries.Qualification: return QualificationApiClient().getQualificationItem({id: payload.id})
        }

        return throwError('Wrong dictionary type')
    },
    create: (payload: any) => {
        switch (payload.type) {
            case Dictionaries.Specialty: return SpecialtyApiClient().postSpecialtyCollection({specialtyWrite: payload.data})
            case Dictionaries.DisabilityType: return DisabilityTypeApiClient().postDisabilityTypeCollection({disabilityTypeWrite: payload.data})
            case Dictionaries.Event: return EventApiClient().postEventCollection({eventWrite: payload.data})
            case Dictionaries.Qualification: return QualificationApiClient().postQualificationCollection({qualificationWrite: payload.data})
        }

        return throwError('Wrong dictionary type')
    },
    update: (payload: any) => {
        switch (payload.type) {
            case Dictionaries.Specialty: return SpecialtyApiClient().putSpecialtyItem({id: payload.data.id, specialtyWrite: payload.data.data})
            case Dictionaries.DisabilityType: return DisabilityTypeApiClient().putDisabilityTypeItem({id: payload.data.id, disabilityTypeWrite: payload.data.data})
            case Dictionaries.Event: return EventApiClient().putEventItem({id: payload.data.id, eventWrite: payload.data.data})
            case Dictionaries.Qualification: return QualificationApiClient().putQualificationItem({id: payload.data.id, qualificationWrite: payload.data.data})
        }

        return throwError('Wrong dictionary type')
    },
    delete: (payload: any) => {
        switch (payload.type) {
            case Dictionaries.Specialty: return SpecialtyApiClient().deleteSpecialtyItem(payload.data)
            case Dictionaries.DisabilityType: return DisabilityTypeApiClient().deleteDisabilityTypeItem(payload.data)
            case Dictionaries.Event: return EventApiClient().deleteEventItem(payload.data)
            case Dictionaries.Qualification: return QualificationApiClient().deleteQualificationItem(payload.data)
        }

        return throwError('Wrong dictionary type')
    }
}