import {Typography} from '@material-ui/core'
import {BackButton} from 'app/Layout/Content'
import {CrudAction, getRow, RouteType, Type} from 'lib/crud'
import {Dictionary, name} from 'lib/dictionaries'
import {NavigationAction} from 'lib/navigation'
import {generateCrudPath, getCrudPathLabels} from 'lib/router'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {Form} from './Form'
import {Top} from './Top'

type StateToProps = {
    readonly dictionary: Dictionary
}

type DispatchToProps = {
    readonly loadDictionary: (id: string, type: string) => void,
    readonly handleSubmit: (type: string) => (dictionary: Dictionary) => void,
    readonly setHeader: (actionName: string, type: RouteType) => void,
    readonly setAction: (action: JSX.Element) => void,
}

type Params = {
    id: string
    type: string
}

type EditProps = StateToProps & DispatchToProps & RouteComponentProps<Params>

type EditState = {}

class EditComponent extends React.Component<EditProps, EditState> {
    public componentDidMount(): void {
        this.props.setHeader(this.props.match.params.type, RouteType.EDIT)
        this.props.setAction(<BackButton url={generateCrudPath(this.props.match.params.type, RouteType.LIST)}/>)
        this.props.loadDictionary(this.props.match.params.id, this.props.match.params.type)
    }

    public render = () => <Form
        initialValues={this.props.dictionary}
        handleSubmit={this.props.handleSubmit(this.props.match.params.type)}
    />
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    dictionary: getRow(state => state.dictionaries)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    loadDictionary: (id: string, type: string) => {
        dispatch(CrudAction.execute(name, Type.GET, {id, type}))
    },
    handleSubmit: (type: string) => (dictionary: Dictionary) => {
        dispatch(CrudAction.execute(name, Type.UPDATE, {
            type: type,
            data: {
                id: dictionary.id,
                data: {
                    name: dictionary.name,
                    active: dictionary.active
                }
            }
        }))
    },
    setHeader: (actionName, type) => {
        dispatch(NavigationAction.setHeader(getCrudPathLabels(actionName, type)))
    },
    setAction: (action: JSX.Element) => {
        dispatch(NavigationAction.setAction(action))
    }
})

export const Edit = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditComponent))