// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20025,
    UserPictureRead,
} from '../models';

export interface ContentUserPictureItemRequest {
    id: string;
}

export interface DeleteUserPictureItemRequest {
    id: string;
}

export interface GetUserPictureCollectionRequest {
    id?: Array<string>;
    user?: Array<string>;
    page?: number;
    itemsPerPage?: number;
}

export interface GetUserPictureItemRequest {
    id: string;
}

export interface PostUserPictureCollectionRequest {
    user?: string;
    file?: Blob;
}

/**
 * no description
 */
export class UserPictureApi extends BaseAPI {

    /**
     * Retrieves a UserPicture resource.
     */
    contentUserPictureItem({ id }: ContentUserPictureItemRequest): Observable<Blob>
    contentUserPictureItem({ id }: ContentUserPictureItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Blob>>
    contentUserPictureItem({ id }: ContentUserPictureItemRequest, opts?: OperationOpts): Observable<Blob | RawAjaxResponse<Blob>> {
        throwIfNullOrUndefined(id, 'id', 'contentUserPictureItem');

        return this.request<Blob>({
            url: '/api/user-pictures/{id}/content'.replace('{id}', encodeURI(id)),
            method: 'GET',
            responseType: 'blob',
        }, opts?.responseOpts);
    };

    /**
     * Removes the UserPicture resource.
     */
    deleteUserPictureItem({ id }: DeleteUserPictureItemRequest): Observable<void>
    deleteUserPictureItem({ id }: DeleteUserPictureItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteUserPictureItem({ id }: DeleteUserPictureItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteUserPictureItem');

        return this.request<void>({
            url: '/api/user-pictures/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of UserPicture resources.
     */
    getUserPictureCollection({ id, user, page, itemsPerPage }: GetUserPictureCollectionRequest): Observable<InlineResponse20025>
    getUserPictureCollection({ id, user, page, itemsPerPage }: GetUserPictureCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20025>>
    getUserPictureCollection({ id, user, page, itemsPerPage }: GetUserPictureCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20025 | RawAjaxResponse<InlineResponse20025>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (user != null) { query['user[]'] = user; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20025>({
            url: '/api/user-pictures',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a UserPicture resource.
     */
    getUserPictureItem({ id }: GetUserPictureItemRequest): Observable<UserPictureRead>
    getUserPictureItem({ id }: GetUserPictureItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserPictureRead>>
    getUserPictureItem({ id }: GetUserPictureItemRequest, opts?: OperationOpts): Observable<UserPictureRead | RawAjaxResponse<UserPictureRead>> {
        throwIfNullOrUndefined(id, 'id', 'getUserPictureItem');

        return this.request<UserPictureRead>({
            url: '/api/user-pictures/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a UserPicture resource.
     */
    postUserPictureCollection({ user, file }: PostUserPictureCollectionRequest): Observable<UserPictureRead>
    postUserPictureCollection({ user, file }: PostUserPictureCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserPictureRead>>
    postUserPictureCollection({ user, file }: PostUserPictureCollectionRequest, opts?: OperationOpts): Observable<UserPictureRead | RawAjaxResponse<UserPictureRead>> {

        const formData = new FormData();
        if (user !== undefined) { formData.append('user', user as any); }
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<UserPictureRead>({
            url: '/api/user-pictures',
            method: 'POST',
            body: formData,
        }, opts?.responseOpts);
    };

}
