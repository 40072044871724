import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export const SelectActionType = {
    Add: () => '[SELECT] add',
    Remove: () => '[SELECT] remove',

    LoadOptions: (name: string, source: string) => `[SELECT] load options start (field: ${name}, source: ${source})`,
    LoadNextOptions: () => `[SELECT] load options next`,
    LoadOptionsFailure: (name: string, source: string) => `[SELECT] load options failure (field: ${name}, source: ${source})`,
    LoadOptionsSuccess: (name: string, source: string) => `[SELECT] load options success (field: ${name}, source: ${source})`,

    LoadSelected: (name: string, source: string) => `[SELECT] load selected start (field: ${name}, source: ${source})`,
    LoadSelectedFailure: (name: string, source: string) => `[SELECT] load selected failure (field: ${name}, source: ${source})`,
    LoadSelectedSuccess: (name: string, source: string) => `[SELECT] load selected success (field: ${name}, source: ${source})`,
}

export const SelectAction = {
    add: (name: string) => createAction(SelectActionType.Add(), name),
    remove: (name: string) => createAction(SelectActionType.Remove(), name),

    loadOptions: (name: string, source: string, query: string, options?: any) => createAction(SelectActionType.LoadOptions(name, source), {name, source, query, ...options}),
    loadNextOptions: (name: string, source: string, options: any, response: any) => createAction(SelectActionType.LoadNextOptions(), {name, source, options, response}),
    loadOptionsFailure: (name: string, source: string, error: any) => createAction(SelectActionType.LoadOptionsFailure(name, source), error),
    loadOptionsSuccess: (name: string, source: string, response: any) => createAction(SelectActionType.LoadOptionsSuccess(name, source), response),

    loadSelected: (name: string, source: string, ids: string[], options?: any) => createAction(SelectActionType.LoadSelected(name, source), {name, source, ids, ...options}),
    loadSelectedFailure: (name: string, source: string, error: any) => createAction(SelectActionType.LoadSelectedFailure(name, source), error),
    loadSelectedSuccess: (name: string, source: string, response: any) => createAction(SelectActionType.LoadSelectedSuccess(name, source), response),
}

export type SelectAction = ActionsUnion<typeof SelectAction>
