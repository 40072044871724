import {AnyAction} from 'redux'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'

export const name = 'STUDENT_CATEGORY'

export type StudentCategoryState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function studentCategoryReducer(state: StudentCategoryState = initialState, action: AnyAction): StudentCategoryState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
