// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    Credentials,
    Me,
} from '../models';

export interface LoginRequest {
    credentials?: Credentials;
}

/**
 * no description
 */
export class AuthApi extends BaseAPI {

    /**
     * Log in.
     */
    login({ credentials }: LoginRequest): Observable<Me>
    login({ credentials }: LoginRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Me>>
    login({ credentials }: LoginRequest, opts?: OperationOpts): Observable<Me | RawAjaxResponse<Me>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<Me>({
            url: '/auth/login',
            method: 'POST',
            headers,
            body: credentials,
        }, opts?.responseOpts);
    };

    /**
     * Log out.
     */
    logout(): Observable<void>
    logout(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    logout(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        return this.request<void>({
            url: '/auth/logout',
            method: 'POST',
        }, opts?.responseOpts);
    };

    /**
     * Obtain the logged user ID.
     */
    me(): Observable<Me>
    me(opts?: OperationOpts): Observable<RawAjaxResponse<Me>>
    me(opts?: OperationOpts): Observable<Me | RawAjaxResponse<Me>> {
        return this.request<Me>({
            url: '/auth/me',
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Obtain a new access and refresh token set.
     */
    refreshTokens(): Observable<void>
    refreshTokens(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    refreshTokens(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        return this.request<void>({
            url: '/auth/refresh',
            method: 'POST',
        }, opts?.responseOpts);
    };

}
