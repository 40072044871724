import {Preview, UserLabel} from 'app/Components'
import {Source} from 'lib/select'
import {User} from 'lib/users'
import * as React from 'react'

type UserPreviewProps = {
    id: string,
}

export class UserPreview extends React.Component<UserPreviewProps> {
    public render = () => <Preview
        value={this.props.id}
        source={Source.USERS}
        template={(user: User) => <UserLabel user={user} />}
    />
}
