import {
    Box,
    Checkbox as MuiCheckbox,
    FormControl, Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    index: number
    section: STARETestSection
    preview?: boolean
    prev: null|number
    next: null|number
}

type SectionState = {
    section: STARETestSection
    editableActivity: boolean
}

export type STARETestSection = {
    activity: string
    adults: number
    general: number
    quality: number
    groups: string[]
    objects: number,
    peers: number,
    required: number
}

export class STARESection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section,
        editableActivity: this.props.index > 5
    }

    public handleActivityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.activity = event.target.value;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let keys = event.target.name.split('|')
        const value = event.target.checked ? parseInt(keys[1]) : 0
        switch(keys[0]) {
            case 'general': this.state.section.general = value; break;
            case 'adults': this.state.section.adults = value; break;
            case 'peers': this.state.section.peers = value; break;
            case 'objects': this.state.section.objects = value; break;
            case 'quality': this.state.section.quality = value; break;
        }
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        const isPreview = this.props.preview || false

        return <Box style={isPreview ? {margin: '0px 0px 50px 0px'} : {}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Grid container alignItems="center" spacing={1}>
                                {this.state.editableActivity && !isPreview ? <FormControl margin="dense" fullWidth>
                                        <TextField
                                            name="activity"
                                            variant="outlined"
                                            inputProps={{
                                                maxLength: 50,
                                            }}
                                            value={this.state.section.activity}
                                            onChange={this.handleActivityChange}
                                        />
                                    </FormControl> : this.state.section.activity}
                            </Grid>
                        </TableCell>
                        <TableCell>Prawie w ogóle</TableCell>
                        <TableCell>Krótki czas</TableCell>
                        <TableCell>Połowę czasu</TableCell>
                        <TableCell>Większość czasu</TableCell>
                        <TableCell>Prawie cały czas</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={'gray-striped'}>
                    <TableRow>
                        <TableCell style={isPreview && this.state.section.required && this.state.section.general == 0 ? {color: 'red'} : {}}>
                            Ogólny poziom
                        </TableCell>
                        {[1,2,3,4,5].map((val, index) => <TableCell key={index}>
                            <Checkbox
                                name={'general|' + val}
                                value={this.state.section.general}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            />
                        </TableCell>)}
                    </TableRow>
                    <TableRow>
                        <TableCell style={isPreview && this.state.section.required && this.state.section.adults == 0 ? {color: 'red'} : {}}>
                            Z dorosłym
                        </TableCell>
                        {[1,2,3,4,5].map((val, index) => <TableCell key={index}>
                            <Checkbox
                                name={'adults|' + val}
                                value={this.state.section.adults}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            />
                        </TableCell>)}
                    </TableRow>
                    <TableRow>
                        <TableCell style={isPreview && this.state.section.required && this.state.section.peers == 0 ? {color: 'red'} : {}}>
                            Z rówieśnikami
                        </TableCell>
                        {[1,2,3,4,5].map((val, index) => <TableCell key={index}>
                            <Checkbox
                                name={'peers|' + val}
                                value={this.state.section.peers}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            />
                        </TableCell>)}
                    </TableRow>
                    <TableRow>
                        <TableCell style={isPreview && this.state.section.required && this.state.section.objects == 0 ? {color: 'red'} : {}}>
                            Z rzeczami
                        </TableCell>
                        {[1,2,3,4,5].map((val, index) => <TableCell key={index}>
                            <Checkbox
                                name={'objects|' + val}
                                value={this.state.section.objects}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            />
                        </TableCell>)}
                    </TableRow>
                    <TableRow>
                        <TableCell style={isPreview && this.state.section.required && this.state.section.quality == 0 ? {color: 'red'} : {}}>
                            Jakość
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                name={'quality|1'}
                                value={this.state.section.quality}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            /> Niezaangażowany
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                name={'quality|2'}
                                value={this.state.section.quality}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            /> Nisko
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                name={'quality|3'}
                                value={this.state.section.quality}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            /> Średnio
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                name={'quality|4'}
                                value={this.state.section.quality}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            /> Wysoko
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                name={'quality|5'}
                                value={this.state.section.quality}
                                handleChange={this.handleChange}
                                preview={isPreview}
                            /> Bardzo wysoko
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    }
}

type CheckboxProps = {
    name: string
    value: number
    handleChange: (event: any) => void
    preview: boolean
}

class Checkbox extends React.Component<CheckboxProps> {
    public render = () => {
        const name = this.props.name.split('|')
        return <MuiCheckbox
            name={this.props.name}
            checked={parseInt(name[1]) == this.props.value}
            onChange={this.props.handleChange}
            disabled={this.props.preview}
            color="primary"
        />
    }
}