// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    ClassDurationRead,
    InlineResponse200,
} from '../models';

export interface GetClassDurationCollectionRequest {
    page?: number;
    itemsPerPage?: number;
}

export interface GetClassDurationItemRequest {
    id: string;
}

/**
 * no description
 */
export class ClassDurationApi extends BaseAPI {

    /**
     * Retrieves the collection of ClassDuration resources.
     */
    getClassDurationCollection({ page, itemsPerPage }: GetClassDurationCollectionRequest): Observable<InlineResponse200>
    getClassDurationCollection({ page, itemsPerPage }: GetClassDurationCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse200>>
    getClassDurationCollection({ page, itemsPerPage }: GetClassDurationCollectionRequest, opts?: OperationOpts): Observable<InlineResponse200 | RawAjaxResponse<InlineResponse200>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse200>({
            url: '/api/class-durations',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a ClassDuration resource.
     */
    getClassDurationItem({ id }: GetClassDurationItemRequest): Observable<ClassDurationRead>
    getClassDurationItem({ id }: GetClassDurationItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ClassDurationRead>>
    getClassDurationItem({ id }: GetClassDurationItemRequest, opts?: OperationOpts): Observable<ClassDurationRead | RawAjaxResponse<ClassDurationRead>> {
        throwIfNullOrUndefined(id, 'id', 'getClassDurationItem');

        return this.request<ClassDurationRead>({
            url: '/api/class-durations/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
