import {Metadata} from 'lib/dto'

export type IndividualClass = {
    id: string
    student: string
    weeklySessions: number
    duration: string
    teacher: string
    subject: string
    metadata?: Metadata
}

export const newIndividualClass = (studentId: string): IndividualClass => ({
    id: '',
    student: studentId,
    weeklySessions: 0,
    duration: '',
    teacher: '',
    subject: ''
})
