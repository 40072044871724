// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    DisabilityTypeRead,
    DisabilityTypeWrite,
    InlineResponse2001,
} from '../models';

export interface DeleteDisabilityTypeItemRequest {
    id: string;
}

export interface GetDisabilityTypeCollectionRequest {
    id?: Array<string>;
    name?: string;
    active?: boolean;
    orderName?: GetDisabilityTypeCollectionOrderNameEnum;
    orderActive?: GetDisabilityTypeCollectionOrderActiveEnum;
    orderPosition?: GetDisabilityTypeCollectionOrderPositionEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetDisabilityTypeItemRequest {
    id: string;
}

export interface PostDisabilityTypeCollectionRequest {
    disabilityTypeWrite?: DisabilityTypeWrite;
}

export interface PutDisabilityTypeItemRequest {
    id: string;
    disabilityTypeWrite?: DisabilityTypeWrite;
}

/**
 * no description
 */
export class DisabilityTypeApi extends BaseAPI {

    /**
     * Removes the DisabilityType resource.
     */
    deleteDisabilityTypeItem({ id }: DeleteDisabilityTypeItemRequest): Observable<void>
    deleteDisabilityTypeItem({ id }: DeleteDisabilityTypeItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteDisabilityTypeItem({ id }: DeleteDisabilityTypeItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteDisabilityTypeItem');

        return this.request<void>({
            url: '/api/disability-types/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of DisabilityType resources.
     */
    getDisabilityTypeCollection({ id, name, active, orderName, orderActive, orderPosition, page, itemsPerPage }: GetDisabilityTypeCollectionRequest): Observable<InlineResponse2001>
    getDisabilityTypeCollection({ id, name, active, orderName, orderActive, orderPosition, page, itemsPerPage }: GetDisabilityTypeCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse2001>>
    getDisabilityTypeCollection({ id, name, active, orderName, orderActive, orderPosition, page, itemsPerPage }: GetDisabilityTypeCollectionRequest, opts?: OperationOpts): Observable<InlineResponse2001 | RawAjaxResponse<InlineResponse2001>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (name != null) { query['name'] = name; }
        if (active != null) { query['active'] = active; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (orderPosition != null) { query['order[position]'] = orderPosition; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse2001>({
            url: '/api/disability-types',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a DisabilityType resource.
     */
    getDisabilityTypeItem({ id }: GetDisabilityTypeItemRequest): Observable<DisabilityTypeRead>
    getDisabilityTypeItem({ id }: GetDisabilityTypeItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<DisabilityTypeRead>>
    getDisabilityTypeItem({ id }: GetDisabilityTypeItemRequest, opts?: OperationOpts): Observable<DisabilityTypeRead | RawAjaxResponse<DisabilityTypeRead>> {
        throwIfNullOrUndefined(id, 'id', 'getDisabilityTypeItem');

        return this.request<DisabilityTypeRead>({
            url: '/api/disability-types/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a DisabilityType resource.
     */
    postDisabilityTypeCollection({ disabilityTypeWrite }: PostDisabilityTypeCollectionRequest): Observable<DisabilityTypeRead>
    postDisabilityTypeCollection({ disabilityTypeWrite }: PostDisabilityTypeCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<DisabilityTypeRead>>
    postDisabilityTypeCollection({ disabilityTypeWrite }: PostDisabilityTypeCollectionRequest, opts?: OperationOpts): Observable<DisabilityTypeRead | RawAjaxResponse<DisabilityTypeRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<DisabilityTypeRead>({
            url: '/api/disability-types',
            method: 'POST',
            headers,
            body: disabilityTypeWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the DisabilityType resource.
     */
    putDisabilityTypeItem({ id, disabilityTypeWrite }: PutDisabilityTypeItemRequest): Observable<DisabilityTypeRead>
    putDisabilityTypeItem({ id, disabilityTypeWrite }: PutDisabilityTypeItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<DisabilityTypeRead>>
    putDisabilityTypeItem({ id, disabilityTypeWrite }: PutDisabilityTypeItemRequest, opts?: OperationOpts): Observable<DisabilityTypeRead | RawAjaxResponse<DisabilityTypeRead>> {
        throwIfNullOrUndefined(id, 'id', 'putDisabilityTypeItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<DisabilityTypeRead>({
            url: '/api/disability-types/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: disabilityTypeWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetDisabilityTypeCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetDisabilityTypeCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetDisabilityTypeCollectionOrderPositionEnum {
    Asc = 'asc',
    Desc = 'desc'
}
