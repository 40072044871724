import {configuration} from 'lib/client'
import {StudentFileTypeApi} from 'client/apis'

const StudentFileTypeApiClient = (): StudentFileTypeApi => {
    return new StudentFileTypeApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return StudentFileTypeApiClient().getStudentFileTypeCollection(payload)
    },
    get: (payload: any) => {
        return StudentFileTypeApiClient().getStudentFileTypeItem({id: payload.id})
    },
}