import {Chip} from '@material-ui/core'
import InactiveIcon from '@material-ui/icons/CancelOutlined'
import ActiveIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import React, {FunctionComponent} from 'react'

type StatusProps = {
    readonly status: boolean
    readonly activeLabel?: string
    readonly inactiveLabel?: string
}

export const Status: FunctionComponent<StatusProps> = props => {
    return <>{props.status
        ? <Chip
            icon={<ActiveIcon style={{color: '#ffffff'}} />}
            label={props.activeLabel || 'Tak'}
            style={{background: 'rgba(67, 160, 71, 0.6)', color: '#ffffff'}}
        />
        : <Chip
            icon={<InactiveIcon style={{color: '#ffffff'}} />}
            label={props.inactiveLabel || 'Nie'}
            style={{background: 'rgba(220, 0, 78, 0.6)', color: '#ffffff'}}
        />}
    </>
}