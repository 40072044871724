import {SchoolTypeApi} from 'client/apis'
import {configuration} from 'lib/client'

const SchoolTypeApiClient = (): SchoolTypeApi => {
    return new SchoolTypeApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return SchoolTypeApiClient().getSchoolTypeCollection(payload)
    },
    get: (payload: any) => {
        return SchoolTypeApiClient().getSchoolTypeItem({id: payload.id})
    },
}