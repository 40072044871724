import {AnyAction} from 'redux'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'

export const name = 'INDIVIDUAL_CLASS'

export type IndividualClassState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function individualClassReducer(state: IndividualClassState = initialState, action: AnyAction): IndividualClassState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
