import {Metadata} from 'lib/dto'

export type Group = {
    id: string
    name: string
    school: string
    active: boolean
    studentAge: number
    teachers: string[]
    students: string[]
    classes: string[]
    metadata?: Metadata
}

export const newGroup = (): Group => ({
    id: '',
    name: '',
    school: '',
    active: false,
    studentAge: 0,
    teachers: [],
    students: [],
    classes: []
})
