import {
    Checkbox,
    FormControlLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from '@material-ui/core'
import * as React from 'react'
import {LFWTestSection} from "../LFWSection";

type Q20Props = {
    section: LFWTestSection
    preview: boolean
}

type Q20State = {
    section: LFWTestSection
}


export class Q20 extends React.Component<Q20Props, Q20State> {
    state: Q20State = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.section.response.hasOwnProperty(event.target.name)) {
            this.state.section.response[event.target.name] = event.target.checked
        }

        if (event.target.name == 'wzrok') {
            this.state.section.response['słuch'] = false
            this.state.section.response['dotyk'] = false
            this.state.section.response['brak wyraźnego zróżnicowania'] = false
        }

        if (event.target.name == 'słuch') {
            this.state.section.response['wzrok'] = false
            this.state.section.response['dotyk'] = false
            this.state.section.response['brak wyraźnego zróżnicowania'] = false
        }

        if (event.target.name == 'dotyk') {
            this.state.section.response['wzrok'] = false
            this.state.section.response['słuch'] = false
            this.state.section.response['brak wyraźnego zróżnicowania'] = false
        }

        if (event.target.name == 'brak wyraźnego zróżnicowania') {
            this.state.section.response['wzrok'] = false
            this.state.section.response['słuch'] = false
            this.state.section.response['dotyk'] = false
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.value;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'wzrok'}
                                        checked={this.state.section.response['wzrok']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'wzrok'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'słuch'}
                                        checked={this.state.section.response['słuch']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'słuch'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'dotyk'}
                                        checked={this.state.section.response['dotyk']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'dotyk'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'brak wyraźnego zróżnicowania'}
                                        checked={this.state.section.response['brak wyraźnego zróżnicowania']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'brak wyraźnego zróżnicowania'}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}