import {Box, Button, ButtonGroup, ClickAwayListener, Grid, MenuList, Paper, Popper, TableCell} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'

type StateToProps = {}

type DispatchToProps = {}

type MenuProps = {
    id: string,
    button: JSX.Element|null
    actions: JSX.Element[]
    showSubmenu: boolean
} & StateToProps & DispatchToProps

type MenuState = {
    anchorEl: Element | null
    opened: boolean
}

class MenuComponent extends React.Component<MenuProps, MenuState> {
    state: MenuState = {
        anchorEl: null,
        opened: false
    }

    public render = () => <>
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                {this.props.button
                    ? <ButtonGroup variant="contained" color="primary">
                        {this.props.button}
                        {this.props.showSubmenu ?
                            <Button
                                color="primary"
                                size="small"
                                aria-haspopup="menu"
                                onClick={this.handleOpen}
                            >
                                <ArrowDropDownIcon/>
                            </Button> : null}
                    </ButtonGroup> : null}

                    <Popper open={this.state.opened}
                            anchorEl={this.state.anchorEl}
                            role={undefined}
                            placement="bottom-end"
                            disablePortal={false}>
                        <ClickAwayListener onClickAway={this.handleClose}>
                            <Paper variant={'outlined'}>
                                <MenuList>
                                    {this.props.actions.map(
                                        (action, index) => (
                                            <Box key={index}>{action}</Box>
                                        ),
                                        this
                                    )}
                                </MenuList>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
            </Grid>
        </Grid>
    </>

    private handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.currentTarget

        this.setState(prevState => {
            return {...prevState, anchorEl: target, opened: true}
        })
    }

    private handleClose = () => {
        this.setState(prevState => {
            return {...prevState, anchorEl: null, opened: false}
        })
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    close: () => {
    }
})

export const Menu = connect(mapStateToProps, mapDispatchToProps)(MenuComponent)