import {AnyAction} from 'redux'
import {TabsActionType} from 'lib/tabs'

export type TabsState = {
    tab: number,
}

const initialState = {
    tab: 0,
}

export function tabsReducer(state: TabsState = initialState, action: AnyAction): TabsState {
    switch (action.type) {
        case TabsActionType.setTab():
            return {
                ...state,
                tab: action.payload
            }
        default:
            return state
    }
}
