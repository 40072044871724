import {ClameisrSection, ClameisrTestSection} from 'app/Views/Tests'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {TestAction, TestActionType} from "../../../../lib/test";

type ClameisrFormProps = {
    data?: any
    preview: boolean
    sectionIndex?: number
}

type DispatchToProps = {
    determineIfCorrect: (isCorrect: boolean) => void
    setInvalidSection: (sectionNumber: number) => void
}

type ClameisrFormState = {
    isValid: boolean
    firstInvalidSection: number
}

class ClameisrFormComponent extends React.Component<ClameisrFormProps & DispatchToProps, ClameisrFormState> {
    state: ClameisrFormState = {
        isValid: true,
        firstInvalidSection: 0
    }

    public render = () => {
        let isValid = true

        this.props.determineIfCorrect(true)
        this.props.setInvalidSection(0)

        return <>
            {this.props.data ? (this.props.data as ClameisrTestSection[]).map(
                (section, index) => {

                    section.items.map(item => {
                        if (isValid && this.props.preview && item.required && item.value == 0) {
                            isValid = false
                            this.props.determineIfCorrect(false)
                            this.props.setInvalidSection(index)
                        }
                    })

                    return index == this.props.sectionIndex || this.props.sectionIndex == undefined ?
                        <ClameisrSection
                            section={section}
                            key={index}
                            index={index}
                            preview={this.props.preview}
                            prev={index == 0 ? null : index - 1}
                            next={index == this.props.data.length - 1 ? null : index + 1}
                        /> : null
                },
                this
            ) : ''}
        </>
    }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    determineIfCorrect: (isCorrect: boolean) => {
        dispatch(TestAction.determineIfCorrect(isCorrect))
    },
    setInvalidSection: (sectionNumber: number) => {
        dispatch(TestAction.setInvalidSection(sectionNumber))
    }
})

export const ClameisrForm = connect(null, mapDispatchToProps)(ClameisrFormComponent)